/**
 *---------------------------------------------------------------
 * SINGLE FILE UPLOAD FORM STYLES
 *---------------------------------------------------------------
*/

    .single-file {
        position: relative;

        .preview {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-color: #dadfe1;
            background-image: url(/packages/netmatters/base/images/upload-logo.png);
            background-repeat: no-repeat;
            background-position: center;

            &.img-contain {
                top: 1px;
                right: 1px;
                bottom: 1px;
                left: 1px;
                border: 12px solid @white;
                background-color: @white;
                outline: 1px solid #ccc;
                padding: 12px;
            }
        }

        &:hover {
            .preview.img-contain:not(.active) {
                background-image: url(/packages/netmatters/base/images/upload-logo.png) !important;
                background-color: #dadfe1;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding: 0;
                outline: 0;
                border: 0;
                pointer-events: none;
            }
        }

        .form-control {
            border: 0;
            background-color: @white;
            box-shadow: 0 0 0 rgba(255, 255, 255, 0);
            padding: 64px 12px 86px 20px;
            cursor: pointer;
        }
    }
