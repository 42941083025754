/**
 *---------------------------------------------------------------
 * LABEL & BADGE
 *---------------------------------------------------------------
*/
.label.label-sm {
    padding: 2px 4px;
}

h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
    font-size: 75%;
}

.badge {
    text-align: center;
    padding: 3px 6px 3px 6px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px !important;
    text-shadow: none;
    text-align: center;
    vertical-align: middle;
}

.badge.badge-roundless {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.badge-default,
.label-default {
    background-color: @gray-light;
}

.badge-primary,
.label-primary {
    background-color: @brand-primary;
}

.label-success,
.badge-success {
    background-color: @brand-success;
}

.label-warning,
.badge-warning {
    background-color: @brand-warning;
}

.label-danger,
.badge-danger {
    background-color: @brand-danger;
}

.label-info,
.badge-info {
    background-color: @brand-info;
}

.label-red,
.badge-red {
    background-color: @alizarin;
    background-image: none;
}

.label-green,
.badge-green {
    background-color: @greensea;
    background-image: none;
}

.label-blue,
.badge-blue {
    background-color: @belizehole;
    background-image: none;
}

.label-dark-blue,
.badge-dark-blue {
    background-color: @midnightblue;
    background-image: none;
}

.label-purple,
.badge-purple {
    background-color: @wisteria;
    background-image: none;
}

.label-silver,
.badge-silver {
    background-color: @asbestos;
    background-image: none;
}

.label-orange,
.badge-orange {
    background-color: @pumpkin;
    background-image: none;
}

.label-yellow,
.badge-yellow {
    background-color: @yellow;
    background-image: none;
}