
/**
 *---------------------------------------------------------------
 * BUTTONS
 *---------------------------------------------------------------
*/
.btn {
    font-weight: 500;
    border-radius: @btn-border-radius;
    outline: 0 !important;
    border:0;
    :not dropdown-toggle {
        padding:8px 12px;
    }
    i {
        font-size:16px;
    }
}


.btn-link {
    color: @text-color;
    &.active {
        webkit-box-shadow: none;
        box-shadow: none;
    }
}

.btn-default {
    .button-variant(@text-color, @btn-default-bg, @btn-default-border);
    background-color: @btn-default-bg;
    color: @btn-default-color  !important;
    &.btn-bg {
        border-color: rgba(0, 0, 0, 0.1);
        background-clip: padding-box;
    }
}

.btn-default:hover {
    color: @btn-default-color  !important;

}

table.dataTable .btn, .actions .btn {
    border-radius:0;
    border-bottom:0;
    margin-left:5px;
    padding:6px 10px
}

.btn-primary {
    .button-variant(#fff, @brand-primary, @brand-primary);
}

.btn-success {
    .button-variant(#fff, @brand-success, @brand-success);
}

.btn-info {
    .button-variant(#fff, @brand-info, @brand-info);
}

.btn-warning {
    .button-variant(#fff, @brand-warning, @brand-warning);
}

.btn-danger {
    .button-variant(#fff, @brand-danger, @brand-danger);
}

.btn-dark {
    .button-variant(#fff, @brand-dark, @brand-dark);
}

.btn-black {
    .button-variant(#fff, @brand-black, @brand-black);
}

.btn-icon {
    padding: 0 !important;
    text-align: center;
    width: 34px;
    height: 34px;
    i {
        top: -1px;
        position: relative;
        line-height: 34px;
    }
    &.btn-sm {
        width: 30px;
        height: 30px;
        i {
            line-height: 30px;
        }
    }
    &.btn-lg {
        width: 45px;
        height: 45px;
        i {
            line-height: 45px;
        }
    }
}

.btn-rounded {
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    &.btn-lg {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.btn {
    > i {
        &.pull-left,
        &.pull-right {
            line-height: @line-height-base;
        }
    }
}

.btn-block {
    padding-left: 12px;
    padding-right: 12px;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
    border-top-right-radius: @border-radius-base;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
    border-bottom-left-radius: @border-radius-base;
}

.btn-addon {
    i {
        margin: -7px -12px;
        margin-right: 12px;
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        float: left;
        position: relative;
        border-radius: @btn-border-radius 0 0 @btn-border-radius;
        width: 35px;
        line-height: 39px;
        &.pull-right {
            margin-right: -12px;
            margin-left: 12px;
            border-radius: 0 @btn-border-radius @btn-border-radius 0;
        }
    }
    .text {
        font-size: 14px;
        line-height: 25px;
    }
    &.btn-sm {
        i {
            margin: -6px -10px;
            margin-right: 10px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            &.pull-right {
                margin-right: -10px;
                margin-left: 10px;
            }
        }
    }
    &.btn-lg {
        i {
            margin: -11px -16px;
            margin-right: 16px;
            width: 45px;
            height: 45px;
            line-height: 45px;
            &.pull-right {
                margin-right: -16px;
                margin-left: 16px;
            }
        }
    }
    &.btn-default {
        i {
            background-color: transparent;
            border-right: 1px solid @border-color;
        }
    }
}


/**
 *---------------------------------------------------------------
 * CUSTOM BUTTONS
 *---------------------------------------------------------------
*/

.btn i, .btn span {
    font-size:18px;
}

.btn-sm i, .btn-sm span {
    font-size:11px;
}


/* Blue */
.btn.blue {
    color: @white;
    text-shadow: none;
    background-color: @blue;
    border-color: darken(@blue, 5%);
}

.btn.blue:hover,
.btn.blue:focus,
.btn.blue:active,
.btn.blue.active,
.btn.blue[disabled],
.btn.blue.disabled {
    outline: none !important;
    background-color: darken(@blue, 10%) !important;
}

/* Red */
.btn.red {
    color: @white;
    text-shadow: none;
    background-color: @red;
    border-color: darken(@red, 5%);
}

.btn.red:hover,
.btn.red:focus,
.btn.red:active,
.btn.red.active,
.btn.red[disabled],
.btn.red.disabled {
    outline: none !important;
    background-color: darken(@red, 10%) !important;
}

/* Green */
.btn.green {
    color: @white;
    text-shadow: none;
    background-color: @green;
    border-color: darken(@green, 5%);
}

.btn.green:hover,
.btn.green:focus,
.btn.green:active,
.btn.green.active,
.btn.green[disabled],
.btn.green.disabled {
    outline: none !important;
    background-color: darken(@green, 10%) !important;
}

/* Purple */
.btn.purple {
    color: @white;
    text-shadow: none;
    background-color: @purple;
    border-color: darken(@purple, 5%);
}

.btn.purple:hover,
.btn.purple:focus,
.btn.purple:active,
.btn.purple.active,
.btn.purple[disabled],
.btn.purple.disabled {
    outline: none !important;
    background-color: darken(@purple, 10%) !important;
}

/* Yellow */
.btn.yellow {
    color: @white;
    text-shadow: none;
    background-color: @yellow;
    border-color: darken(@yellow, 5%);
}

.btn.yellow:hover,
.btn.yellow:focus,
.btn.yellow:active,
.btn.yellow.active,
.btn.yellow[disabled],
.btn.yellow.disabled {
    outline: none !important;
    background-color: darken(@yellow, 10%) !important;
}

/* Dark */
.btn.dark {
    color: @white;
    text-shadow: none;
    background-color: @gray-darker;
    border-color: darken(@gray-darker, 5%);
}

.btn.dark:hover,
.btn.dark:focus,
.btn.dark:active,
.btn.dark.active,
.btn.dark[disabled],
.btn.dark.disabled {
    outline: none !important;
    background-color: darken(@gray-darker, 10%) !important;
}

/**
 *---------------------------------------------------------------
 * BUTTON STRIPES
 *---------------------------------------------------------------
*/
/* Buttons With left Strip */
.red-stripe {
    border-left: 3px solid @red;
}

.blue-stripe {
    border-left: 3px solid @blue;
}

.purple-stripe {
    border-left: 3px solid @purple;
}

.green-stripe {
    border-left: 3px solid @green;
}

.yellow-stripe {
    border-left: 3px solid @yellow;
}

.grey-stripe {
    border-left: 3px solid @gray;
}

.dark-stripe {
    border-left: 3px solid @gray-darker;
}
