
/**
 * ---------------------------------------------------------------
 * LAYOUT CLASSES
 * ---------------------------------------------------------------
 */
.hbox {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    .col {
        display: table-cell;
        vertical-align: top;
        height: 100%;
        float: none;
    }
}

.v-middle {
    vertical-align: middle !important;
}
.v-top {
    vertical-align: top !important;
}
.v-bottom {
    vertical-align: bottom !important;
}

.vbox {
    display: table;
    border-spacing: 0;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 240px;
    .row-row {
        display: table-row;
        height: 100%;
        .cell {
            position: relative;
            height: 100%;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            overflow: auto;
            .ie & {
                display: table-cell;
            }
            .cell-inner {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }
}


/**
 * Positioning
 */
.pos-rlt {
    position: relative;
}
.pos-stc {
    position: static;
}
.pos-abt {
    position: absolute;
}
.pos-fix {
    position: fixed;
}

.stick-top-left,
.stick-top-right,
.stick-top-left-right {
    position: absolute;
    top: 0;
}
.stick-bottom-left,
.stick-bottom-right,
.stick-bottom-left-right {
    position: absolute;
    bottom: 0;
}
.stick-top-left,
.stick-bottom-left,
.stick-top-left-right,
.stick-bottom-left-right {
    left: 0;
}
.stick-top-right,
.stick-bottom-right,
.stick-top-left-right,
.stick-bottom-left-right {
    right: 0;
}



/**
 * ---------------------------------------------------------------
 * LINE
 * ---------------------------------------------------------------
 */
.line {
    width: 100%;
    height: 2px;
    margin: 10px 0;
    font-size: 0;
    overflow: hidden;
}
.line-xs {
    margin: 0
}
.line-lg {
    margin-top: 15px;
    margin-bottom: 15px
}
.line-dashed {
    border-style: dashed !important;
    background-color: transparent;
    border-width: 0;
}
.no-line {
    border-width: 0
}


/**
 * ---------------------------------------------------------------
 * BORDER
 * ---------------------------------------------------------------
 */
.no-border, .no-borders {
    border-color: transparent;
    border-width: 0
}

.no-radius {
    border-radius: 0
}


/**
 * ---------------------------------------------------------------
 * DISPLAY
 * ---------------------------------------------------------------
 */
.block {
    display: block;
}

.block.hide {
    display: none;
}

.inline {
    display: inline-block !important;
}

.none {
    display: none;
}



.pull-none {
    float: none;
}

.rounded {
    border-radius: 500px;
}

.clear {
    display: block;
    overflow: hidden;
}

.no-bg {
    background-color: transparent;
    color: inherit;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.no-results {
    text-align: center;
    color: #444;
    padding: 20px;
    background: @white;
    font-weight: 700;
    border: 1px solid #dee5e7;

    h2,
    h3,
    h4 {
        margin: 0;
        font-size: 22px;
    }
}


/**
 * ---------------------------------------------------------------
 * LINE HEIGHT
 * ---------------------------------------------------------------
 */
.l-h {
    line-height: @line-height-base;
}
.l-h-0x {
    line-height: 0;
}
.l-h-1x {
    line-height: 1.2;
}
.l-h-2x {
    line-height: 2em;
}


/**
 * ---------------------------------------------------------------
 * FONT WEIGHT
 * ---------------------------------------------------------------
 */
.font-normal {
    font-weight: normal;
}
.font-thin {
    font-weight: 300;
}
.font-bold {
    font-weight: 700;
}


/**
 * ---------------------------------------------------------------
 * FONT SIZE
 * ---------------------------------------------------------------
 */
.text-xxs {
    font-size: 65%;
}
.text-xs {
    font-size: 75%;
}
.text-sm {
    font-size: 85%;
}
.text-base {
    font-size: @font-size-base;
}
.text-md {
    font-size: @font-size-md;
}
.text-lg {
    font-size: 125%;
}
.text-xl {
    font-size: 180%;
}
.text-xxl {
    font-size: 225%;
}
.text-xxxl {
    font-size: 330%;
}
.text-xxxxl {
    font-size: 500%;
}

.text-2x {
    font-size: 2em;
}
.text-3x {
    font-size: 3em;
}


/**
 * ---------------------------------------------------------------
 * FONT STYLES
 * ---------------------------------------------------------------
 */
.text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-u-c {
    text-transform: uppercase;
}
.text-l-t {
    text-decoration: line-through;
}
.text-u-l {
    text-decoration: underline;
}

.text-active, .active > .text, .active > .auto .text {
    display: none !important;
}

.active > .text-active, .active > .auto .text-active {
    display: inline-block !important;
}


/**
 * ---------------------------------------------------------------
 * BOX SHADOW
 * ---------------------------------------------------------------
 */
.box-shadow {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}
.box-shadow-lg {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}


/**
 * ---------------------------------------------------------------
 * TEXT SHADOW
 * ---------------------------------------------------------------
 */
.text-shadow {
    font-size: 170px;
    text-shadow: 0 1px 0 @border-color, 0 2px 0 lighten(@border-color, 10%), 0 5px 10px rgba(0, 0, 0, .125), 0 10px 20px rgba(0, 0, 0, .2);
}
.no-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}


/**
 * ---------------------------------------------------------------
 * WRAPPER
 * ---------------------------------------------------------------
 */
.wrapper-xs {
    padding: 5px;
}
.wrapper-sm {
    padding: 10px;
}
.wrapper {
    padding: 15px;
}
.wrapper-md {
    padding: 20px;
}
.wrapper-lg {
    padding: 30px;
}
.wrapper-xl {
    padding: 50px;
}


/**
 * ---------------------------------------------------------------
 * PADDING
 * ---------------------------------------------------------------
 */
.padder-lg {
    padding-left: 40px;
    padding-right: 30px
}
.padder-md {
    padding-left: 40px;
    padding-right: 20px
}
.padder {
    padding-left: 15px;
    padding-right: 15px
}
.padder-v {
    padding-top: 15px;
    padding-bottom: 15px
}
.no-padder {
    padding: 0 !important;
}


.p-xxs {
    padding: 2px 4px
}
.p-xs {
    padding: 5px;
}
.p-sm {
    padding: 10px;
}
.m {
    padding: 15px;
}
.p-md {
    padding: 20px;
}
.p-lg {
    padding: 30px;
}
.p-xl {
    padding: 50px;
}


.p-none {
    padding-left: 0 !important
}
.p-t-none {
    padding-top: 0 !important
}
.p-r-none {
    padding-right: 0 !important
}
.p-b-none {
    padding-bottom: 0 !important
}
.p-l-none {
    padding-left: 0 !important
}

.pull-in {
    margin-left: -15px;
    margin-right: -15px;
}
.pull-out {
    margin: -10px -15px;
}


/**
 * ---------------------------------------------------------------
 * BORDER
 * ---------------------------------------------------------------
 */
.b {
    border: 1px solid rgba(0, 0, 0, 0.05)
}
.b-a {
    border: 1px solid @border-color
}
.b-t {
    border-top: 1px solid @border-color
}
.b-r {
    border-right: 1px solid @border-color
}
.b-b {
    border-bottom: 1px solid @border-color
}
.b-l {
    border-left: 1px solid @border-color
}


/**
 * ---------------------------------------------------------------
 * BORDER COLOUR
 * ---------------------------------------------------------------
 */
.b-light {
    border-color: @brand-light
}
.b-dark {
    border-color: @brand-dark
}
.b-black {
    border-color: @brand-dark
}
.b-primary {
    border-color: @brand-primary
}
.b-success {
    border-color: @brand-success
}
.b-info {
    border-color: @brand-info
}
.b-warning {
    border-color: @brand-warning
}
.b-danger {
    border-color: @brand-danger
}
.b-white {
    border-color: #FFFFFF
}
.b-dashed {
    border-style: dashed !important;
}
.b-l-light {
    border-left-color: @brand-light
}
.b-l-dark {
    border-left-color: @brand-dark
}
.b-l-black {
    border-left-color: @brand-dark
}
.b-l-primary {
    border-left-color: @brand-primary
}
.b-l-success {
    border-left-color: @brand-success
}
.b-l-info {
    border-left-color: @brand-info
}
.b-l-warning {
    border-left-color: @brand-warning
}
.b-l-danger {
    border-left-color: @brand-danger
}
.b-l-white {
    border-left-color: #FFFFFF
}


/**
 * ---------------------------------------------------------------
 * BORDER WIDTH
 * ---------------------------------------------------------------
 */
.b-l-2x {
    border-left-width: 2px
}
.b-l-3x {
    border-left-width: 3px
}
.b-l-4x {
    border-left-width: 4px
}
.b-l-5x {
    border-left-width: 5px
}
.b-2x {
    border-width: 2px
}
.b-3x {
    border-width: 3px
}
.b-4x {
    border-width: 4px
}
.b-5x {
    border-width: 5px
}


/**
 * ---------------------------------------------------------------
 * BORDER RADIUS
 * ---------------------------------------------------------------
 */
.r {
    border-radius: @border-radius-base @border-radius-base @border-radius-base @border-radius-base;
}
.r-2x {
    border-radius: @border-radius-base * 2;
}
.r-3x {
    border-radius: @border-radius-base * 3;
}
.r-l {
    border-radius: @border-radius-base 0 0 @border-radius-base;
}
.r-r {
    border-radius: 0 @border-radius-base @border-radius-base 0;
}
.r-t {
    border-radius: @border-radius-base @border-radius-base 0 0;
}
.r-b {
    border-radius: 0 0 @border-radius-base @border-radius-base;
}


/**
 * ---------------------------------------------------------------
 * MARGIN
 * ---------------------------------------------------------------
 */
.m-xxs {
    margin: 2px 4px
}
.m-xs {
    margin: 5px;
}
.m-sm {
    margin: 10px;
}
.m {
    margin: 15px;
}
.m-md {
    margin: 20px;
}
.m-lg {
    margin: 30px;
}
.m-xl {
    margin: 50px;
}
.m-n {
    margin: 0 !important
}
.m-l-none {
    margin-left: 0 !important
}
.m-l-xs {
    margin-left: 5px;
}
.m-l-sm {
    margin-left: 10px;
}
.m-l {
    margin-left: 15px
}
.m-l-md {
    margin-left: 20px;
}
.m-l-lg {
    margin-left: 30px;
}
.m-l-xl {
    margin-left: 40px;
}
.m-l-xxl {
    margin-left: 50px;
}

.m-l-n-xxs {
    margin-left: -1px
}
.m-l-n-xs {
    margin-left: -5px
}
.m-l-n-sm {
    margin-left: -10px
}
.m-l-n {
    margin-left: -15px
}
.m-l-n-md {
    margin-left: -20px
}
.m-l-n-lg {
    margin-left: -30px
}
.m-l-n-xl {
    margin-left: -40px
}
.m-l-n-xxl {
    margin-left: -50px
}


.m-t-none {
    margin-top: 0 !important
}
.m-t-xxs {
    margin-top: 1px;
}
.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}
.m-t {
    margin-top: 15px
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-xxl {
    margin-top: 50px;
}

.m-t-n-xxs {
    margin-top: -1px
}

.m-t-n-xs {
    margin-top: -5px
}

.m-t-n-sm {
    margin-top: -10px
}

.m-t-n {
    margin-top: -15px
}

.m-t-n-md {
    margin-top: -20px
}

.m-t-n-lg {
    margin-top: -30px
}

.m-t-n-xl {
    margin-top: -40px
}

.m-t-n-xxl {
    margin-top: -50px
}

.m-r-none {
    margin-right: 0 !important
}

.m-r-xxs {
    margin-right: 1px
}

.m-r-xs {
    margin-right: 5px
}

.m-r-sm {
    margin-right: 10px
}

.m-r {
    margin-right: 15px
}

.m-r-md {
    margin-right: 20px
}

.m-r-lg {
    margin-right: 30px
}

.m-r-xl {
    margin-right: 40px
}

.m-r-xxl {
    margin-right: 50px
}

.m-r-n-xxs {
    margin-right: -1px
}

.m-r-n-xs {
    margin-right: -5px
}

.m-r-n-sm {
    margin-right: -10px
}

.m-r-n {
    margin-right: -15px
}

.m-r-n-md {
    margin-right: -20px
}

.m-r-n-lg {
    margin-right: -30px
}

.m-r-n-xl {
    margin-right: -40px
}

.m-r-n-xxl {
    margin-right: -50px
}

.m-b-none {
    margin-bottom: 0 !important
}

.m-b-xxs {
    margin-bottom: 1px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.m-b-xxl {
    margin-bottom: 50px;
}

.m-b-n-xxs {
    margin-bottom: -1px
}

.m-b-n-xs {
    margin-bottom: -5px
}

.m-b-n-sm {
    margin-bottom: -10px
}

.m-b-n {
    margin-bottom: -15px
}

.m-b-n-md {
    margin-bottom: -20px
}

.m-b-n-lg {
    margin-bottom: -30px
}

.m-b-n-xl {
    margin-bottom: -40px
}

.m-b-n-xxl {
    margin-bottom: -50px
}


/**
 * ---------------------------------------------------------------
 * AVATAR
 * ---------------------------------------------------------------
 */
.avatar {
    position: relative;
    display: block;
    border-radius: 500px;
    white-space: nowrap;
    img {
        border-radius: 500px;
        width: 100%;
    }
    i {
        position: absolute;
        left: 0;
        top: 0;
        width: 10px;
        height: 10px;
        margin: 2px;
        border-width: 2px;
        border-style: solid;
        border-radius: 100%;
        &.right {
            left: auto;
            right: 0;
        }
        &.bottom {
            left: auto;
            top: auto;
            bottom: 0;
            right: 0;
        }
        &.left {
            top: auto;
            bottom: 0;
        }
        &.on {
            background-color: @brand-success;
        }
        &.off {
            background-color: @text-muted;
        }
        &.busy {
            background-color: @brand-danger;
        }
        &.away {
            background-color: @brand-warning;
        }
    }

    &.thumb-md i {
        width: 12px;
        height: 12px;
        margin: 3px;
    }
    &.thumb-sm i {
        margin: 1px;
    }
    &.thumb-xs i {
        margin: 0;
    }
}


/**
 * ---------------------------------------------------------------
 * WIDTHS
 * ---------------------------------------------------------------
 */
.w-xxs {
    width: 60px;
}

.w-xs {
    width: 90px;
}

.w-sm {
    width: 150px;
}

.w {
    width: 200px;
}

.w-md {
    width: 240px;
}

.w-lg {
    width: 280px;
}

.w-xl {
    width: 320px;
}

.w-xxl {
    width: 360px;
}

.w-full {
    width: 100%;
}

.w-auto {
    width: auto;
}


/**
 * ---------------------------------------------------------------
 * HEIGHTS
 * ---------------------------------------------------------------
 */
.h-auto {
    height: auto;
}

.h-full {
    height: 100%;
}


/**
 * ---------------------------------------------------------------
 * THUMBNAIL
 * ---------------------------------------------------------------
 */
.thumb-xl {
    width: 128px;
    display: inline-block
}
.thumb-lg {
    width: 96px;
    display: inline-block
}
.thumb-md {
    width: 64px;
    display: inline-block
}

.thumb {
    width: 50px;
    display: inline-block
}
.thumb-sm {
    width: 40px;
    display: inline-block
}

.thumb-xs {
    width: 34px;
    display: inline-block
}
.thumb-xxs {
    width: 30px;
    display: inline-block
}

.thumb-wrapper {
    padding: 2px;
    border: 1px solid @border-color
}

.thumb,
.thumb-xs,
.thumb-sm,
.thumb-md,
.thumb-lg,
.thumb-btn {
    img {
        height: auto;
        max-width: 100%;
        vertical-align: middle;
    }
}

.img-full {
    width: 100%;
    img {
        width: 100%;
    }
}


/**
 * ---------------------------------------------------------------
 * SCROLLABLE
 * ---------------------------------------------------------------
 */
.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    &.hover {
        overflow-y: hidden !important;
        &:hover {
            overflow: visible !important;
            overflow-y: auto !important;
        }
    }
    .smart & {
        overflow-y: auto !important;
    }
}
.scroll-x, .scroll-y {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}
.scroll-y {
    overflow-y: auto;
}
.scroll-x {
    overflow-x: auto;
}


/**
 * ---------------------------------------------------------------
 * ARROWS
 * ---------------------------------------------------------------
 */
.arrow {
    border-width: @arrow-outer-width;
    z-index: 10;
    &,
    &:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
    &:after {
        border-width: @arrow-width;
        content: "";
    }

    &.top {
        left: 50%;
        margin-left: -@arrow-outer-width;
        border-top-width: 0;
        border-bottom-color: @arrow-outer-color;
        top: -@arrow-outer-width;
        &:after {
            top: 1px;
            margin-left: -@arrow-width;
            border-top-width: 0;
            border-bottom-color: @arrow-color;
        }
        &.arrow-primary {
            &:after {
                border-bottom-color: @brand-primary;
            }
        }
        &.arrow-info {
            &:after {
                border-bottom-color: @brand-info;
            }
        }
        &.arrow-success {
            &:after {
                border-bottom-color: @brand-success;
            }
        }
        &.arrow-danger {
            &:after {
                border-bottom-color: @brand-danger;
            }
        }
        &.arrow-warning {
            &:after {
                border-bottom-color: @brand-warning;
            }
        }
        &.arrow-light {
            &:after {
                border-bottom-color: @brand-light;
            }
        }
        &.arrow-dark {
            &:after {
                border-bottom-color: @brand-dark;
            }
        }
        &.arrow-black {
            &:after {
                border-bottom-color: @brand-black;
            }
        }
    }

    &.right {
        top: 50%;
        right: -@arrow-outer-width;
        margin-top: -@arrow-outer-width;
        border-right-width: 0;
        border-left-color: @arrow-outer-color;
        &:after {
            right: 1px;
            bottom: -@arrow-width;
            border-right-width: 0;
            border-left-color: @arrow-color;
        }
        &.arrow-primary {
            &:after {
                border-left-color: @brand-primary;
            }
        }
        &.arrow-info {
            &:after {
                border-left-color: @brand-info;
            }
        }
        &.arrow-success {
            &:after {
                border-left-color: @brand-success;
            }
        }
        &.arrow-danger {
            &:after {
                border-left-color: @brand-danger;
            }
        }
        &.arrow-warning {
            &:after {
                border-left-color: @brand-warning;
            }
        }
        &.arrow-light {
            &:after {
                border-left-color: @brand-light;
            }
        }
        &.arrow-dark {
            &:after {
                border-left-color: @brand-dark;
            }
        }
        &.arrow-black {
            &:after {
                border-left-color: @brand-black;
            }
        }
    }

    &.bottom {
        left: 50%;
        bottom: -@arrow-outer-width;
        margin-left: -@arrow-outer-width;
        border-bottom-width: 0;
        border-top-color: @arrow-outer-color;
        &:after {
            bottom: 1px;
            margin-left: -@arrow-width;
            border-bottom-width: 0;
            border-top-color: @arrow-color;
        }
        &.arrow-primary {
            &:after {
                border-top-color: @brand-primary;
            }
        }
        &.arrow-info {
            &:after {
                border-top-color: @brand-info;
            }
        }
        &.arrow-success {
            &:after {
                border-top-color: @brand-success;
            }
        }
        &.arrow-danger {
            &:after {
                border-top-color: @brand-danger;
            }
        }
        &.arrow-warning {
            &:after {
                border-top-color: @brand-warning;
            }
        }
        &.arrow-light {
            &:after {
                border-top-color: @brand-light;
            }
        }
        &.arrow-dark {
            &:after {
                border-top-color: @brand-dark;
            }
        }
        &.arrow-black {
            &:after {
                border-top-color: @brand-black;
            }
        }
    }

    &.left {
        top: 50%;
        left: -@arrow-outer-width;
        margin-top: -@arrow-outer-width;
        border-left-width: 0;
        border-right-color: @arrow-outer-color;
        &:after {
            left: 1px;
            bottom: -@arrow-width;
            border-left-width: 0;
            border-right-color: @arrow-color;
        }
        &.arrow-primary {
            &:after {
                border-right-color: @brand-primary;
            }
        }
        &.arrow-info {
            &:after {
                border-right-color: @brand-info;
            }
        }
        &.arrow-success {
            &:after {
                border-right-color: @brand-success;
            }
        }
        &.arrow-danger {
            &:after {
                border-right-color: @brand-danger;
            }
        }
        &.arrow-warning {
            &:after {
                border-right-color: @brand-warning;
            }
        }
        &.arrow-light {
            &:after {
                border-right-color: @brand-light;
            }
        }
        &.arrow-dark {
            &:after {
                border-right-color: @brand-dark;
            }
        }
        &.arrow-black {
            &:after {
                border-right-color: @brand-black;
            }
        }
    }

    &.pull-left {
        left: @arrow-outer-width + 10;
    }
    &.pull-right {
        left: auto;
        right: @arrow-outer-width + 10;
    }
    &.pull-up {
        top: @arrow-outer-width + 10;
    }
    &.pull-down {
        top: auto;
        bottom: @arrow-outer-width + 10;
    }
}


/**
 * ---------------------------------------------------------------
 * HOVER
 * ---------------------------------------------------------------
 */
.hover-action {
    display: none;
}

.hover-rotate {
    .transition(all .2s ease-in-out .1s)
}

.hover-anchor:hover,
.hover-anchor:focus,
.hover-anchor:active {
    > .hover-action {
        display: inherit;
    }
    > .hover-rotate {
        .rotate(90deg)
    }
}

.backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    &.fade {
        opacity: 0;
        filter: alpha(opacity=0);
    }
    &.in {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
}

/*desktop*/
@media screen and (min-width: 992px) {
    .col-lg-2-4 {
        width: 20.000%;
        float: left;
    }
}

// sm
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm.show {
        display: inherit !important;
    }
}

/*phone*/
@media (max-width: 767px) {
    .w-auto-xs {
        width: auto;
    }

    .shift {
        display: none !important;
    }

    .shift.in {
        display: block !important;
    }

    .row-2 [class*="col"] {
        width: 50%;
        float: left
    }

    .row-2 .col-0 {
        clear: none
    }

    .row-2 li:nth-child(odd) {
        clear: left;
        margin-left: 0
    }

    .text-center-xs {
        text-align: center;
    }

    .text-left-xs {
        text-align: left;
    }

    .text-right-xs {
        text-align: right;
    }

    .no-border-xs {
        border-width: 0;
    }

    .pull-none-xs {
        float: none !important;
    }

    .pull-right-xs {
        float: right !important;
    }

    .pull-left-xs {
        float: left !important;
    }

    .dropdown-menu.pull-none-xs {
        left: 0;
    }

    .hidden-xs.show {
        display: inherit !important;
    }

    .wrapper-lg, .wrapper-md {
        padding: 15px;
    }

    .padder-lg, .padder-md {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.back-forward-arrows {
    padding-top: 7px;
}


/**
 * Transitions
 */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-moz-keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-o-keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-ms-keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes zoom-down {
    from {
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-webkit-keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-moz-keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-o-keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-ms-keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@keyframes zoom-up {
    from {
        -webkit-transform-origin: bottom;
        -moz-transform-origin: bottom;
        -ms-transform-origin: bottom;
        transform-origin: bottom;
        -webkit-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
    }
    to {
        -webkit-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
}

@-webkit-keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-moz-keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-o-keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-ms-keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes slide-right {
    from {
        -webkit-transform: translate(-20px, 0);
        -ms-transform: translate(-20px, 0);
        -o-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-moz-keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-o-keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-ms-keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes slide-left {
    from {
        -webkit-transform: translate(20px, 0);
        -ms-transform: translate(20px, 0);
        -o-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-moz-keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-o-keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-ms-keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes slide-down {
    from {
        -webkit-transform: translate(0, -20px);
        -ms-transform: translate(0, -20px);
        -o-transform: translate(0, -20px);
        transform: translate(0, -20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-moz-keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-o-keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@-ms-keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes slide-up {
    from {
        -webkit-transform: translate(0, 20px);
        -ms-transform: translate(0, 20px);
        -o-transform: translate(0, 20px);
        transform: translate(0, 20px);
        opacity: 0;
    }
    to {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}


/**
 * ---------------------------------------------------------------
 * LOADERS/ SPINNERS STYLES
 * ---------------------------------------------------------------
 */
    .load {
        &.spin {
            margin: 20px auto;
            font-size: 10px;
            position: relative;
            text-indent: -9999em;
            border-top: 1.1em solid fade(@brand-primary, 20%);
            border-right: 1.1em solid fade(@brand-primary, 20%);
            border-bottom: 1.1em solid fade(@brand-primary, 20%);
            border-left: 1.1em solid @brand-primary;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation: load8 1.1s infinite linear;
            animation: load8 1.1s infinite linear;

            &.spin,
            &::after {
                border-radius: 50%;
                width: 10em;
                height: 10em;
            }
        }
    }

    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
