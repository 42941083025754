/**
 *---------------------------------------------------------------
 * TABS
 *---------------------------------------------------------------
*/
.nav-tabs {
    border-color: @border-color;

    > li {
        > a {
            background: #f5f5f5;
            border: 1px solid #e2e2e2;
            border-radius: @panel-border-radius @panel-border-radius 0 0;
            border-bottom: none;

            &:hover {
                background: #f2f2f2;
                border: 1px solid #dddddd;
                border-bottom: none;
            }
        }

        &.active {
            > a {
                border-color: @border-color;
                background-color: #FFF;
                border: 1px solid @nav-tabs-border-color;
                border-bottom-color: #FFF !important;

                &:active,
                &:visited,
                &:focus,
                &:hover {
                    background-color: #FFF;
                }
            }
        }
    }

    .nav-tabs-alt & {
        &.nav-justified {
            > li {
                display: table-cell;
                width: 1%;
            }
        }
        > li {
            > a {
                border-radius: 0;
                border-color: transparent !important;
                background: transparent !important;
                border-bottom-color: @border-color !important;
            }
            &.active {
                > a {
                    border-bottom-color: @brand-primary !important;
                }
            }
        }
        li {
            span,
            i {
                font-size: 20px;
            }
        }
    }
}

.tab-container {
    margin-bottom: 15px;

    .tab-content {
        padding: 15px;
        background-color: #fff;
        border: 1px solid #dee5e7;
        border-top-width: 0;
        border-radius: 0 0 @panel-border-radius @panel-border-radius;
    }
}
