/**
 *---------------------------------------------------------------
 * TILES
 *---------------------------------------------------------------
*/
.tile {
    height: 135px;
    width: 100%;
    margin-bottom: 14px;
    display: block;
    letter-spacing: 0.02em;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.02em;
    line-height: 20px;
    overflow: hidden;
    border: 4px solid #bbb;
    background-image: none;
    background-color: #FFF;
    border: 1px solid #DDD;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.tile * {
    color: #999;
}

.tile:hover * {
    color: #666;
}

.tile:hover {
    border-color: darken(#DDD, 10%);
}

.tile a,
.tile a:hover {
    color: #999;
    text-decoration: none;
}

.tile:after,
.tile:before {
    content: "";
    float: left;
}

.tile.double-down {
    height: 280px !important;
}

.tile:active, .tile.selected {
    border-color: #ccc !important;
}

.tile.selected .corner:after {
    content: "";
    display: inline-block;
    border-left: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-right: 40px solid #ccc;
    position: absolute;
    top: -3px;
    right: -3px;
}

.tile.selected .check:after {
    content: "";
    font-family: FontAwesome;
    font-size: 13px;
    content: "\f00c";
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 2px;
}

.tile .tile-body {
    height: 100%;
    vertical-align: top;
    padding: 10px 10px;
    overflow: hidden;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 10px;
}

.tile .tile-body img {
    float: left;
    margin-right: 10px;
}

.tile .tile-body img.pull-right {
    float: right !important;
    margin-left: 10px;
    margin-right: 0px;
}

.tile .tile-body .content {
    display: inline-block;
}

.tile .tile-body > i,
.tile .tile-body > span {
    margin-top: 17px;
    display: block;
    font-size: 56px;
    line-height: 56px;
    text-align: center;

    &::before {
        display: block;
    }
}

.tile.double-down i,
.tile.double-down span {
    margin-top: 95px;
}

.tile .tile-body h1,
.tile .tile-body h2,
.tile .tile-body h3,
.tile .tile-body h4,
.tile .tile-body h5,
.tile .tile-body h6,
.tile .tile-body p {
    padding: 0;
    margin: 0;
    line-height: 14px;
}

.tile .tile-body h3,
.tile .tile-body h4 {
    margin-bottom: 5px;
}

.tile .tile-body h1:hover,
.tile .tile-body h2:hover,
.tile .tile-body h3:hover,
.tile .tile-body h4:hover,
.tile .tile-body h5:hover,
.tile .tile-body h6:hover,
.tile .tile-body p:hover {

}

.tile .tile-body p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    overflow: hidden;
}

.tile.icon > .tile-body {
    padding: 0;
}

.tile .tile-object {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 30px;
    background-color: transparent;
    *zoom: 1;
}

.tile .tile-object:before,
.tile .tile-object:after {
    display: table;
    content: "";
}

.tile .tile-object:after {
    clear: both;
}

.tile .tile-object > .name {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 5px;
    padding-left: 10px;
    width:100%;
    text-align:center;
    padding-right: 10px;
    font-weight: bold;
    font-size: 13px;
}

.tile .tile-object > .name > i {
    vertical-align: middle;
    display: block;
    font-size: 24px;
    height: 18px;
    width: 24px;
}

.tile .tile-object > .number {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 0;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 14px;
    margin-bottom: 8px;
    margin-right: 10px;
}

.tile.image > .tile-body {
    padding: 0 !important;
}

.tile.image > .tile-body > img {
    width: 100%;
    height: auto;
    min-height: 100%;
    max-width: 100%;
}

.tile.image .tile-body h3 {
    display: inline-block;
}

/**
 *---------------------------------------------------------------
 * SORTABLE PLACEHOLDER
 *---------------------------------------------------------------
*/

.sortable-place-holder {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: top;
    margin-bottom: 14px;
}

.sortable-place-holder::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7px;
    right: 7px;
    border: 2px dashed #ddd;
    background: #eee;
    padding: 0 20px;
}

#dashboard-form {
    margin: 0 -25px;

    > div {
        padding: 0 25px;
    }

    .test {
        background: linear-gradient(to bottom right, #008299, #10acbe);
        color: @white;
    }

    .dashboard-group {
        min-height: 133px;
        display: block;
        margin: 0 -7px;
        font-size: 0;

        > li {
            display: inline-block;
            float: none;
            padding: 0 7px;

            .popover.top {
                top: auto;
                left: auto;
                right: -100px;
                left: -100px;
                margin: auto;
                width: 270px;
                border-radius: 0 !important;
                z-index: 9990;

                .popover-content.form-horizontal {
                    padding: 15px;

                    .control-label {
                        text-align: left;
                        font-size: 14px;
                    }

                    .form-group:last-child {
                        margin-bottom: 0;
                    }

                }

            }

        }

    }

    .dashboard-group a.custom-icon,
    .dashboard-group a.bg-cover,
    .dashboard-group a.bg-contain {
        height: 135px;
        margin-bottom: 20px;
    }

    .tile.custom-icon {
        border: 0;
        text-align: center;

        .tile-body {
            img {
                margin-top: 17px;
                display: inline-block;
                text-align: center;
                float: none;
                margin-right: 0;
            }
        }

        a {
            display: block;
            height: 135px;
        }

    }

    .tile.custom-icon * {
        color: @white;
    }

}

.bg-cover,
.bg-contain  {
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

/**
 *---------------------------------------------------------------
 * NEWS WIDGET OVERRIDE
 *---------------------------------------------------------------
*/

.tile.news-widget {
    padding: 0;
    height: 295px;

    .news-top {
        display: block;
        font-weight: bold;
        border-bottom: 1px solid #DDD;
        padding: 15px 20px;
        font-size: 14px;
    }

    .tile-body {
        padding: 0;

        .tile-inner {
            padding: 20px;
        }

        ul {
            list-style:none;
            margin:0;
            padding:0;
        }

        .bx-wrapper {
            min-height: 158px;
        }

    }

}

.tile.news-widget .caption {
    color: #fff;
    min-height: 140px;
}

.tile.news-widget .caption h3 {
    font-size:14px;
    font-weight:bold;
}

.tile.news-widget .caption h3,{
    line-height:18px;
}

.tile.news-widget .caption p {
    line-height:24px;
}

.news-widget .caption a, .news-widget .caption span {
    font-weight:bold;
}

.news-widget .caption a {
    margin-top:10px;
    clear:both;
    display:block;
}

#internal-news {

    .btn-next, .btn-prev  {
        float: right;
        padding: 12px 18px;
        background-color: #999;
        margin-left: 15px;
    }

    .btn-next:hover, .btn-prev:hover  {
        background-color: #666;
    }

    .btn-next span, .btn-prev span {
        color:#fff;
    }

}


#knowledge-base {

    .btn-next1, .btn-prev1  {
        float: right;
        padding: 12px 18px;
        background-color: #999;
        margin-left: 15px;
    }

    .btn-next:hover, .btn-prev:hover  {
        background-color: #666;
    }

    .btn-next1 span, .btn-prev1 span {
        color:#fff;
    }
    .tile-inner {
        overflow:auto;
    }

}

