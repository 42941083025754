/**
 *---------------------------------------------------------------
 * HTML STYLES
 *---------------------------------------------------------------
*/
body{
    background: @body-bg;
    overflow: visible;
}

body .fixed-navbar{
    padding-top: 70px;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #E0DFDF;
  border-bottom: 1px solid #FEFEFE;
}

button.square {
    border-radius:0;
    padding:4px 10px;
}


/**
 *---------------------------------------------------------------
 * NAVBAR
 *---------------------------------------------------------------
*/
header .navbar {
    border-radius: 0;
    margin-bottom: 0;
}

.logout-button {
    position:relative;
}

.logout-button span {
    display:block;
    background-color:#fff;
    position:absolute;
    width:30px;
    height:5px;
    bottom: 22px;
    left:50%;
    margin-left:-15px;
}

.logout-button:hover span {
    background-color: @navbar-link-hover-bg !important;

}

/* Header Nav */
.header-nav {
    min-height: 0 !important;
    height: 69px;
    background: @navbar-bg;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: @navbar-border;
    top: 0;


    /* Navbar */
    .navbar-collapse {
        border-color: @navbar-border;
    }
    .navbar-brand {
        height: auto;
    }

    /* Header Nav Logo */
    .navbar-brand.header-logo {
        margin: 0;
        padding: 5px 0 0 10px;
    }

    /* Header Nav Text */
    .navbar-brand.header-text {
        margin: 0;
        padding: 10px 10px 11px 10px;
        font-size: 20px;
        line-height: 1.2;
        color: #EEE;
    }
    .navbar-brand.header-text .glyphicons {
        line-height: 1;
        vertical-align: text-top;
    }

    .navbar-nav {
        border-left: 1px solid darken(@navbar-bg, 7%);
        .open {
            a:hover, a:focus, a {
                color: inherit !important;
                border:inherit !important;
            }
        }
    }

    .search-form {
        padding:9px 0;

        input[type=text] {
            border-radius:0;
            background-color: @search-bar-bg;
            border:0;
            font-style:italic;
            box-shadow: none;
        }

        button {
            background-color: @search-bar-bg;

            border:0;
            box-shadow: 0;
            i {
                font-size:24px;
                padding: 0 5px;
            }
        }

    }


    /* Sidebar Toggle */
    .sidebar-toggle {
        float: left;
        padding: 25px 29px 25px;
        background-color: @sidebar-toggle;
        border:1px solid;
        border-color: @sidebar-toggle-border-color;
        position:relative;

        .triangle-icon {
            position:absolute;
            left: 15px;
            bottom:50%;
            margin-bottom:-4px;
        }

        .left-pointing {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4.5px 5px 4.5px 0;
            border-color: transparent @brand-primary transparent transparent;
        }

        .right-pointing {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4.5px 0 4.5px 5px;
            border-color: transparent transparent transparent @brand-primary;
        }

    }
    .sidebar-toggle:hover,
    .sidebar-toggle:active,
    .sidebar-toggle:focus {
        outline: none;
        background-color: @sidebar-toggle-hover-bg;
        border-color: @sidebar-toggle-border-color;
        transition: background-color .5s linear;
    }
    .sidebar-toggle .icon-bar {
        display: block;
        width: 20px;
        height: 3px;
        border-radius: 1px;
        background-color: #333645;
    }
    .sidebar-toggle .icon-bar + .icon-bar {
        margin-top: 4px;
    }


    /* Menu */

    .navbar-nav {
        margin: 0;
    }
    .navbar-nav > li > a,
    .navbar-nav > li > a:active,
    .navbar-nav > li > a:visited {
        color: @navbar-link-color;
        padding: 23px 26px 20px;
        border-radius:0;
    }
    .navbar-nav > li > a > i {
        font-size:26px;
    }


    .navbar-nav > li > a:hover {
        color: @navbar-link-hover-color;
        background-color: @navbar-link-hover-bg;
    }
    .navbar-nav > .active > a,
    .navbar-nav > .active > a:hover {
        color: @navbar-link-hover-color;
        background-color: @navbar-link-hover-bg;
    }


    /* Header Nav Dropdown */
    .navbar-nav .dropdown-menu {
        margin: 0;
        padding: 0;
    }
    .navbar-nav .dropdown-menu > li > a:hover,
    .navbar-nav .dropdown-menu > li > a:focus,
    .navbar-nav .dropdown-menu > .active > a,
    .navbar-nav .dropdown-menu > .active > a:hover,
    .navbar-nav .dropdown-menu > .active > a:focus {
        background-color: @navbar-bg;
        background-image: none;
    }

    /* Header Nav Open */
    .navbar-nav .open .divider {
        background-color: lighten(@navbar-bg, 7%);
    }
    .navbar-nav .open .dropdown-header {
        color: #999;
    }
    .navbar-nav > .open > a,
    .navbar-nav > .open > a:hover,
    .navbar-nav > .open > a:focus {
        color: @white;
        background-color: lighten(@navbar-bg, 7%);
    }
    .navbar-nav .open .dropdown-menu {
        background: darken(@navbar-bg, 4%);
    }
    .navbar-nav .open .dropdown-menu > li > a {
        color: @navbar-link-color;
        display: block;
        padding: 10px 15px;
    }
    .navbar-nav .open .dropdown-menu > li > a:hover {
        color: @navbar-link-hover-color;
        background: lighten(@navbar-bg, 7%);
    }

    /* Toolbar */
    .toolbar {
        float: left;


    }
    .toolbar .glyphicons {
        vertical-align: middle;
    }
    .toolbar a {
        display: inline-block;
        background: none;
        border: none;
        color: @navbar-link-color;
    }
    .toolbar a:hover {
        background: @navbar-link-active-bg;
        color: @navbar-link-hover-color;
    }
    .toolbar ul.extended {
        min-width: 300px;
    }
    .toolbar-header {
        padding: 10px;
        background-color: @sidebar-bg;
        color: @white;
        margin: 0;
    }
    .toolbar-item {
        overflow: hidden;
    }
    .toolbar-footer {
        margin: 0;
        padding: 0;
    }
    .toolbar-footer a {
        background-color: darken(@navbar-bg, 7%);
    }

    /* Notification */
    .toolbar .notification_icon .unread {
        color: @red;
    }
    .toolbar .notification_list a.unread {
        color: @red !important;
    }

    /* Search bar*/
    .toolbar form.search-form {
        padding: 5px;
    }
}

/* Multi Level Dropdown */
.dropdown-submenu {
    position: relative;

    .pull-left {
        float: none;

        > .dropdown-menu {
            left: -100%;
            margin-left: 10px;
            -webkit-border-radius: 6px 0 6px 6px;
            -moz-border-radius: 6px 0 6px 6px;
            border-radius: 6px 0 6px 6px;
        }
    }

    > .dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -6px;
        margin-left: -1px;
        -webkit-border-radius: 0 6px 6px 6px;
        -moz-border-radius: 0 6px 6px;
        border-radius: 0 6px 6px 6px;
    }

    > a {
        &:after {
            display: block;
            content: " ";
            float: right;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px 0 5px 5px;
            border-left-color: #ccc;
            margin-top: 5px;
            margin-right: -10px;
        }
    }

    &:hover {
        > .dropdown-menu {
            display: block;
        }

        > a {

            &:after {
                border-left-color: #fff;
            }
        }
    }
}


.dropdown-menu-right {

    .dropdown-submenu {

        > .dropdown-menu {
            left: -100%;
        }

        > a {
            &:after {
                float: left;
                border-width: 5px 5px 5px 0;
                border-right-color: #ccc;
            }
        }

        &:hover {
            > a {
                :after {
                    border-right-color: #fff;
                }
            }
        }
    }
}


/**
 *---------------------------------------------------------------
 * BREADCRUMBS
 *---------------------------------------------------------------
*/
.breadcrumbs{
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    height: 0;

    .breadcrumb {
        margin: 0;
        list-style: none;
        background: @breadcrumb-bg;
        border-radius: 0;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: @white-dark;
        color:@white;

        a {
            color:@white;
        }
    }
}

/**
 * 992px breakpoint 62em
 */
@media only screen and (min-width: 992px) {
    .breadcrumbs{
        display: block;
        height: auto;
        top: 69px;

        .breadcrumb {
            padding: 8px 15px;
        }
    }
}

.spacer {
    display:block;
    clear:both;
    margin-bottom:20px;
}



/**
 * Sidebar and Activity bar styles
 */
#sidebar,
#activity-bar {
    margin: 0;
    padding: 0;
    z-index: 1000;
    position: fixed;

    #sidebar-inner,
    #activity-bar-inner {
        min-height: 940px;
        width: 300px;

        // Menu wrapper
        .menu-wrapper {
            overflow: auto;
        }
    }
}


/*
 * Sidebar
 */
#sidebar {
    top: 0;
    left: -300px;

    #sidebar-inner {
        background: @sidebar-bg;

        /* Sidebar Account */
        .account {
            display: block;
            position: relative;
            border-right: 1px solid darken(@brand-primary, 10%);

            /* Sidebar Account Detail */
            .account-detail {
                cursor: pointer;
                background: darken(@brand-primary, 7%);
            }
            .account-detail.active {
                background-color: darken(@brand-primary, 7%);
            }
            .account-image {
                float: left;
                overflow: hidden;
                border-radius: 50% !important;
                margin: 15px 10px 14px 20px;
            }
            .account-image img {
                width: 40px;
                height: 40px;
            }
            .account-name {
                float: left;
                color: @gray-lighter;
                font-size: 14px;
                line-height: 20px;
                padding: 14px 10px;

                .user-role {
                    font-size: 11px;
                    color: @white-darker;
                }
            }

            .text {
                margin-left: 8px;
                display: inline !important;
            }
        }

        /* Sidebar Nav List */
        .nav-list > li {
            border-bottom: 1px solid darken(@sidebar-bg, 8%);

            a {
                margin: 0;
                color: @sidebar-link-color;
                padding: 15px 15px;
                line-height: 18px;
                i {
                    font-size: 18px;
                    margin-right: 5px;
                    margin-top: 1px;
                    display: block;
                    float: left;
                }
            }
        }
        .nav-list > li > a:hover,
        .nav-list > li > a:focus {
            border-left: 5px solid @brand-primary;
            background-color: @sidebar-link-hover-bg;
            color: @sidebar-link-hover-color;
        }
        .nav-list > li > a:hover .glyphicons,
        .nav-list > li > a:hover .text {
            color: @sidebar-link-hover-color;
        }
        .nav-list > li.active > a,
        .nav-list > li.active > a:hover {
            border-left: 5px solid @brand-primary;
            background-color: @sidebar-link-hover-bg;
            color: @sidebar-link-hover-color;

        }
        .nav-list > li.active .indicator {
            top: 15px;
            right: 0;
            position: absolute;
            border-top: 10px solid transparent;
            border-right: 10px solid #EBF1F5;
            border-bottom: 10px solid transparent;
        }
        .glyphicons {
            color: @sidebar-link-color;
            font-size: 16px;
            margin-right: 7px;
        }
        .text {
            color: @sidebar-link-color;
            padding-bottom: 3px;
        }

        /* Sidebar Nav List Sub Nav */
        .nav-list {
            .sub-nav {
                display: none;
                background-color: lighten(@sidebar-bg, 4%);
            }
            > li.open {
                position: relative;

                > ul {
                    display: block;

                    &:before {
                        border-left: 1px dashed rgba(255, 255, 255, 0.3);
                        bottom: 0;
                        content: "";
                        display: block;
                        left: 23px;
                        position: absolute;
                        top: 50px;
                        z-index: 1;
                    }
                    > li {
                        position: relative;

                        &:before {
                            content: "";
                            display: block;
                            left: 23px;
                            position: absolute;
                            top: 19px;
                            width: 8px;
                            z-index: 1;
                        }
                        a {
                            display: block;
                            padding: 10px 0 10px 40px;
                            color: @gray-lighter;

                            &:before {
                                content: "";
                                left: 25px;
                                top: 19px;
                                position: absolute;
                                display: block;
                                width: 12px;
                                border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
                                z-index: 1;
                            }
                        }
                        a:hover,
                        &.active a {
                            background-color: darken(@sidebar-bg, 7%);
                            border-left: 5px solid @brand-primary;
                            text-decoration: none;
                        }
                    }
                }
            }


            // Group
            .group {

                > a:before {
                    content: "+";
                    position: absolute;
                    top: 15px;
                    right:25px;
                    display: inline-block;
                    color: rgba(255, 255, 255, 0.8);
                    font-size: 12px;
                    font-weight: inherit;
                    transform: rotate(0deg);
                    transition: all 0.15s linear;
                }

                &.open {
                    transition: all 1s ease;

                    > a {
                        background-color: @gray;
                        border-bottom: 1px;
                        color: #FFFFFF;

                        &:before {
                            transform: rotate(-45deg);

                        }
                    }
                }
            }
        }
    }
}


/**
 * Activity Bar
 */
#activity-bar {
    top: 0;
    right: -300px;
    border-left: 1px solid #dee5e7;

    #activity-bar-inner {
        background: @activity-bar-bg;

    }
}


/**
 * Sidebar open / Activity bar open
 */
body.sidebar-open,
body.activity-bar-open {

    // Transition
    .navbar-fixed-top,
    .breadcrumbs,
    #app-container,
    #sidebar,
    #activity-bar {
        transition-duration: 0.3s;
    }
}

/**
 * Sidebar open
 */
body.sidebar-open {
    #sidebar {
        left: 0;
    }
}

/**
 * Activity bar open
 */
body.activity-bar-open {
    #activity-bar {
        right: 0;
    }
}


/**
 * 768px breakpoint 48em
 */
@media only screen and (min-width: 768px)
{
    /**
     * Sidebar open
     */
    body.sidebar-open {
        .navbar-fixed-top,
        .breadcrumbs,
        #page-head .sticky-actions {
            left: 300px;
            right: 0;
        }
        #app-container {
            margin-left: 300px;
        }
    }

    /**
     * Activity bar open
     */
    body.activity-bar-open {
        #app-container {
            margin-right: 300px;
        }
    }
}


/**
 * Sidebar & Activity bar open
 */
body.sidebar-open.activity-bar-open {

}



/**
 * Sidebar closed / Activity bar closed
 */
body.sidebar-closed,
body.activity-bar-closed {

    // Transition
    .navbar-fixed-top,
    .breadcrumbs,
    #app-container,
    #sidebar,
    #activity-bar,
    #page-head .sticky-actions {
        transition-duration: 0.2s;
    }
}


/**
 * --------------------------------------------------------------
 * CONTENT HEAD
 * --------------------------------------------------------------
 */
#page-head {
    background-color: @page-header-color;
    margin-bottom:40px;
    padding-top:20px;
    border-bottom: 1px solid @border-color;
    min-height: 140px; /* Do not change or sticky actions will break */

    &.row {
        margin-left: -40px;
        margin-right: -30px;
    }

    h1 {
        margin:0;
        line-height: 1.5;
        padding-bottom: 10px;
        word-break: break-all;
        word-wrap: break-word;
        max-height: 40px;
        overflow: hidden;
    }

    .navbar-toggle {
        display: none;
    }

    .flag {
        margin-left: 10px;
        margin-top: 2px;
        margin-bottom: 0;
        display: inline-block;
    }

    .default-subtext {
        margin-bottom:20px;
        display:block;
    }
    .navbar-nav {
        margin: 20px 0 0 0;

        li {
            float: left;
            margin-right: 20px;

            a {
                text-transform: uppercase;
                font-size: 13px;
                letter-spacing:0.5px;
                font-weight: bold;
                line-height: 26px;
                padding: 0;
            }
            a:hover, a:active, a:focus {
                background-color:#fff;
            }
        }
        li.active {
            border-bottom: 3px solid @brand-primary;
            margin-bottom: -3px;
        }
    }

    .edited-icon {
        margin-left:50px;
    }

    .timestamps {
        .created {
            margin-right: 10px;
        }
        .updated {
            margin-right: 10px;
        }
    }

    .sticky-actions {
        &.affix {
            position: fixed;
            top: 69px;
            left: 0;
            right: 0;
            z-index: 999;
            background-color: #FFF;
            margin: 0;
            padding: 10px 15px;
            border-bottom: 1px solid #F2F2F2;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

            .timestamps,
            .default-subtext,
            .flag,
            .account-number {
                display: none;
            }

            h1, h2, h3, h4,
            .action-buttons {
                line-height: 1.5;
                margin: 0;
                padding: 0;
            }
        }
    }
}

/**
 * 768px breakpoint 48em
 */
@media only screen and (min-width: 768px) {
    #page-head {
        .navbar-nav {
            li {
                margin-right: 40px;

                a {
                    line-height: 36px;
                }
            }
        }
    }
}

/**
 * 992px breakpoint 62em
 */
@media only screen and (min-width: 992px) {
    #page-head {
        &.affix {
            top: 105px;
        }
        .navbar-nav {
            li {
                margin-top: 3px;
            }
            li.active {
                border-bottom: 3px solid @brand-primary;
                margin-bottom: 0;
            }
        }
    }
}


/**
 *---------------------------------------------------------------
 * CONTENT STYLES
 *---------------------------------------------------------------
*/
#middle {
    padding-top: 69px;
    padding-bottom: 30px;

    > .container {
        padding-top: 15px;
    }

    h1 {
        font-style: italic;
        font-size: 24px;
    }
}

.timestamps i {
    margin-top: 1px;
    display: inline-block;
}

/**
 * 992px breakpoint 62em
 */
@media only screen and (min-width: 992px) {
    #middle {
        padding-top: 105px;
    }
}

/**
 *---------------------------------------------------------------
 * ALERT STYLES
 *---------------------------------------------------------------
*/

#message-area {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 500px;
    z-index: 10000;

    .alert {
        width: 100%;
    }
}


/**
 *---------------------------------------------------------------
 * EDITABLE STYLES
 *---------------------------------------------------------------
*/
.editable{
    border-bottom:1px dashed @white-dark;
}

.editable.active{
    background: @yellow-lighter;
    padding: 2px;
}


/**
 *---------------------------------------------------------------
 * USER STYLES
 *---------------------------------------------------------------
*/
#contact-details {
    margin-bottom: 20px;
}


/**
 *---------------------------------------------------------------
 * SEARCH STYLES
 *---------------------------------------------------------------
*/
.search-results {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}


/**
 *---------------------------------------------------------------
 * MODAL STYLES
 *---------------------------------------------------------------
*/

/**
 * Modal open
 */
body.modal-open {
    margin-right: 0;

    .navbar-fixed-top,
    .navbar-fixed-bottom {
        margin-right: 0;
    }
}


/**
 * Modal backdrop style
 */
.modal-backdrop {
    background-color: #000;
}


/**
 * Modal styles
 */
.modal {
    overflow: hidden;

    .modal-dialog {
        position: fixed;
        left: 0;
        right: 0;
        background-color: #fafafa;
        border-radius: 2px;
        box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);

        // Modal header
        .modal-header {
            color: #555;
            padding: 15px 20px;
            background: #FFF;
            border-bottom: 1px solid #EDF1F2;

            .modal-title,
            .close {
                color: #555;
                &:hover,
                &focus {
                    color: #333;
                }
            }
        }

        // Modal body
        .modal-body {
            padding: 10px 20px;
            margin: 10px 0;
        }

        // Modal footer
        .modal-footer {
            padding: 10px 20px;
            background: #FFF;
        }

        /**
         * Not scrollable
         */
        .modal-body.no-scroller {
            overflow: scroll !important;

            .modal-footer {
                border-top: none;
            }
        }
    }
}


/**
 * Prompt styles
 */
.prompt {
    text-align: center;

    .prompt-icon {
        text-align: center;
        display: block;
        width: 90px;
        height: 90px;
        border-radius: 50%!important;
        font-size: 55px;
        filter: alpha(opacity=50);
        opacity: .5;
        color: #666;
        margin: 0 auto 20px auto;
        border: 3px solid #666;

        span,
        i {
            line-height: inherit;
        }
    }
    .prompt-title {
        color: #666;
        font-weight: bold;
        font-size: 1.5em;
        margin-bottom: 5px;
        color: #333;
    }
    .prompt-message {
        color: #666;
        font-size: 1.1em;
    }
    .modal-footer {
        margin-top: 0;
        border-top: none;

        .primary-action {
            display: block;
            padding: 12px 16px;
            font-size: 18px;
            line-height: 1.3333333;
            width: 55%;
            margin: 0 auto 20px auto;
        }
        .secondary-action {
            display:none;
        }
    }
}


/**
 * 480px breakpoint 30em
 */
@media only screen and (max-width: 599px) {
    .modal-dialog {
        top: 1%;

        .modal-body{
            max-height: 200px;
        }
    }
}

/**
 * 600px breakpoint 38em
 */
@media only screen and (min-width: 600px) {
    .modal-dialog {
        top: 1%;

        .modal-body {
            max-height: 300px;
        }
    }
}

/**
 * 768px breakpoint 48em
 */
@media only screen and (min-width: 768px) {
    .modal-dialog {
        top: 5%;

        .modal-body {
            max-height: 400px;
        }
    }
}

/**
 * 992px breakpoint 62em
 */
@media only screen and (min-width: 992px) {
    .modal-dialog {
        top: 8%;

        .modal-body {
            max-height: 500px;
        }
    }
}

/**
 * 1382px breakpoint 86em
 */
@media only screen and (min-width: 1382px) {
    .modal-dialog {
        top: 9%;

        .modal-body {
            max-height: 600px;
        }
    }
}



/**
 *---------------------------------------------------------------
 * BLOG STYLES
 *---------------------------------------------------------------
*/
section#article {

    h2 {
        margin-top: 0;
    }

    p.article-date {
        color: lighten(@gray, 30%);
        font-size: 12px;

        i {
            margin-right: 20px;
        }
    }

    p {
        line-height: 24px;
        font-size: 13px;
    }
}


/**
 *---------------------------------------------------------------
 * ANGULAR -- Animate If statements
 *---------------------------------------------------------------
*/
.animate-container {
    position: relative;
}

.animate-if {

    &.ng-enter, &.ng-leave {
        -webkit-transition:0.2s linear all;
        transition:0.2s linear all;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &.ng-enter {
      z-index:100;
      opacity:0;
    }

    &.ng-enter.ng-enter-active {
      -webkit-transition:0.2s linear all 0.2s;
      opacity:1;
    }

    &.ng-leave {
      z-index:101;
      opacity:1;
    }

    &.ng-leave.ng-leave-active {
      opacity:0;
    }

}


/**
 *---------------------------------------------------------------
 * CMS PARTIAL STYLES
 *---------------------------------------------------------------
*/
.partial-header {
    &.panel-heading {
        background-color: @white;
    }
}

.panel-heading {
    text-transform: uppercase;
    font-weight:bold;
    font-size:14px;
    background-color: @panel-heading-bg !important;
}

#redactor_image_box_select {
    display: none !important;
}

.partial-2-col-text-and-images {
    .dropzone,
    .dropzone-block {
        min-height: 443px;
    }
}


/**
 *---------------------------------------------------------------
 * TABLE HELPERS
 *---------------------------------------------------------------
*/

    .table {
        .has-actions {
            line-height: 28px;
        }
    }


/**
 *---------------------------------------------------------------
 * RESPONSIVE HELPERS
 *---------------------------------------------------------------
*/
.form-horizontal .form-group {

}

@media (max-width: @screen-xs-max)
{
    .form-group-xs {
        margin-bottom: 20px;
    }
    .form-group-not-xs {
        margin-bottom: 0;
    }
}


@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max)
{
    .right-sm {
        float: right;
    }
    .form-group-sm {
        margin-bottom: 15px;
    }
    .form-group-not-sm {
        margin-bottom: 0;
    }

}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max)
{
    .right-md {
        float: right;
    }
    .form-group-md {
        margin-bottom: 15px;
    }
    .form-group-not-md {
        margin-bottom: 0;
    }
}

@media (min-width: @screen-lg-min)
{
    .right-lg {
        float: right;
    }
    .form-group-lg {
        margin-bottom: 15px;
    }
    .form-group-not-lg {
        margin-bottom: 0;
    }
}


/**
 *---------------------------------------------------------------
 * SMALL MOBILE - Max Width: 480px breakpoint 30em
 *---------------------------------------------------------------
*/
@media only screen and (max-width: 480px)
{

}


/**
 *---------------------------------------------------------------
 * MOBILE - Min Width: 600px breakpoint 38em
 *---------------------------------------------------------------
*/
@media only screen and (min-width: 600px)
{
    .header-nav .navbar-brand.header-text{
        width:185px;
    }
}


/**
 *---------------------------------------------------------------
 * OFFCANVAS - Max Width: 768px breakpoint 48em
 *---------------------------------------------------------------
*/
@media screen and (max-width: 767px)
{

}


/**
 *---------------------------------------------------------------
 * TABLET - Min Width: 768px breakpoint 48em
 *---------------------------------------------------------------
*/
@media only screen and (min-width: 768px)
{

}


/**
 *---------------------------------------------------------------
 * SMALL DESKTOP- Min Width: 992px breakpoint 62em
 *---------------------------------------------------------------
*/
@media only screen and (min-width: 992px)
{
    .header-nav .toolbar ul.extended{
        min-width: 350px;
    }
}


/**
 *---------------------------------------------------------------
 * LARGE DESKTOP- Min Width: 1382px breakpoint 86em
 *---------------------------------------------------------------
*/
@media only screen and (min-width: 1382px)
{

}


/**
 *---------------------------------------------------------------
 * LARGE DESKTOP- Retina Display
 *---------------------------------------------------------------
*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {


}
