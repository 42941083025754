
/**
 *---------------------------------------------------------------
 * FLIPCLOCK
 *---------------------------------------------------------------
*/
    .timer{
        float:left;
        padding:0 5px;
        margin:0;
        border-left:1px solid #111;
    }
    .timer .glyphicons {
        font-size: 16px;
        line-height: 1.2;
        vertical-align: middle;
    }


    /* Clock Btns */
    .clock-btns{
        float:left;
        margin-right:10px;
    }
    .clock-btns .btn{
        display: inline-block;
        background: none;
        border:none;
        color: #CCC;
        padding: 11px 15px;
    }
    .clock-btns .btn:hover{
        background: #111;
        color: #FFF;
    }

    /* Main */
    .flip-clock-wrapper {
        min-height: 100%;
        font: normal 11px "Helvetica Neue", Helvetica, sans-serif;
        -webkit-user-select: none;
    }

    .flip-clock-meridium {
        background: none;
        box-shadow: 0 0 0 !important;
        font-size: 14px !important;
        color: rgb(49, 51, 51);
        bottom: 10px;
    }

    .flip-clock-wrapper {
        text-align: center;
        position: relative;
        width: auto;
        margin: 7px 0 0 0;
        float: left;
    }


    /* Skeleton */
    .flip-clock-wrapper ul {
        position: relative;
        float: left;
        margin: 0 2px;
        width: 26px;
        height: 31px;
        font-size: 100px;
        font-weight: bold;
        line-height: 31px;
        border-radius: 6px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .7);
    }

    .flip-clock-wrapper ul li {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .flip-clock-wrapper ul li a {
        display: block;
        height: 100%;
        perspective: 200px;
        margin: 0 !important;
        overflow: visible !important;
    }

    .flip-clock-wrapper ul li a div {
        z-index: 1;
        position: absolute;
        left: 0;
        width: 100%;
        height: 50%;
        overflow: hidden;
    }

    .flip-clock-wrapper ul li a div .shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .flip-clock-wrapper ul li a div.up {
        transform-origin: 50% 100%;
        top: 0;
    }

    .flip-clock-wrapper ul li a div.up:after {
        content: "";
        position:absolute;
        top:15px;
        left:0;
        z-index: 5;
        width: 100%;
        height: 3px;
        background:#171717;
        border-top: 1px solid #393939;
        border-bottom: 1px solid #393939;
        background-color: rgba(0,0,0,.4);
    }

    .flip-clock-wrapper ul li a div.down {
        transform-origin: 50% 0%;
        bottom: 0;
    }

    .flip-clock-wrapper ul li a div div.inn {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 200%;
        color: #FFFFFF;
        text-shadow: 0 1px 2px #000;
        text-align: center;
        background-color: #333;
        border-radius: 6px;
        font-size: 26px;
        font-family: Arial;
    }


    /* PLAY */
    .flip-clock-divider {
        float: left;
        display: inline-block;
        position: relative;
        width: 15px;
        height: 31px;
        margin-top:5px;
    }

    .flip-clock-divider:first-child {
        width: 0;
    }

    .flip-clock-dot {
        display: block;
        background: #DDD;
        width: 3px;
        height: 3px;
        position: absolute;
        border-radius: 1000px;
        box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    }

    .flip-clock-divider .flip-clock-label {
        position: absolute;
        top:30px;
        bottom:0;
        color: #DDD;
        text-shadow: none;
        display:none;
    }
    .flip-clock-divider.hours .flip-clock-label {
        right: -45px;
    }
    .flip-clock-divider.minutes .flip-clock-label {
        right: -45px;
    }
    .flip-clock-divider.seconds .flip-clock-label {
        right: -55px;
    }

    .flip-clock-dot.top {
        top: 10px;
        left: 5px;
    }

    .flip-clock-dot.bottom {
        bottom: 10px;
        left: 5px;
    }
