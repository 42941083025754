
/**
 *---------------------------------------------------------------
 * SHARED STYLES
 *---------------------------------------------------------------
*/
    .wysiwyg-footer {
        background: #F0F0F0;
        border: 1px solid #DDD;
        border-top: none;
        min-height: 20px;
        padding: 5px;
    }
    .wysiwyg-footer .word-count{
        font-size:12px;
    }


/**
 *---------------------------------------------------------------
 * TINY MCE
 *---------------------------------------------------------------
*/
    .has-tiny .mce-panel {
        border: 0 solid #DDD !important;
        background-color: #F0F0F0 !important;
        background-image: none !important;
    }
    .mce-panel {
        border-color: #CCC;
    }


/**
 *---------------------------------------------------------------
 * REDACTOR
 *---------------------------------------------------------------
*/
    .redactor_toolbar {
        background: #F0F0F0 !important;
        border: 1px solid #DDD !important;
        border-bottom: none !important;
        box-shadow: none !important;
    }
    .redactor_editor {
        border: 1px solid #DDD !important;
    }
