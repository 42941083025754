/**
 *---------------------------------------------------------------
 * IMAGES
 *---------------------------------------------------------------
*/

    .img-bg,
    .img-cover,
    .img-contain {
        display: block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        &.img-contain {
            background-size: contain;
        }
    }
