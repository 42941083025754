/**
 * ---------------------------------------------------------------
 * VARIABLES AND MIXINS
 * ---------------------------------------------------------------
 * Include variables and mixins
 */
/**
 *---------------------------------------------------------------
 * COLOURS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * TYPOGRAPHY
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * FONTS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * COMPONENTS
 *---------------------------------------------------------------
 * Define common padding and border radius sizes and more.
 * Values based on 14px text and 1.428 line-height (~20px to start).
*/
/**
 *---------------------------------------------------------------
 * TABLES
 *---------------------------------------------------------------
 * Customizes the `.table` component with basic values, each used across all table variations.
*/
/**
 *---------------------------------------------------------------
 * BUTTONS
 *---------------------------------------------------------------
 * For each of Bootstrap's buttons, define text, background and border color.
*/
/**
 *---------------------------------------------------------------
 * FORMS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * DROPDOWNS
 *---------------------------------------------------------------
 * Dropdown menu container and contents.
*/
/**
 *---------------------------------------------------------------
 * Z-INDEX MASTER LIST
 *---------------------------------------------------------------
 * Warning: Avoid customizing these values. They're used for a bird's eye view
 * of components dependent on the z-axis and are designed to all work together.
 * Note: These variables are not generated into the Customizer.
*/
/**
 *---------------------------------------------------------------
 * MEDIA QUERIES
 *---------------------------------------------------------------
 * Define the breakpoints at which your layout will change,
 * adapting to different screen sizes.
*/
/**
 *---------------------------------------------------------------
 * GRID SYSTEM
 *---------------------------------------------------------------
 * Define your custom responsive grid.
*/
/**
 *---------------------------------------------------------------
 * CONTAINER SIZES
 *---------------------------------------------------------------
 * Define the maximum width of `.container` for different screen sizes.
*/
/**
 *---------------------------------------------------------------
 * NAVBAR
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * NAVS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * PAGINATION
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * JUMBOTRON
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * FORM STATES AND ALERTS
 *---------------------------------------------------------------
 * Define colors for form feedback states and, by default, alerts.
*/
/**
 *---------------------------------------------------------------
 * TOOLTIPS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * POPOVERS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * LABELS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * MODALS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * ALERTS
 *---------------------------------------------------------------
 * Define alert colors, border radius, and padding.
*/
/**
 *---------------------------------------------------------------
 * PROGRESS BARS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * LIST GROUP
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * PANELS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * THUMBNAILS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * WELLS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * BADGES
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * BREADCRUMBS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * CAROUSEL
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * CLOSE BUTTON
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * CODE
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * TYPE
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * MISCELLANEOUS
 *---------------------------------------------------------------
*/
/**
 * ---------------------------------------------------------------
 * Admin Override, do not remove.
 * ---------------------------------------------------------------
 */
/**
 *---------------------------------------------------------------
 * COLOURS
 *---------------------------------------------------------------
 * Gray and brand colors for use across Bootstrap.
*/
/**
 *---------------------------------------------------------------
 * TYPOGRAPHY
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * COMPONENTS
 *---------------------------------------------------------------
 * Define common padding and border radius sizes and more.
 * Values based on 14px text and 1.428 line-height (~20px to start).
*/
/**
 *---------------------------------------------------------------
 * NAVBAR
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * NAVS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * DROPDOWNS
 *---------------------------------------------------------------
 * Dropdown menu container and contents.
*/
/**
 * SEARCH FORM
 */
/**
 *---------------------------------------------------------------
 * BREADCRUMBS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * SIDEBAR
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * Activity bar
 *---------------------------------------------------------------
 */
/**
 * Page Header
 */
/**
 *---------------------------------------------------------------
 * BUTTONS
 *---------------------------------------------------------------
 * For each of Bootstrap's buttons, define text,
 * background and border color.
*/
/**
 *---------------------------------------------------------------
 * TABLES
 *---------------------------------------------------------------
 * Customizes the `.table` component with basic values,
 * each used across all table variations.
*/
/**
 *---------------------------------------------------------------
 * PANELS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * LIST GROUPS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * PAGINATION
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * FORMS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * MISC ITEMS
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * BRAND COLOURS
 *---------------------------------------------------------------
*/
/**
 * ---------------------------------------------------------------
 * MAIN TEMPLATE
 * ---------------------------------------------------------------
 */
/**
 *---------------------------------------------------------------
 * HTML OVERRIDES
 *---------------------------------------------------------------
*/
html,
body {
  overflow-x: hidden;
  /* Prevent scroll on narrow devices */
}
/*
Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't
properly apply the media queries in Bootstrap's CSS. To address this,
you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/
@-webkit-viewport {
  width: device-width;
}
@-moz-viewport {
  width: device-width;
}
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
/* Internet Explorer 10 doesn't differentiate device width from viewport width,
and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied */
@-ms-viewport {
  width: auto !important;
}
html {
  overflow-y: scroll;
}
/**
 *---------------------------------------------------------------
 * BROWSER OVERRIDES
 *---------------------------------------------------------------
*/
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
/** Remove the gap between images, videos, audio and canvas and the bottom of their containers */
audio,
canvas,
img,
video {
  vertical-align: middle;
}
/** Remove default fieldset styles.*/
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
/** Allow only vertical resizing of textareas.*/
textarea {
  resize: vertical;
}
i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
}
/** Fix link outlines after click */
a,
a:focus,
a:hover,
a:active {
  outline: 0;
}
/**
 *---------------------------------------------------------------
 * GLYPHICONS PRO FIX
 *---------------------------------------------------------------
*/
.glyphicons,
.glyphicons:before {
  position: relative;
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  color: inherit;
  text-decoration: none;
  vertical-align: top;
}
.glyphicons {
  padding-left: 0;
}
.glyphicons:before {
  font-family: 'Glyphicons Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bootstrap-select i.check-mark.glyphicons.ok:before {
  content: "\E207";
}
/**
 *---------------------------------------------------------------
 * GOOGLE MAP BOOTSTRAP FIX
 *---------------------------------------------------------------
*/
.gm-style img {
  max-width: none;
}
/**
 *---------------------------------------------------------------
 * BUTTON OVERRIDES
 *---------------------------------------------------------------
*/
/* fix jumping group buttons */
.btn-group.btn-group-solid .btn + .btn,
.btn-group.btn-group-solid .btn + .btn-group.btn-group-solid,
.btn-group.btn-group-solid .btn-group.btn-group-solid + .btn,
.btn-group.btn-group-solid .btn-group.btn-group-solid + .btn-group.btn-group-solid {
  margin-left: 0;
}
.btn-group-vertical.btn-group-solid > .btn + .btn,
.btn-group-vertical.btn-group-solid > .btn + .btn-group,
.btn-group-vertical.btn-group-solid > .btn-group + .btn,
.btn-group-vertical.btn-group-solid > .btn-group + .btn-group {
  margin-top: 0;
  margin-left: 0;
}
.input-group .btn {
  border-radius: 0 4px 4px 0;
}
/* Fix for weird hover problem */
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: 0;
}
/**
 *---------------------------------------------------------------
 * FORM OVERRIDES
 *---------------------------------------------------------------
*/
/** Pretty Check Boxes */
.form-group .prettycheckbox label,
.form-group .prettyradio label {
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  margin: 0 5px;
}
.prettyblock .prettycheckbox,
.prettyblock .prettyradio {
  display: block;
}
.prettyblock .has-pretty-child {
  margin-bottom: 20px;
}
.has-switch > div.switch-off {
  margin-left: 0 !important;
}
/**
 *---------------------------------------------------------------
 * GOOGLE MAP BOOTSTRAP FIX
 *---------------------------------------------------------------
*/
.dropdown-menu.text-select li.selected {
  position: relative;
}
.dropdown-menu.text-select li.selected i.glyphicons {
  display: inline-block;
  position: absolute;
  right: 15px;
  margin-top: 2.5px;
}
/**
 *---------------------------------------------------------------
 * TABLE OVERRIDES
 *---------------------------------------------------------------
*/
.dataTables_wrapper .table {
  width: 100% !important;
}
.table li.divider {
  margin: 10px 0;
  padding: 0;
}
/* Bootstrap Table Info Replacement*/
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
  border-color: #DDD;
}
/**
 *---------------------------------------------------------------
 * MODAL OVERRIDES
 *---------------------------------------------------------------
*/
.form-tabs {
  margin-bottom: 15px;
}
/**
 *---------------------------------------------------------------
 * SPINNER OVERRIDES
 *---------------------------------------------------------------
*/
.spinner .input-group-sm {
  width: 100px;
  margin: 0 auto;
}
.spinner .input-group-sm .btn {
  font-size: 14px;
  font-weight: bold;
}
/**
 *---------------------------------------------------------------
 * DATEPICKER OVERRIDES
 *---------------------------------------------------------------
*/
.datepicker {
  margin-top: 0;
}
/**
 *---------------------------------------------------------------
 * LABEL / BADGE OVERRIDES
 *---------------------------------------------------------------
*/
/* fix badge position for navs */
.nav.nav-pills > li > a > .badge {
  margin-top: -2px;
}
.nav.nav-stacked > li > a > .badge {
  margin-top: 1px;
  margin-bottom: 0;
}
/**
 *---------------------------------------------------------------
 * PAGE TOP LINK
 *---------------------------------------------------------------
*/
body > #top-shortcut {
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 99999;
}
body > #top-shortcut a {
  background-color: #555555;
  color: #FFF;
  border-radius: 2px !important;
  font-size: 14px;
  padding: 7px 9px;
}
body > #top-shortcut a:hover {
  text-decoration: none;
  background-color: #af1a1a;
  transition: 250ms linear all;
}
/**
 *---------------------------------------------------------------
 * NO JS WARNING
 *---------------------------------------------------------------
*/
.noscript {
  color: #FFF;
  background-color: #D9534F;
  border-color: #D43F3A;
  border-width: 0 0 1px 0;
  position: fixed;
  top: 45px;
  z-index: 10000;
  width: 97.4%;
  margin-left: 50px;
}
.noscript .notice-inner {
  margin: 0 auto;
  padding: 10px 15px;
  text-align: center;
}
/**
 *---------------------------------------------------------------
 * IE WARNING
 *---------------------------------------------------------------
*/
.ie-message {
  color: #FFF;
  background-color: #D9534F;
  border-color: #D43F3A;
  border-width: 0 0 1px 0;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  width: 97.4%;
  margin-left: 50px;
}
.ie-message .notice-inner {
  margin: 0 auto;
  padding: 10px 15px;
  text-align: center;
}
.ie-message .notice-inner a {
  color: #FFF;
  text-decoration: underline;
}
.ie-message .notice-inner a:hover {
  text-decoration: none;
}
/**
 *---------------------------------------------------------------
 * USEFULL TOOLS
 *---------------------------------------------------------------
*/
/** Clearfixes */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
/** Margins */
.no-margin {
  margin: 0 !important;
}
.no-lft-margin {
  margin-left: 0 !important;
}
.no-rgt-margin {
  margin-right: 0 !important;
}
/** Padding */
.no-padding {
  padding: 0 !important;
}
.no-lft-padding {
  padding-left: 0 !important;
}
.no-rgt-padding {
  padding-right: 0 !important;
}
/**
 *---------------------------------------------------------------
 * MEDIA QUERY FIXES
 *---------------------------------------------------------------
*/
/*  480px breakpoint 30em  */
@media only screen and (max-width: 480px) {
  #login-box {
    width: 300px;
  }
}
/*  600px breakpoint 38em  */
/*  768px breakpoint 48em  */
/*  992px breakpoint 62em  */
/*  1382px breakpoint 86em  */
/*  high pixel ratio Retina Display = Load High Res Images  */
/* Style adjustments for high resolution devices */
/*
 * -----------------------
 * Input Group Select Fix
 * -----------------------
 */
.input-group .bootstrap-select.form-control {
  z-index: inherit;
}
/**
 *---------------------------------------------------------------
 * HTML STYLES
 *---------------------------------------------------------------
*/
body {
  background: #ebf1f5;
  overflow: visible;
}
body .fixed-navbar {
  padding-top: 70px;
}
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #E0DFDF;
  border-bottom: 1px solid #FEFEFE;
}
button.square {
  border-radius: 0;
  padding: 4px 10px;
}
/**
 *---------------------------------------------------------------
 * NAVBAR
 *---------------------------------------------------------------
*/
header .navbar {
  border-radius: 0;
  margin-bottom: 0;
}
.logout-button {
  position: relative;
}
.logout-button span {
  display: block;
  background-color: #fff;
  position: absolute;
  width: 30px;
  height: 5px;
  bottom: 22px;
  left: 50%;
  margin-left: -15px;
}
.logout-button:hover span {
  background-color: #eeeeee !important;
}
/* Header Nav */
.header-nav {
  min-height: 0 !important;
  height: 69px;
  background: #FFF;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #eeeeee;
  top: 0;
  /* Navbar */
  /* Header Nav Logo */
  /* Header Nav Text */
  /* Sidebar Toggle */
  /* Menu */
  /* Header Nav Dropdown */
  /* Header Nav Open */
  /* Toolbar */
  /* Notification */
  /* Search bar*/
}
.header-nav .navbar-collapse {
  border-color: #eeeeee;
}
.header-nav .navbar-brand {
  height: auto;
}
.header-nav .navbar-brand.header-logo {
  margin: 0;
  padding: 5px 0 0 10px;
}
.header-nav .navbar-brand.header-text {
  margin: 0;
  padding: 10px 10px 11px 10px;
  font-size: 20px;
  line-height: 1.2;
  color: #EEE;
}
.header-nav .navbar-brand.header-text .glyphicons {
  line-height: 1;
  vertical-align: text-top;
}
.header-nav .navbar-nav {
  border-left: 1px solid #ededed;
}
.header-nav .navbar-nav .open a:hover,
.header-nav .navbar-nav .open a:focus,
.header-nav .navbar-nav .open a {
  color: inherit !important;
  border: inherit !important;
}
.header-nav .search-form {
  padding: 9px 0;
}
.header-nav .search-form input[type=text] {
  border-radius: 0;
  background-color: #EEEEEE;
  border: 0;
  font-style: italic;
  box-shadow: none;
}
.header-nav .search-form button {
  background-color: #EEEEEE;
  border: 0;
  box-shadow: 0;
}
.header-nav .search-form button i {
  font-size: 24px;
  padding: 0 5px;
}
.header-nav .sidebar-toggle {
  float: left;
  padding: 25px 29px 25px;
  background-color: #FFFFFF;
  border: 1px solid;
  border-color: #e6e6e6;
  position: relative;
}
.header-nav .sidebar-toggle .triangle-icon {
  position: absolute;
  left: 15px;
  bottom: 50%;
  margin-bottom: -4px;
}
.header-nav .sidebar-toggle .left-pointing {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.5px 5px 4.5px 0;
  border-color: transparent #991717 transparent transparent;
}
.header-nav .sidebar-toggle .right-pointing {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.5px 0 4.5px 5px;
  border-color: transparent transparent transparent #991717;
}
.header-nav .sidebar-toggle:hover,
.header-nav .sidebar-toggle:active,
.header-nav .sidebar-toggle:focus {
  outline: none;
  background-color: #FFFFFF;
  border-color: #e6e6e6;
  transition: background-color 0.5s linear;
}
.header-nav .sidebar-toggle .icon-bar {
  display: block;
  width: 20px;
  height: 3px;
  border-radius: 1px;
  background-color: #333645;
}
.header-nav .sidebar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}
.header-nav .navbar-nav {
  margin: 0;
}
.header-nav .navbar-nav > li > a,
.header-nav .navbar-nav > li > a:active,
.header-nav .navbar-nav > li > a:visited {
  color: #333645;
  padding: 23px 26px 20px;
  border-radius: 0;
}
.header-nav .navbar-nav > li > a > i {
  font-size: 26px;
}
.header-nav .navbar-nav > li > a:hover {
  color: #333645;
  background-color: #eeeeee;
}
.header-nav .navbar-nav > .active > a,
.header-nav .navbar-nav > .active > a:hover {
  color: #333645;
  background-color: #eeeeee;
}
.header-nav .navbar-nav .dropdown-menu {
  margin: 0;
  padding: 0;
}
.header-nav .navbar-nav .dropdown-menu > li > a:hover,
.header-nav .navbar-nav .dropdown-menu > li > a:focus,
.header-nav .navbar-nav .dropdown-menu > .active > a,
.header-nav .navbar-nav .dropdown-menu > .active > a:hover,
.header-nav .navbar-nav .dropdown-menu > .active > a:focus {
  background-color: #FFF;
  background-image: none;
}
.header-nav .navbar-nav .open .divider {
  background-color: #ffffff;
}
.header-nav .navbar-nav .open .dropdown-header {
  color: #999;
}
.header-nav .navbar-nav > .open > a,
.header-nav .navbar-nav > .open > a:hover,
.header-nav .navbar-nav > .open > a:focus {
  color: #FFFFFF;
  background-color: #ffffff;
}
.header-nav .navbar-nav .open .dropdown-menu {
  background: #f5f5f5;
}
.header-nav .navbar-nav .open .dropdown-menu > li > a {
  color: #333645;
  display: block;
  padding: 10px 15px;
}
.header-nav .navbar-nav .open .dropdown-menu > li > a:hover {
  color: #333645;
  background: #ffffff;
}
.header-nav .toolbar {
  float: left;
}
.header-nav .toolbar .glyphicons {
  vertical-align: middle;
}
.header-nav .toolbar a {
  display: inline-block;
  background: none;
  border: none;
  color: #333645;
}
.header-nav .toolbar a:hover {
  background: #eeeeee;
  color: #333645;
}
.header-nav .toolbar ul.extended {
  min-width: 300px;
}
.header-nav .toolbar-header {
  padding: 10px;
  background-color: #333645;
  color: #FFFFFF;
  margin: 0;
}
.header-nav .toolbar-item {
  overflow: hidden;
}
.header-nav .toolbar-footer {
  margin: 0;
  padding: 0;
}
.header-nav .toolbar-footer a {
  background-color: #ededed;
}
.header-nav .toolbar .notification_icon .unread {
  color: #D64541;
}
.header-nav .toolbar .notification_list a.unread {
  color: #D64541 !important;
}
.header-nav .toolbar form.search-form {
  padding: 5px;
}
/* Multi Level Dropdown */
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu .pull-left {
  float: none;
}
.dropdown-submenu .pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-menu-right .dropdown-submenu > .dropdown-menu {
  left: -100%;
}
.dropdown-menu-right .dropdown-submenu > a:after {
  float: left;
  border-width: 5px 5px 5px 0;
  border-right-color: #ccc;
}
.dropdown-menu-right .dropdown-submenu:hover > a :after {
  border-right-color: #fff;
}
/**
 *---------------------------------------------------------------
 * BREADCRUMBS
 *---------------------------------------------------------------
*/
.breadcrumbs {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  height: 0;
}
.breadcrumbs .breadcrumb {
  margin: 0;
  list-style: none;
  background: #71737d;
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #DDDDDD;
  color: #FFFFFF;
}
.breadcrumbs .breadcrumb a {
  color: #FFFFFF;
}
/**
 * 992px breakpoint 62em
 */
@media only screen and (min-width: 992px) {
  .breadcrumbs {
    display: block;
    height: auto;
    top: 69px;
  }
  .breadcrumbs .breadcrumb {
    padding: 8px 15px;
  }
}
.spacer {
  display: block;
  clear: both;
  margin-bottom: 20px;
}
/**
 * Sidebar and Activity bar styles
 */
#sidebar,
#activity-bar {
  margin: 0;
  padding: 0;
  z-index: 1000;
  position: fixed;
}
#sidebar #sidebar-inner,
#activity-bar #sidebar-inner,
#sidebar #activity-bar-inner,
#activity-bar #activity-bar-inner {
  min-height: 940px;
  width: 300px;
}
#sidebar #sidebar-inner .menu-wrapper,
#activity-bar #sidebar-inner .menu-wrapper,
#sidebar #activity-bar-inner .menu-wrapper,
#activity-bar #activity-bar-inner .menu-wrapper {
  overflow: auto;
}
/*
 * Sidebar
 */
#sidebar {
  top: 0;
  left: -300px;
}
#sidebar #sidebar-inner {
  background: #333645;
  /* Sidebar Account */
  /* Sidebar Nav List */
  /* Sidebar Nav List Sub Nav */
}
#sidebar #sidebar-inner .account {
  display: block;
  position: relative;
  border-right: 1px solid #6d1010;
  /* Sidebar Account Detail */
}
#sidebar #sidebar-inner .account .account-detail {
  cursor: pointer;
  background: #7a1212;
}
#sidebar #sidebar-inner .account .account-detail.active {
  background-color: #7a1212;
}
#sidebar #sidebar-inner .account .account-image {
  float: left;
  overflow: hidden;
  border-radius: 50% !important;
  margin: 15px 10px 14px 20px;
}
#sidebar #sidebar-inner .account .account-image img {
  width: 40px;
  height: 40px;
}
#sidebar #sidebar-inner .account .account-name {
  float: left;
  color: #EEEEEE;
  font-size: 14px;
  line-height: 20px;
  padding: 14px 10px;
}
#sidebar #sidebar-inner .account .account-name .user-role {
  font-size: 11px;
  color: #BBBBBB;
}
#sidebar #sidebar-inner .account .text {
  margin-left: 8px;
  display: inline !important;
}
#sidebar #sidebar-inner .nav-list > li {
  border-bottom: 1px solid #22242e;
}
#sidebar #sidebar-inner .nav-list > li a {
  margin: 0;
  color: #adaeb2;
  padding: 15px 15px;
  line-height: 18px;
}
#sidebar #sidebar-inner .nav-list > li a i {
  font-size: 18px;
  margin-right: 5px;
  margin-top: 1px;
  display: block;
  float: left;
}
#sidebar #sidebar-inner .nav-list > li > a:hover,
#sidebar #sidebar-inner .nav-list > li > a:focus {
  border-left: 5px solid #991717;
  background-color: #242630;
  color: #FFF;
}
#sidebar #sidebar-inner .nav-list > li > a:hover .glyphicons,
#sidebar #sidebar-inner .nav-list > li > a:hover .text {
  color: #FFF;
}
#sidebar #sidebar-inner .nav-list > li.active > a,
#sidebar #sidebar-inner .nav-list > li.active > a:hover {
  border-left: 5px solid #991717;
  background-color: #242630;
  color: #FFF;
}
#sidebar #sidebar-inner .nav-list > li.active .indicator {
  top: 15px;
  right: 0;
  position: absolute;
  border-top: 10px solid transparent;
  border-right: 10px solid #EBF1F5;
  border-bottom: 10px solid transparent;
}
#sidebar #sidebar-inner .glyphicons {
  color: #adaeb2;
  font-size: 16px;
  margin-right: 7px;
}
#sidebar #sidebar-inner .text {
  color: #adaeb2;
  padding-bottom: 3px;
}
#sidebar #sidebar-inner .nav-list .sub-nav {
  display: none;
  background-color: #3c3f51;
}
#sidebar #sidebar-inner .nav-list > li.open {
  position: relative;
}
#sidebar #sidebar-inner .nav-list > li.open > ul {
  display: block;
}
#sidebar #sidebar-inner .nav-list > li.open > ul:before {
  border-left: 1px dashed rgba(255, 255, 255, 0.3);
  bottom: 0;
  content: "";
  display: block;
  left: 23px;
  position: absolute;
  top: 50px;
  z-index: 1;
}
#sidebar #sidebar-inner .nav-list > li.open > ul > li {
  position: relative;
}
#sidebar #sidebar-inner .nav-list > li.open > ul > li:before {
  content: "";
  display: block;
  left: 23px;
  position: absolute;
  top: 19px;
  width: 8px;
  z-index: 1;
}
#sidebar #sidebar-inner .nav-list > li.open > ul > li a {
  display: block;
  padding: 10px 0 10px 40px;
  color: #EEEEEE;
}
#sidebar #sidebar-inner .nav-list > li.open > ul > li a:before {
  content: "";
  left: 25px;
  top: 19px;
  position: absolute;
  display: block;
  width: 12px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
  z-index: 1;
}
#sidebar #sidebar-inner .nav-list > li.open > ul > li a:hover,
#sidebar #sidebar-inner .nav-list > li.open > ul > li.active a {
  background-color: #242630;
  border-left: 5px solid #991717;
  text-decoration: none;
}
#sidebar #sidebar-inner .nav-list .group > a:before {
  content: "+";
  position: absolute;
  top: 15px;
  right: 25px;
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: inherit;
  transform: rotate(0deg);
  transition: all 0.15s linear;
}
#sidebar #sidebar-inner .nav-list .group.open {
  transition: all 1s ease;
}
#sidebar #sidebar-inner .nav-list .group.open > a {
  background-color: #555555;
  border-bottom: 1px;
  color: #FFFFFF;
}
#sidebar #sidebar-inner .nav-list .group.open > a:before {
  transform: rotate(-45deg);
}
/**
 * Activity Bar
 */
#activity-bar {
  top: 0;
  right: -300px;
  border-left: 1px solid #dee5e7;
}
#activity-bar #activity-bar-inner {
  background: #FFF;
}
/**
 * Sidebar open / Activity bar open
 */
body.sidebar-open .navbar-fixed-top,
body.activity-bar-open .navbar-fixed-top,
body.sidebar-open .breadcrumbs,
body.activity-bar-open .breadcrumbs,
body.sidebar-open #app-container,
body.activity-bar-open #app-container,
body.sidebar-open #sidebar,
body.activity-bar-open #sidebar,
body.sidebar-open #activity-bar,
body.activity-bar-open #activity-bar {
  transition-duration: 0.3s;
}
/**
 * Sidebar open
 */
body.sidebar-open #sidebar {
  left: 0;
}
/**
 * Activity bar open
 */
body.activity-bar-open #activity-bar {
  right: 0;
}
/**
 * 768px breakpoint 48em
 */
@media only screen and (min-width: 768px) {
  /**
     * Sidebar open
     */
  body.sidebar-open .navbar-fixed-top,
  body.sidebar-open .breadcrumbs,
  body.sidebar-open #page-head .sticky-actions {
    left: 300px;
    right: 0;
  }
  body.sidebar-open #app-container {
    margin-left: 300px;
  }
  /**
     * Activity bar open
     */
  body.activity-bar-open #app-container {
    margin-right: 300px;
  }
}
/**
 * Sidebar & Activity bar open
 */
/**
 * Sidebar closed / Activity bar closed
 */
body.sidebar-closed .navbar-fixed-top,
body.activity-bar-closed .navbar-fixed-top,
body.sidebar-closed .breadcrumbs,
body.activity-bar-closed .breadcrumbs,
body.sidebar-closed #app-container,
body.activity-bar-closed #app-container,
body.sidebar-closed #sidebar,
body.activity-bar-closed #sidebar,
body.sidebar-closed #activity-bar,
body.activity-bar-closed #activity-bar,
body.sidebar-closed #page-head .sticky-actions,
body.activity-bar-closed #page-head .sticky-actions {
  transition-duration: 0.2s;
}
/**
 * --------------------------------------------------------------
 * CONTENT HEAD
 * --------------------------------------------------------------
 */
#page-head {
  background-color: #FFFFFF;
  margin-bottom: 40px;
  padding-top: 20px;
  border-bottom: 1px solid #f2f2f2;
  min-height: 140px;
  /* Do not change or sticky actions will break */
}
#page-head.row {
  margin-left: -40px;
  margin-right: -30px;
}
#page-head h1 {
  margin: 0;
  line-height: 1.5;
  padding-bottom: 10px;
  word-break: break-all;
  word-wrap: break-word;
  max-height: 40px;
  overflow: hidden;
}
#page-head .navbar-toggle {
  display: none;
}
#page-head .flag {
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 0;
  display: inline-block;
}
#page-head .default-subtext {
  margin-bottom: 20px;
  display: block;
}
#page-head .navbar-nav {
  margin: 20px 0 0 0;
}
#page-head .navbar-nav li {
  float: left;
  margin-right: 20px;
}
#page-head .navbar-nav li a {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: bold;
  line-height: 26px;
  padding: 0;
}
#page-head .navbar-nav li a:hover,
#page-head .navbar-nav li a:active,
#page-head .navbar-nav li a:focus {
  background-color: #fff;
}
#page-head .navbar-nav li.active {
  border-bottom: 3px solid #991717;
  margin-bottom: -3px;
}
#page-head .edited-icon {
  margin-left: 50px;
}
#page-head .timestamps .created {
  margin-right: 10px;
}
#page-head .timestamps .updated {
  margin-right: 10px;
}
#page-head .sticky-actions.affix {
  position: fixed;
  top: 69px;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #FFF;
  margin: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #F2F2F2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
#page-head .sticky-actions.affix .timestamps,
#page-head .sticky-actions.affix .default-subtext,
#page-head .sticky-actions.affix .flag,
#page-head .sticky-actions.affix .account-number {
  display: none;
}
#page-head .sticky-actions.affix h1,
#page-head .sticky-actions.affix h2,
#page-head .sticky-actions.affix h3,
#page-head .sticky-actions.affix h4,
#page-head .sticky-actions.affix .action-buttons {
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
/**
 * 768px breakpoint 48em
 */
@media only screen and (min-width: 768px) {
  #page-head .navbar-nav li {
    margin-right: 40px;
  }
  #page-head .navbar-nav li a {
    line-height: 36px;
  }
}
/**
 * 992px breakpoint 62em
 */
@media only screen and (min-width: 992px) {
  #page-head.affix {
    top: 105px;
  }
  #page-head .navbar-nav li {
    margin-top: 3px;
  }
  #page-head .navbar-nav li.active {
    border-bottom: 3px solid #991717;
    margin-bottom: 0;
  }
}
/**
 *---------------------------------------------------------------
 * CONTENT STYLES
 *---------------------------------------------------------------
*/
#middle {
  padding-top: 69px;
  padding-bottom: 30px;
}
#middle > .container {
  padding-top: 15px;
}
#middle h1 {
  font-style: italic;
  font-size: 24px;
}
.timestamps i {
  margin-top: 1px;
  display: inline-block;
}
/**
 * 992px breakpoint 62em
 */
@media only screen and (min-width: 992px) {
  #middle {
    padding-top: 105px;
  }
}
/**
 *---------------------------------------------------------------
 * ALERT STYLES
 *---------------------------------------------------------------
*/
#message-area {
  position: absolute;
  bottom: 40px;
  right: 40px;
  width: 500px;
  z-index: 10000;
}
#message-area .alert {
  width: 100%;
}
/**
 *---------------------------------------------------------------
 * EDITABLE STYLES
 *---------------------------------------------------------------
*/
.editable {
  border-bottom: 1px dashed #DDDDDD;
}
.editable.active {
  background: #F5D76E;
  padding: 2px;
}
/**
 *---------------------------------------------------------------
 * USER STYLES
 *---------------------------------------------------------------
*/
#contact-details {
  margin-bottom: 20px;
}
/**
 *---------------------------------------------------------------
 * SEARCH STYLES
 *---------------------------------------------------------------
*/
.search-results {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
/**
 *---------------------------------------------------------------
 * MODAL STYLES
 *---------------------------------------------------------------
*/
/**
 * Modal open
 */
body.modal-open {
  margin-right: 0;
}
body.modal-open .navbar-fixed-top,
body.modal-open .navbar-fixed-bottom {
  margin-right: 0;
}
/**
 * Modal backdrop style
 */
.modal-backdrop {
  background-color: #000;
}
/**
 * Modal styles
 */
.modal {
  overflow: hidden;
}
.modal .modal-dialog {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  border-radius: 2px;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  /**
         * Not scrollable
         */
}
.modal .modal-dialog .modal-header {
  color: #555;
  padding: 15px 20px;
  background: #FFF;
  border-bottom: 1px solid #EDF1F2;
}
.modal .modal-dialog .modal-header .modal-title,
.modal .modal-dialog .modal-header .close {
  color: #555;
}
.modal .modal-dialog .modal-header .modal-title:hover,
.modal .modal-dialog .modal-header .close:hover,
.modal .modal-dialog .modal-header .modal-titlefocus,
.modal .modal-dialog .modal-header .closefocus {
  color: #333;
}
.modal .modal-dialog .modal-body {
  padding: 10px 20px;
  margin: 10px 0;
}
.modal .modal-dialog .modal-footer {
  padding: 10px 20px;
  background: #FFF;
}
.modal .modal-dialog .modal-body.no-scroller {
  overflow: scroll !important;
}
.modal .modal-dialog .modal-body.no-scroller .modal-footer {
  border-top: none;
}
/**
 * Prompt styles
 */
.prompt {
  text-align: center;
}
.prompt .prompt-icon {
  text-align: center;
  display: block;
  width: 90px;
  height: 90px;
  border-radius: 50%!important;
  font-size: 55px;
  filter: alpha(opacity=50);
  opacity: .5;
  color: #666;
  margin: 0 auto 20px auto;
  border: 3px solid #666;
}
.prompt .prompt-icon span,
.prompt .prompt-icon i {
  line-height: inherit;
}
.prompt .prompt-title {
  color: #666;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 5px;
  color: #333;
}
.prompt .prompt-message {
  color: #666;
  font-size: 1.1em;
}
.prompt .modal-footer {
  margin-top: 0;
  border-top: none;
}
.prompt .modal-footer .primary-action {
  display: block;
  padding: 12px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  width: 55%;
  margin: 0 auto 20px auto;
}
.prompt .modal-footer .secondary-action {
  display: none;
}
/**
 * 480px breakpoint 30em
 */
@media only screen and (max-width: 599px) {
  .modal-dialog {
    top: 1%;
  }
  .modal-dialog .modal-body {
    max-height: 200px;
  }
}
/**
 * 600px breakpoint 38em
 */
@media only screen and (min-width: 600px) {
  .modal-dialog {
    top: 1%;
  }
  .modal-dialog .modal-body {
    max-height: 300px;
  }
}
/**
 * 768px breakpoint 48em
 */
@media only screen and (min-width: 768px) {
  .modal-dialog {
    top: 5%;
  }
  .modal-dialog .modal-body {
    max-height: 400px;
  }
}
/**
 * 992px breakpoint 62em
 */
@media only screen and (min-width: 992px) {
  .modal-dialog {
    top: 8%;
  }
  .modal-dialog .modal-body {
    max-height: 500px;
  }
}
/**
 * 1382px breakpoint 86em
 */
@media only screen and (min-width: 1382px) {
  .modal-dialog {
    top: 9%;
  }
  .modal-dialog .modal-body {
    max-height: 600px;
  }
}
/**
 *---------------------------------------------------------------
 * BLOG STYLES
 *---------------------------------------------------------------
*/
section#article h2 {
  margin-top: 0;
}
section#article p.article-date {
  color: #a2a2a2;
  font-size: 12px;
}
section#article p.article-date i {
  margin-right: 20px;
}
section#article p {
  line-height: 24px;
  font-size: 13px;
}
/**
 *---------------------------------------------------------------
 * ANGULAR -- Animate If statements
 *---------------------------------------------------------------
*/
.animate-container {
  position: relative;
}
.animate-if.ng-enter,
.animate-if.ng-leave {
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.animate-if.ng-enter {
  z-index: 100;
  opacity: 0;
}
.animate-if.ng-enter.ng-enter-active {
  -webkit-transition: 0.2s linear all 0.2s;
  opacity: 1;
}
.animate-if.ng-leave {
  z-index: 101;
  opacity: 1;
}
.animate-if.ng-leave.ng-leave-active {
  opacity: 0;
}
/**
 *---------------------------------------------------------------
 * CMS PARTIAL STYLES
 *---------------------------------------------------------------
*/
.partial-header.panel-heading {
  background-color: #FFFFFF;
}
.panel-heading {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  background-color: #FFFFFF !important;
}
#redactor_image_box_select {
  display: none !important;
}
.partial-2-col-text-and-images .dropzone,
.partial-2-col-text-and-images .dropzone-block {
  min-height: 443px;
}
/**
 *---------------------------------------------------------------
 * TABLE HELPERS
 *---------------------------------------------------------------
*/
.table .has-actions {
  line-height: 28px;
}
/**
 *---------------------------------------------------------------
 * RESPONSIVE HELPERS
 *---------------------------------------------------------------
*/
@media (max-width: 767px) {
  .form-group-xs {
    margin-bottom: 20px;
  }
  .form-group-not-xs {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .right-sm {
    float: right;
  }
  .form-group-sm {
    margin-bottom: 15px;
  }
  .form-group-not-sm {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .right-md {
    float: right;
  }
  .form-group-md {
    margin-bottom: 15px;
  }
  .form-group-not-md {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .right-lg {
    float: right;
  }
  .form-group-lg {
    margin-bottom: 15px;
  }
  .form-group-not-lg {
    margin-bottom: 0;
  }
}
/**
 *---------------------------------------------------------------
 * SMALL MOBILE - Max Width: 480px breakpoint 30em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * MOBILE - Min Width: 600px breakpoint 38em
 *---------------------------------------------------------------
*/
@media only screen and (min-width: 600px) {
  .header-nav .navbar-brand.header-text {
    width: 185px;
  }
}
/**
 *---------------------------------------------------------------
 * OFFCANVAS - Max Width: 768px breakpoint 48em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * TABLET - Min Width: 768px breakpoint 48em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * SMALL DESKTOP- Min Width: 992px breakpoint 62em
 *---------------------------------------------------------------
*/
@media only screen and (min-width: 992px) {
  .header-nav .toolbar ul.extended {
    min-width: 350px;
  }
}
/**
 *---------------------------------------------------------------
 * LARGE DESKTOP- Min Width: 1382px breakpoint 86em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * LARGE DESKTOP- Retina Display
 *---------------------------------------------------------------
*/
/**
 * ---------------------------------------------------------------
 * NETMATTERS FILES
 * ---------------------------------------------------------------
 * If there are any elements that you do not require
 * for your project comment them out bellow
 */
.arrow {
  border-width: 9px;
  z-index: 10;
}
.arrow,
.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.arrow:after {
  border-width: 8px;
  content: "";
}
.arrow.top {
  left: 50%;
  margin-left: -9px;
  border-top-width: 0;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  top: -9px;
}
.arrow.top:after {
  top: 1px;
  margin-left: -8px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.arrow.top.arrow-primary:after {
  border-bottom-color: #991717;
}
.arrow.top.arrow-info:after {
  border-bottom-color: #5bc0de;
}
.arrow.top.arrow-success:after {
  border-bottom-color: #3FC380;
}
.arrow.top.arrow-danger:after {
  border-bottom-color: #d24d57;
}
.arrow.top.arrow-warning:after {
  border-bottom-color: #f0ad4e;
}
.arrow.top.arrow-light:after {
  border-bottom-color: #e74c3c;
}
.arrow.top.arrow-dark:after {
  border-bottom-color: #3a3f51;
}
.arrow.top.arrow-black:after {
  border-bottom-color: #1c2b36;
}
.arrow.right {
  top: 50%;
  right: -9px;
  margin-top: -9px;
  border-right-width: 0;
  border-left-color: rgba(0, 0, 0, 0.1);
}
.arrow.right:after {
  right: 1px;
  bottom: -8px;
  border-right-width: 0;
  border-left-color: #fff;
}
.arrow.right.arrow-primary:after {
  border-left-color: #991717;
}
.arrow.right.arrow-info:after {
  border-left-color: #5bc0de;
}
.arrow.right.arrow-success:after {
  border-left-color: #3FC380;
}
.arrow.right.arrow-danger:after {
  border-left-color: #d24d57;
}
.arrow.right.arrow-warning:after {
  border-left-color: #f0ad4e;
}
.arrow.right.arrow-light:after {
  border-left-color: #e74c3c;
}
.arrow.right.arrow-dark:after {
  border-left-color: #3a3f51;
}
.arrow.right.arrow-black:after {
  border-left-color: #1c2b36;
}
.arrow.bottom {
  left: 50%;
  bottom: -9px;
  margin-left: -9px;
  border-bottom-width: 0;
  border-top-color: rgba(0, 0, 0, 0.1);
}
.arrow.bottom:after {
  bottom: 1px;
  margin-left: -8px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.arrow.bottom.arrow-primary:after {
  border-top-color: #991717;
}
.arrow.bottom.arrow-info:after {
  border-top-color: #5bc0de;
}
.arrow.bottom.arrow-success:after {
  border-top-color: #3FC380;
}
.arrow.bottom.arrow-danger:after {
  border-top-color: #d24d57;
}
.arrow.bottom.arrow-warning:after {
  border-top-color: #f0ad4e;
}
.arrow.bottom.arrow-light:after {
  border-top-color: #e74c3c;
}
.arrow.bottom.arrow-dark:after {
  border-top-color: #3a3f51;
}
.arrow.bottom.arrow-black:after {
  border-top-color: #1c2b36;
}
.arrow.left {
  top: 50%;
  left: -9px;
  margin-top: -9px;
  border-left-width: 0;
  border-right-color: rgba(0, 0, 0, 0.1);
}
.arrow.left:after {
  left: 1px;
  bottom: -8px;
  border-left-width: 0;
  border-right-color: #fff;
}
.arrow.left.arrow-primary:after {
  border-right-color: #991717;
}
.arrow.left.arrow-info:after {
  border-right-color: #5bc0de;
}
.arrow.left.arrow-success:after {
  border-right-color: #3FC380;
}
.arrow.left.arrow-danger:after {
  border-right-color: #d24d57;
}
.arrow.left.arrow-warning:after {
  border-right-color: #f0ad4e;
}
.arrow.left.arrow-light:after {
  border-right-color: #e74c3c;
}
.arrow.left.arrow-dark:after {
  border-right-color: #3a3f51;
}
.arrow.left.arrow-black:after {
  border-right-color: #1c2b36;
}
.arrow.pull-left {
  left: 19px;
}
.arrow.pull-right {
  left: auto;
  right: 19px;
}
.arrow.pull-up {
  top: 19px;
}
.arrow.pull-down {
  top: auto;
  bottom: 19px;
}
/**
 *---------------------------------------------------------------
 * BUTTONS
 *---------------------------------------------------------------
*/
.btn {
  font-weight: 500;
  border-radius: 2px;
  outline: 0 !important;
  border: 0;
}
.btn :not dropdown-toggle {
  padding: 8px 12px;
}
.btn i {
  font-size: 16px;
}
.btn-link {
  color: #444444;
}
.btn-link.active {
  webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default {
  color: #444444;
  color: #444444 !important;
  border-color: #f2f2f2;
  background-color: #acbcc6;
  color: #ffffff !important;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #444444;
  background-color: #94a8b5;
  border-color: #d4d4d4;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #acbcc6;
  border-color: #f2f2f2;
}
.btn-default .badge {
  color: #acbcc6;
  background-color: #444444;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #444444 !important;
  background-color: #9db0bc;
  border-color: #dedede;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #acbcc6;
  border-color: #f2f2f2;
}
.btn-default.btn-bg {
  border-color: rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
}
.btn-default:hover {
  color: #ffffff !important;
}
table.dataTable .btn,
.actions .btn {
  border-radius: 0;
  border-bottom: 0;
  margin-left: 5px;
  padding: 6px 10px;
}
.btn-primary {
  color: #fff;
  color: #fff !important;
  background-color: #991717;
  border-color: #991717;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #761212;
  border-color: #640f0f;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #991717;
  border-color: #991717;
}
.btn-primary .badge {
  color: #991717;
  background-color: #fff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff !important;
  background-color: #831414;
  border-color: #761212;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #991717;
  border-color: #991717;
}
.btn-success {
  color: #fff;
  color: #fff !important;
  background-color: #3FC380;
  border-color: #3FC380;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #34a56c;
  border-color: #2f9662;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #3FC380;
  border-color: #3FC380;
}
.btn-success .badge {
  color: #3FC380;
  background-color: #fff;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #fff !important;
  background-color: #37b173;
  border-color: #34a56c;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #3FC380;
  border-color: #3FC380;
}
.btn-info {
  color: #fff;
  color: #fff !important;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #39b3d7;
  border-color: #2aabd2;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #fff !important;
  background-color: #46b8da;
  border-color: #39b3d7;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-warning {
  color: #fff;
  color: #fff !important;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ed9c28;
  border-color: #eb9316;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #fff !important;
  background-color: #eea236;
  border-color: #ed9c28;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-danger {
  color: #fff;
  color: #fff !important;
  background-color: #d24d57;
  border-color: #d24d57;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c5323d;
  border-color: #b42e38;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d24d57;
  border-color: #d24d57;
}
.btn-danger .badge {
  color: #d24d57;
  background-color: #fff;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #fff !important;
  background-color: #cd3944;
  border-color: #c5323d;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d24d57;
  border-color: #d24d57;
}
.btn-dark {
  color: #fff;
  color: #fff !important;
  background-color: #3a3f51;
  border-color: #3a3f51;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  color: #fff;
  background-color: #292d39;
  border-color: #20232d;
}
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  background-image: none;
}
.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled] .btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled] .btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark.active {
  background-color: #3a3f51;
  border-color: #3a3f51;
}
.btn-dark .badge {
  color: #3a3f51;
  background-color: #fff;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  color: #fff !important;
  background-color: #2f3342;
  border-color: #292d39;
}
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  background-image: none;
}
.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled] .btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled] .btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark.active {
  background-color: #3a3f51;
  border-color: #3a3f51;
}
.btn-black {
  color: #fff;
  color: #fff !important;
  background-color: #1c2b36;
  border-color: #1c2b36;
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
  color: #fff;
  background-color: #0e161b;
  border-color: #070b0e;
}
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
  background-image: none;
}
.btn-black.disabled,
.btn-black[disabled],
fieldset[disabled] .btn-black,
.btn-black.disabled:hover,
.btn-black[disabled]:hover,
fieldset[disabled] .btn-black:hover,
.btn-black.disabled:focus,
.btn-black[disabled]:focus,
fieldset[disabled] .btn-black:focus,
.btn-black.disabled:active,
.btn-black[disabled]:active,
fieldset[disabled] .btn-black:active,
.btn-black.disabled.active,
.btn-black[disabled].active,
fieldset[disabled] .btn-black.active {
  background-color: #1c2b36;
  border-color: #1c2b36;
}
.btn-black .badge {
  color: #1c2b36;
  background-color: #fff;
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
  color: #fff !important;
  background-color: #131e25;
  border-color: #0e161b;
}
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
  background-image: none;
}
.btn-black.disabled,
.btn-black[disabled],
fieldset[disabled] .btn-black,
.btn-black.disabled:hover,
.btn-black[disabled]:hover,
fieldset[disabled] .btn-black:hover,
.btn-black.disabled:focus,
.btn-black[disabled]:focus,
fieldset[disabled] .btn-black:focus,
.btn-black.disabled:active,
.btn-black[disabled]:active,
fieldset[disabled] .btn-black:active,
.btn-black.disabled.active,
.btn-black[disabled].active,
fieldset[disabled] .btn-black.active {
  background-color: #1c2b36;
  border-color: #1c2b36;
}
.btn-icon {
  padding: 0 !important;
  text-align: center;
  width: 34px;
  height: 34px;
}
.btn-icon i {
  top: -1px;
  position: relative;
  line-height: 34px;
}
.btn-icon.btn-sm {
  width: 30px;
  height: 30px;
}
.btn-icon.btn-sm i {
  line-height: 30px;
}
.btn-icon.btn-lg {
  width: 45px;
  height: 45px;
}
.btn-icon.btn-lg i {
  line-height: 45px;
}
.btn-rounded {
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-rounded.btn-lg {
  padding-left: 25px;
  padding-right: 25px;
}
.btn > i.pull-left,
.btn > i.pull-right {
  line-height: 1.42857143;
}
.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 2px;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 2px;
}
.btn-addon i {
  margin: -7px -12px;
  margin-right: 12px;
  background-color: rgba(0, 0, 0, 0.07);
  text-align: center;
  float: left;
  position: relative;
  border-radius: 2px 0 0 2px;
  width: 35px;
  line-height: 39px;
}
.btn-addon i.pull-right {
  margin-right: -12px;
  margin-left: 12px;
  border-radius: 0 2px 2px 0;
}
.btn-addon .text {
  font-size: 14px;
  line-height: 25px;
}
.btn-addon.btn-sm i {
  margin: -6px -10px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.btn-addon.btn-sm i.pull-right {
  margin-right: -10px;
  margin-left: 10px;
}
.btn-addon.btn-lg i {
  margin: -11px -16px;
  margin-right: 16px;
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.btn-addon.btn-lg i.pull-right {
  margin-right: -16px;
  margin-left: 16px;
}
.btn-addon.btn-default i {
  background-color: transparent;
  border-right: 1px solid #f2f2f2;
}
/**
 *---------------------------------------------------------------
 * CUSTOM BUTTONS
 *---------------------------------------------------------------
*/
.btn i,
.btn span {
  font-size: 18px;
}
.btn-sm i,
.btn-sm span {
  font-size: 11px;
}
/* Blue */
.btn.blue {
  color: #FFFFFF;
  text-shadow: none;
  background-color: #34495E;
  border-color: #2b3c4e;
}
.btn.blue:hover,
.btn.blue:focus,
.btn.blue:active,
.btn.blue.active,
.btn.blue[disabled],
.btn.blue.disabled {
  outline: none !important;
  background-color: #222f3d !important;
}
/* Red */
.btn.red {
  color: #FFFFFF;
  text-shadow: none;
  background-color: #D64541;
  border-color: #d1312d;
}
.btn.red:hover,
.btn.red:focus,
.btn.red:active,
.btn.red.active,
.btn.red[disabled],
.btn.red.disabled {
  outline: none !important;
  background-color: #bc2c28 !important;
}
/* Green */
.btn.green {
  color: #FFFFFF;
  text-shadow: none;
  background-color: #3FC380;
  border-color: #37b173;
}
.btn.green:hover,
.btn.green:focus,
.btn.green:active,
.btn.green.active,
.btn.green[disabled],
.btn.green.disabled {
  outline: none !important;
  background-color: #319e67 !important;
}
/* Purple */
.btn.purple {
  color: #FFFFFF;
  text-shadow: none;
  background-color: #9B59B6;
  border-color: #8f4bab;
}
.btn.purple:hover,
.btn.purple:focus,
.btn.purple:active,
.btn.purple.active,
.btn.purple[disabled],
.btn.purple.disabled {
  outline: none !important;
  background-color: #804399 !important;
}
/* Yellow */
.btn.yellow {
  color: #FFFFFF;
  text-shadow: none;
  background-color: #F4D03F;
  border-color: #f3ca27;
}
.btn.yellow:hover,
.btn.yellow:focus,
.btn.yellow:active,
.btn.yellow.active,
.btn.yellow[disabled],
.btn.yellow.disabled {
  outline: none !important;
  background-color: #f1c40f !important;
}
/* Dark */
.btn.dark {
  color: #FFFFFF;
  text-shadow: none;
  background-color: #222222;
  border-color: #151515;
}
.btn.dark:hover,
.btn.dark:focus,
.btn.dark:active,
.btn.dark.active,
.btn.dark[disabled],
.btn.dark.disabled {
  outline: none !important;
  background-color: #080808 !important;
}
/**
 *---------------------------------------------------------------
 * BUTTON STRIPES
 *---------------------------------------------------------------
*/
/* Buttons With left Strip */
.red-stripe {
  border-left: 3px solid #D64541;
}
.blue-stripe {
  border-left: 3px solid #34495E;
}
.purple-stripe {
  border-left: 3px solid #9B59B6;
}
.green-stripe {
  border-left: 3px solid #3FC380;
}
.yellow-stripe {
  border-left: 3px solid #F4D03F;
}
.grey-stripe {
  border-left: 3px solid #555555;
}
.dark-stripe {
  border-left: 3px solid #222222;
}
/**
 *---------------------------------------------------------------
 * CMS REDACTOR STYLES
 *---------------------------------------------------------------
*/
.redactor_editor i,
.redactor_editor em {
  font-style: italic;
}
.redactor_editor ul,
.redactor_editor ol {
  padding-left: 28px;
  margin-bottom: 27px;
}
.redactor_editor ul li,
.redactor_editor ol li {
  padding-left: 20px;
  line-height: 24px;
}
.redactor_editor ul {
  list-style: disc;
}
.redactor_editor ol {
  list-style: decimal;
}
/**
 *---------------------------------------------------------------
 * FLIPCLOCK
 *---------------------------------------------------------------
*/
.timer {
  float: left;
  padding: 0 5px;
  margin: 0;
  border-left: 1px solid #111;
}
.timer .glyphicons {
  font-size: 16px;
  line-height: 1.2;
  vertical-align: middle;
}
/* Clock Btns */
.clock-btns {
  float: left;
  margin-right: 10px;
}
.clock-btns .btn {
  display: inline-block;
  background: none;
  border: none;
  color: #CCC;
  padding: 11px 15px;
}
.clock-btns .btn:hover {
  background: #111;
  color: #FFF;
}
/* Main */
.flip-clock-wrapper {
  min-height: 100%;
  font: normal 11px "Helvetica Neue", Helvetica, sans-serif;
  -webkit-user-select: none;
}
.flip-clock-meridium {
  background: none;
  box-shadow: 0 0 0 !important;
  font-size: 14px !important;
  color: #313333;
  bottom: 10px;
}
.flip-clock-wrapper {
  text-align: center;
  position: relative;
  width: auto;
  margin: 7px 0 0 0;
  float: left;
}
/* Skeleton */
.flip-clock-wrapper ul {
  position: relative;
  float: left;
  margin: 0 2px;
  width: 26px;
  height: 31px;
  font-size: 100px;
  font-weight: bold;
  line-height: 31px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}
.flip-clock-wrapper ul li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.flip-clock-wrapper ul li a {
  display: block;
  height: 100%;
  perspective: 200px;
  margin: 0 !important;
  overflow: visible !important;
}
.flip-clock-wrapper ul li a div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.flip-clock-wrapper ul li a div .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.flip-clock-wrapper ul li a div.up {
  transform-origin: 50% 100%;
  top: 0;
}
.flip-clock-wrapper ul li a div.up:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 3px;
  background: #171717;
  border-top: 1px solid #393939;
  border-bottom: 1px solid #393939;
  background-color: rgba(0, 0, 0, 0.4);
}
.flip-clock-wrapper ul li a div.down {
  transform-origin: 50% 0%;
  bottom: 0;
}
.flip-clock-wrapper ul li a div div.inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: #FFFFFF;
  text-shadow: 0 1px 2px #000;
  text-align: center;
  background-color: #333;
  border-radius: 6px;
  font-size: 26px;
  font-family: Arial;
}
/* PLAY */
.flip-clock-divider {
  float: left;
  display: inline-block;
  position: relative;
  width: 15px;
  height: 31px;
  margin-top: 5px;
}
.flip-clock-divider:first-child {
  width: 0;
}
.flip-clock-dot {
  display: block;
  background: #DDD;
  width: 3px;
  height: 3px;
  position: absolute;
  border-radius: 1000px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.flip-clock-divider .flip-clock-label {
  position: absolute;
  top: 30px;
  bottom: 0;
  color: #DDD;
  text-shadow: none;
  display: none;
}
.flip-clock-divider.hours .flip-clock-label {
  right: -45px;
}
.flip-clock-divider.minutes .flip-clock-label {
  right: -45px;
}
.flip-clock-divider.seconds .flip-clock-label {
  right: -55px;
}
.flip-clock-dot.top {
  top: 10px;
  left: 5px;
}
.flip-clock-dot.bottom {
  bottom: 10px;
  left: 5px;
}
.flag {
  width: 32px;
  height: 32px;
  background: url('../images/icons/flags.png') no-repeat;
  display: inline-block;
}
.flag.flag-ad {
  background-position: -32px 0;
}
.flag.flag-ae {
  background-position: -64px 0;
}
.flag.flag-af {
  background-position: -96px 0;
}
.flag.flag-ag {
  background-position: -128px 0;
}
.flag.flag-ai {
  background-position: -160px 0;
}
.flag.flag-al {
  background-position: -192px 0;
}
.flag.flag-am {
  background-position: -224px 0;
}
.flag.flag-an {
  background-position: -256px 0;
}
.flag.flag-ao {
  background-position: -288px 0;
}
.flag.flag-ar {
  background-position: -320px 0;
}
.flag.flag-as {
  background-position: -352px 0;
}
.flag.flag-at {
  background-position: -384px 0;
}
.flag.flag-au {
  background-position: -416px 0;
}
.flag.flag-aw {
  background-position: -448px 0;
}
.flag.flag-az {
  background-position: 0 -32px;
}
.flag.flag-ba {
  background-position: -32px -32px;
}
.flag.flag-bb {
  background-position: -64px -32px;
}
.flag.flag-bd {
  background-position: -96px -32px;
}
.flag.flag-be {
  background-position: -128px -32px;
}
.flag.flag-bf {
  background-position: -160px -32px;
}
.flag.flag-bg {
  background-position: -192px -32px;
}
.flag.flag-bh {
  background-position: -224px -32px;
}
.flag.flag-bi {
  background-position: -256px -32px;
}
.flag.flag-bj {
  background-position: -288px -32px;
}
.flag.flag-bm {
  background-position: -320px -32px;
}
.flag.flag-bn {
  background-position: -352px -32px;
}
.flag.flag-bo {
  background-position: -384px -32px;
}
.flag.flag-br {
  background-position: -416px -32px;
}
.flag.flag-bs {
  background-position: -448px -32px;
}
.flag.flag-bt {
  background-position: 0 -64px;
}
.flag.flag-bw {
  background-position: -32px -64px;
}
.flag.flag-by {
  background-position: -64px -64px;
}
.flag.flag-bz {
  background-position: -96px -64px;
}
.flag.flag-ca {
  background-position: -128px -64px;
}
.flag.flag-cd {
  background-position: -160px -64px;
}
.flag.flag-cf {
  background-position: -192px -64px;
}
.flag.flag-cg {
  background-position: -224px -64px;
}
.flag.flag-ch {
  background-position: -256px -64px;
}
.flag.flag-ci {
  background-position: -288px -64px;
}
.flag.flag-ck {
  background-position: -320px -64px;
}
.flag.flag-cl {
  background-position: -352px -64px;
}
.flag.flag-cm {
  background-position: -384px -64px;
}
.flag.flag-cn {
  background-position: -416px -64px;
}
.flag.flag-co {
  background-position: -448px -64px;
}
.flag.flag-cr {
  background-position: 0 -96px;
}
.flag.flag-cu {
  background-position: -32px -96px;
}
.flag.flag-cv {
  background-position: -64px -96px;
}
.flag.flag-cy {
  background-position: -96px -96px;
}
.flag.flag-cz {
  background-position: -128px -96px;
}
.flag.flag-de {
  background-position: -160px -96px;
}
.flag.flag-dj {
  background-position: -192px -96px;
}
.flag.flag-dk {
  background-position: -224px -96px;
}
.flag.flag-dm {
  background-position: -256px -96px;
}
.flag.flag-do {
  background-position: -288px -96px;
}
.flag.flag-dz {
  background-position: -320px -96px;
}
.flag.flag-ec {
  background-position: -352px -96px;
}
.flag.flag-ee {
  background-position: -384px -96px;
}
.flag.flag-eg {
  background-position: -416px -96px;
}
.flag.flag-eh {
  background-position: -448px -96px;
}
.flag.flag-er {
  background-position: 0 -128px;
}
.flag.flag-es {
  background-position: -32px -128px;
}
.flag.flag-et {
  background-position: -64px -128px;
}
.flag.flag-fi {
  background-position: -96px -128px;
}
.flag.flag-fj {
  background-position: -128px -128px;
}
.flag.flag-fm {
  background-position: -160px -128px;
}
.flag.flag-fo {
  background-position: -192px -128px;
}
.flag.flag-fr {
  background-position: -224px -128px;
}
.flag.flag-ga {
  background-position: -256px -128px;
}
.flag.flag-gb {
  background-position: -288px -128px;
}
.flag.flag-gd {
  background-position: -320px -128px;
}
.flag.flag-ge {
  background-position: -352px -128px;
}
.flag.flag-gg {
  background-position: -384px -128px;
}
.flag.flag-gh {
  background-position: -416px -128px;
}
.flag.flag-gi {
  background-position: -448px -128px;
}
.flag.flag-gl {
  background-position: 0 -160px;
}
.flag.flag-gm {
  background-position: -32px -160px;
}
.flag.flag-gn {
  background-position: -64px -160px;
}
.flag.flag-gp {
  background-position: -96px -160px;
}
.flag.flag-gq {
  background-position: -128px -160px;
}
.flag.flag-gr {
  background-position: -160px -160px;
}
.flag.flag-gt {
  background-position: -192px -160px;
}
.flag.flag-gu {
  background-position: -224px -160px;
}
.flag.flag-gw {
  background-position: -256px -160px;
}
.flag.flag-gy {
  background-position: -288px -160px;
}
.flag.flag-hk {
  background-position: -320px -160px;
}
.flag.flag-hn {
  background-position: -352px -160px;
}
.flag.flag-hr {
  background-position: -384px -160px;
}
.flag.flag-ht {
  background-position: -416px -160px;
}
.flag.flag-hu {
  background-position: -448px -160px;
}
.flag.flag-id {
  background-position: 0 -192px;
}
.flag.flag-ie {
  background-position: -32px -192px;
}
.flag.flag-il {
  background-position: -64px -192px;
}
.flag.flag-im {
  background-position: -96px -192px;
}
.flag.flag-in {
  background-position: -128px -192px;
}
.flag.flag-iq {
  background-position: -160px -192px;
}
.flag.flag-ir {
  background-position: -192px -192px;
}
.flag.flag-is {
  background-position: -224px -192px;
}
.flag.flag-it {
  background-position: -256px -192px;
}
.flag.flag-je {
  background-position: -288px -192px;
}
.flag.flag-jm {
  background-position: -320px -192px;
}
.flag.flag-jo {
  background-position: -352px -192px;
}
.flag.flag-jp {
  background-position: -384px -192px;
}
.flag.flag-ke {
  background-position: -416px -192px;
}
.flag.flag-kg {
  background-position: -448px -192px;
}
.flag.flag-kh {
  background-position: 0 -224px;
}
.flag.flag-ki {
  background-position: -32px -224px;
}
.flag.flag-km {
  background-position: -64px -224px;
}
.flag.flag-kn {
  background-position: -96px -224px;
}
.flag.flag-kp {
  background-position: -128px -224px;
}
.flag.flag-kr {
  background-position: -160px -224px;
}
.flag.flag-kw {
  background-position: -192px -224px;
}
.flag.flag-ky {
  background-position: -224px -224px;
}
.flag.flag-kz {
  background-position: -256px -224px;
}
.flag.flag-la {
  background-position: -288px -224px;
}
.flag.flag-lb {
  background-position: -320px -224px;
}
.flag.flag-lc {
  background-position: -352px -224px;
}
.flag.flag-li {
  background-position: -384px -224px;
}
.flag.flag-lk {
  background-position: -416px -224px;
}
.flag.flag-lr {
  background-position: -448px -224px;
}
.flag.flag-ls {
  background-position: 0 -256px;
}
.flag.flag-lt {
  background-position: -32px -256px;
}
.flag.flag-lu {
  background-position: -64px -256px;
}
.flag.flag-lv {
  background-position: -96px -256px;
}
.flag.flag-ly {
  background-position: -128px -256px;
}
.flag.flag-ma {
  background-position: -160px -256px;
}
.flag.flag-mc {
  background-position: -192px -256px;
}
.flag.flag-md {
  background-position: -224px -256px;
}
.flag.flag-me {
  background-position: -256px -256px;
}
.flag.flag-mg {
  background-position: -288px -256px;
}
.flag.flag-mh {
  background-position: -320px -256px;
}
.flag.flag-mk {
  background-position: -352px -256px;
}
.flag.flag-ml {
  background-position: -384px -256px;
}
.flag.flag-mm {
  background-position: -416px -256px;
}
.flag.flag-mn {
  background-position: -448px -256px;
}
.flag.flag-mo {
  background-position: 0 -288px;
}
.flag.flag-mq {
  background-position: -32px -288px;
}
.flag.flag-mr {
  background-position: -64px -288px;
}
.flag.flag-ms {
  background-position: -96px -288px;
}
.flag.flag-mt {
  background-position: -128px -288px;
}
.flag.flag-mu {
  background-position: -160px -288px;
}
.flag.flag-mv {
  background-position: -192px -288px;
}
.flag.flag-mw {
  background-position: -224px -288px;
}
.flag.flag-mx {
  background-position: -256px -288px;
}
.flag.flag-my {
  background-position: -288px -288px;
}
.flag.flag-mz {
  background-position: -320px -288px;
}
.flag.flag-na {
  background-position: -352px -288px;
}
.flag.flag-nc {
  background-position: -384px -288px;
}
.flag.flag-ne {
  background-position: -416px -288px;
}
.flag.flag-ng {
  background-position: -448px -288px;
}
.flag.flag-ni {
  background-position: 0 -320px;
}
.flag.flag-nl {
  background-position: -32px -320px;
}
.flag.flag-no {
  background-position: -64px -320px;
}
.flag.flag-np {
  background-position: -96px -320px;
}
.flag.flag-nr {
  background-position: -128px -320px;
}
.flag.flag-nz {
  background-position: -160px -320px;
}
.flag.flag-om {
  background-position: -192px -320px;
}
.flag.flag-pa {
  background-position: -224px -320px;
}
.flag.flag-pe {
  background-position: -256px -320px;
}
.flag.flag-pf {
  background-position: -288px -320px;
}
.flag.flag-pg {
  background-position: -320px -320px;
}
.flag.flag-ph {
  background-position: -352px -320px;
}
.flag.flag-pk {
  background-position: -384px -320px;
}
.flag.flag-pl {
  background-position: -416px -320px;
}
.flag.flag-pr {
  background-position: -448px -320px;
}
.flag.flag-ps {
  background-position: 0 -352px;
}
.flag.flag-pt {
  background-position: -32px -352px;
}
.flag.flag-pw {
  background-position: -64px -352px;
}
.flag.flag-py {
  background-position: -96px -352px;
}
.flag.flag-qa {
  background-position: -128px -352px;
}
.flag.flag-re {
  background-position: -160px -352px;
}
.flag.flag-ro {
  background-position: -192px -352px;
}
.flag.flag-rs {
  background-position: -224px -352px;
}
.flag.flag-ru {
  background-position: -256px -352px;
}
.flag.flag-rw {
  background-position: -288px -352px;
}
.flag.flag-sa {
  background-position: -320px -352px;
}
.flag.flag-sb {
  background-position: -352px -352px;
}
.flag.flag-sc {
  background-position: -384px -352px;
}
.flag.flag-sd {
  background-position: -416px -352px;
}
.flag.flag-se {
  background-position: -448px -352px;
}
.flag.flag-sg {
  background-position: 0 -384px;
}
.flag.flag-si {
  background-position: -32px -384px;
}
.flag.flag-sk {
  background-position: -64px -384px;
}
.flag.flag-sl {
  background-position: -96px -384px;
}
.flag.flag-sm {
  background-position: -128px -384px;
}
.flag.flag-sn {
  background-position: -160px -384px;
}
.flag.flag-so {
  background-position: -192px -384px;
}
.flag.flag-sr {
  background-position: -224px -384px;
}
.flag.flag-st {
  background-position: -256px -384px;
}
.flag.flag-sv {
  background-position: -288px -384px;
}
.flag.flag-sy {
  background-position: -320px -384px;
}
.flag.flag-sz {
  background-position: -352px -384px;
}
.flag.flag-tc {
  background-position: -384px -384px;
}
.flag.flag-td {
  background-position: -416px -384px;
}
.flag.flag-tg {
  background-position: -448px -384px;
}
.flag.flag-th {
  background-position: 0 -416px;
}
.flag.flag-tj {
  background-position: -32px -416px;
}
.flag.flag-tl {
  background-position: -64px -416px;
}
.flag.flag-tm {
  background-position: -96px -416px;
}
.flag.flag-tn {
  background-position: -128px -416px;
}
.flag.flag-to {
  background-position: -160px -416px;
}
.flag.flag-tr {
  background-position: -192px -416px;
}
.flag.flag-tt {
  background-position: -224px -416px;
}
.flag.flag-tv {
  background-position: -256px -416px;
}
.flag.flag-tw {
  background-position: -288px -416px;
}
.flag.flag-tz {
  background-position: -320px -416px;
}
.flag.flag-ua {
  background-position: -352px -416px;
}
.flag.flag-ug {
  background-position: -384px -416px;
}
.flag.flag-us {
  background-position: -416px -416px;
}
.flag.flag-uy {
  background-position: -448px -416px;
}
.flag.flag-uz {
  background-position: 0 -448px;
}
.flag.flag-va {
  background-position: -32px -448px;
}
.flag.flag-vc {
  background-position: -64px -448px;
}
.flag.flag-ve {
  background-position: -96px -448px;
}
.flag.flag-vg {
  background-position: -128px -448px;
}
.flag.flag-vi {
  background-position: -160px -448px;
}
.flag.flag-vn {
  background-position: -192px -448px;
}
.flag.flag-vu {
  background-position: -224px -448px;
}
.flag.flag-ws {
  background-position: -256px -448px;
}
.flag.flag-ye {
  background-position: -288px -448px;
}
.flag.flag-za {
  background-position: -320px -448px;
}
.flag.flag-zm {
  background-position: -352px -448px;
}
.flag.flag-zw {
  background-position: -384px -448px;
}
/**
 *---------------------------------------------------------------
 * FORM
 *---------------------------------------------------------------
*/
.error-popup {
  color: #B94A48;
  font-weight: bold;
  font-size: 12px;
}
.form-title {
  margin-top: 20px;
  margin-bottom: 20px;
}
.form-title .timestamps {
  font-size: 11px;
  font-weight: bold;
  color: #555555;
  margin-left: 2px;
}
.form-title .form-title h1 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}
.form-title .form-title h1.highlight {
  color: #991717;
}
.form-title .action-buttons {
  margin: 0;
  padding: 0;
}
.form-horizontal .form-group label,
.form-horizontal .row label {
  text-align: left !important;
  padding-bottom: 5px;
}
.form-control {
  box-shadow: none;
}
input[type=text].form-control,
input[type=search],
.form-control.input-sm,
textarea.form-control {
  box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  border-radius: 0;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
  text-transform: capitalize;
}
.bootstrap-select .btn-default,
.bootstrap-select .btn-default:active,
.bootstrap-select .btn-default:focus {
  background: #FFF;
  color: #444444 !important;
  border-radius: 0;
  box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.03);
  border: 1px solid #e5e5e5;
}
/**
 * Bootstrap select disabled style
 */
.bootstrap-select .btn-default.disabled,
.bootstrap-select .btn-default.disabled.active,
.bootstrap-select .btn-default.disabled:active,
.bootstrap-select .btn-default.disabled:focus,
.bootstrap-select .btn-default.disabled:hover,
.bootstrap-select .btn-default[disabled],
.bootstrap-select .btn-default[disabled].active,
.bootstrap-select .btn-default[disabled]:active,
.bootstrap-select .btn-default[disabled]:focus,
.bootstrap-select .btn-default[disabled]:hover,
.bootstrap-select fieldset[disabled] .btn-default,
.bootstrap-select fieldset[disabled] .btn-default.active,
.bootstrap-select fieldset[disabled] .btn-default:active,
.bootstrap-select fieldset[disabled] .btn-default:focus,
.bootstrap-select fieldset[disabled] .btn-default:hover {
  cursor: not-allowed;
  background-color: #EEE;
  border-color: #E5E5E5;
}
.bootstrap-select.form-control {
  margin-bottom: 0;
}
.input-group-btn-space:last-child > .btn {
  margin-left: 15px;
}
.pop-settings-modal {
  margin-left: 15px;
}
/**
 * Select 2 loading text
 */
.select2-container--default .select2-results__option[aria-disabled=true],
.select2-results__option .loading-results {
  color: green;
  font-weight: bold;
  text-decoration: blink;
}
/**
 * Checkbox and radio styles
 */
.checkbox-styled:not(ie8),
.radio-styled:not(ie8) {
  position: relative;
  margin-top: 0;
}
.checkbox-styled:not(ie8) input ~ span,
.radio-styled:not(ie8) input ~ span {
  position: relative;
  padding-left: 30px;
  display: inline-block;
  cursor: pointer;
  vertical-align: text-top;
}
.checkbox-styled:not(ie8) input ~ span:before,
.radio-styled:not(ie8) input ~ span:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
  border: 2px solid #555555;
}
.checkbox-styled:not(ie8) input[type="radio"],
.radio-styled:not(ie8) input[type="radio"],
.checkbox-styled:not(ie8) input[type="checkbox"],
.radio-styled:not(ie8) input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox-styled:not(ie8) label,
.radio-styled:not(ie8) label {
  padding-left: 0;
}
.checkbox-styled:not(ie8).radio-inline,
.radio-styled:not(ie8).radio-inline,
.checkbox-styled:not(ie8).checkbox-inline,
.radio-styled:not(ie8).checkbox-inline {
  padding-left: 0;
}
.checkbox-styled:not(ie8).disabled ~ span:before,
.radio-styled:not(ie8).disabled ~ span:before,
.checkbox-styled:not(ie8) input[disabled] ~ span:before,
.radio-styled:not(ie8) input[disabled] ~ span:before,
.checkbox-styled:not(ie8) input[readonly] ~ span:before,
.radio-styled:not(ie8) input[readonly] ~ span:before {
  border-color: #EEEEEE;
}
.checkbox-styled:not(ie8).disabled:checked ~ span:before,
.radio-styled:not(ie8).disabled:checked ~ span:before,
.checkbox-styled:not(ie8) input[disabled]:checked ~ span:before,
.radio-styled:not(ie8) input[disabled]:checked ~ span:before,
.checkbox-styled:not(ie8) input[readonly]:checked ~ span:before,
.radio-styled:not(ie8) input[readonly]:checked ~ span:before {
  border-color: #EEEEEE;
}
.checkbox-styled:not(ie8).disabled:checked ~ span:after,
.radio-styled:not(ie8).disabled:checked ~ span:after,
.checkbox-styled:not(ie8) input[disabled]:checked ~ span:after,
.radio-styled:not(ie8) input[disabled]:checked ~ span:after,
.checkbox-styled:not(ie8) input[readonly]:checked ~ span:after,
.radio-styled:not(ie8) input[readonly]:checked ~ span:after {
  background-color: #EEEEEE;
}
.checkbox-styled:not(ie8) input ~ span:before {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.checkbox-styled:not(ie8) input:checked ~ span:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 10px;
  border-color: #991717;
  border-top-style: none;
  border-right-style: none;
}
.radio-styled:not(ie8) input ~ span:before {
  top: 0;
  border-radius: 50%;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.radio-styled:not(ie8) input:checked ~ span:before {
  border-color: #991717;
}
.radio-styled:not(ie8) input ~ span:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  width: 0;
  height: 0;
  background-color: #991717;
  border-radius: 50%;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.radio-styled:not(ie8) input:checked ~ span:after {
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
}
/*
 * Role styles
 */
.roles .panel-heading {
  font-weight: 700;
  border-bottom: none;
  color: #555;
  text-transform: initial;
}
.roles .permission-group {
  padding: 0;
  margin: 0;
}
.roles .permission-group .permission {
  padding: 5px 10px 5px 20px;
  border-bottom: 1px dashed #e2e2e2;
}
.roles .permission-group .permission label {
  font-size: 12px;
}
.roles .permission-group .permission .checkbox {
  padding: 0;
}
.roles .permission-group .permission:last-child {
  border-bottom: none;
  margin-bottom: 10px;
}
.form-control.has-error {
  border-color: #D64541;
}
.control-label.has-error {
  color: #D64541;
}
.error-popup.has-error {
  position: absolute;
  top: -32px;
  right: 15px;
  line-height: 34px;
  height: 32px;
  padding: 0 12px;
  background-color: #D64541;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .4px;
}
/**
 *---------------------------------------------------------------
 * SMALL MOBILE - Max Width: 480px breakpoint 30em
 *---------------------------------------------------------------
*/
@media only screen and (max-width: 480px) {
  .form-title h1,
  .form-title .action-buttons {
    font-size: 1.3em;
  }
  .form-title h1 {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
/**
 *---------------------------------------------------------------
 * MOBILE - Min Width: 600px breakpoint 38em
 *---------------------------------------------------------------
*/
@media only screen and (min-width: 600px) {
  .form-title h1 {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
/**
 *---------------------------------------------------------------
 * TABLET - Min Width: 768px breakpoint 48em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * SMALL DESKTOP- Min Width: 992px breakpoint 62em
 *---------------------------------------------------------------
*/
@media only screen and (min-width: 992px) {
  .form-title h1 {
    margin-bottom: 0;
    font-size: 24px;
  }
}
/**
 *---------------------------------------------------------------
 * LARGE DESKTOP- Min Width: 1382px breakpoint 86em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * LARGE DESKTOP- Retina Display
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * IMAGES
 *---------------------------------------------------------------
*/
.img-bg,
.img-cover,
.img-contain {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.img-bg.img-contain,
.img-cover.img-contain,
.img-contain.img-contain {
  background-size: contain;
}
/**
 *---------------------------------------------------------------
 * LABEL & BADGE
 *---------------------------------------------------------------
*/
.label.label-sm {
  padding: 2px 4px;
}
h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
  font-size: 75%;
}
.badge {
  padding: 3px 6px 3px 6px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px !important;
  text-shadow: none;
  text-align: center;
  vertical-align: middle;
}
.badge.badge-roundless {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.badge-default,
.label-default {
  background-color: #888888;
}
.badge-primary,
.label-primary {
  background-color: #991717;
}
.label-success,
.badge-success {
  background-color: #3FC380;
}
.label-warning,
.badge-warning {
  background-color: #f0ad4e;
}
.label-danger,
.badge-danger {
  background-color: #d24d57;
}
.label-info,
.badge-info {
  background-color: #5bc0de;
}
.label-red,
.badge-red {
  background-color: #E74C3C;
  background-image: none;
}
.label-green,
.badge-green {
  background-color: #16A085;
  background-image: none;
}
.label-blue,
.badge-blue {
  background-color: #2980B9;
  background-image: none;
}
.label-dark-blue,
.badge-dark-blue {
  background-color: #2C3E50;
  background-image: none;
}
.label-purple,
.badge-purple {
  background-color: #8E44AD;
  background-image: none;
}
.label-silver,
.badge-silver {
  background-color: #7F8C8D;
  background-image: none;
}
.label-orange,
.badge-orange {
  background-color: #D35400;
  background-image: none;
}
.label-yellow,
.badge-yellow {
  background-color: #F4D03F;
  background-image: none;
}
/**
 * List group styles
 */
.list-group .fa,
.list-group .md,
.list-group .glyphicon {
  margin-right: 5px;
}
.list-group .btn[class^="btn-radio"],
.list-group .btn[class^="btn-checkbox"],
.list-group .btn[class*=" btn-radio"],
.list-group .btn[class*=" btn-checkbox"] {
  padding: 0;
}
/**
 * List group item styles
 */
.list-group-item {
  color: #555555;
}
.list-group-item.focus,
.list-group-item.focus:hover,
.list-group-item.focus:focus {
  background-color: #F5F5F5;
}
.list-group-item.focus .list-group-item-heading,
.list-group-item.focus:hover .list-group-item-heading,
.list-group-item.focus:focus .list-group-item-heading {
  color: inherit;
}
.list-group-item.focus .list-group-item-text,
.list-group-item.focus:hover .list-group-item-text,
.list-group-item.focus:focus .list-group-item-text {
  opacity: .75;
}
/**
 * Tags list
 */
.tags-list .btn {
  margin-bottom: 3px;
}
/**
 *---------------------------------------------------------------
 * LOGIN: CONTAINER
 *---------------------------------------------------------------
*/
.login-container {
  border: 1px solid #DDDDDD;
  background-color: #FFFFFF;
  margin-top: 20%;
  box-shadow: 0 0 5px #444444;
}
.login-container .login-header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #DDDDDD;
  font-size: 14px;
  margin: 0;
  padding: 10px 15px;
  font-weight: bold;
}
.login-container label {
  color: #555555;
}
.login-container .login-form {
  padding: 20px 15px;
}
.login-container input.error {
  border-color: #D64541;
  box-shadow: 0 0 3px #D64541;
}
/**
 *---------------------------------------------------------------
 * LOGIN: HEADER LOGO
 *---------------------------------------------------------------
*/
/* Header Logo */
.login-container .login-header.header-logo {
  margin: 0;
}
.login-container .login-header.header-logo img {
  max-height: 60px;
  width: auto;
}
/**
 *---------------------------------------------------------------
 * LOGIN: HEADER TEXT
 *---------------------------------------------------------------
*/
/* Header Text */
.login-container .login-header.header-text {
  margin: 0;
  padding: 10px 15px;
  font-size: 20px;
  color: #EEE;
  background: #FFF;
}
/**
 *---------------------------------------------------------------
 * LOGIN: OTHER ACTIONS
 *---------------------------------------------------------------
*/
.login-container .forgotLnk {
  margin-top: 10px;
  display: block;
}
.login-container .or-box {
  position: relative;
  border-top: 1px solid #dfdfdf;
  padding-top: 20px;
  margin: 20px 0 5px 0;
}
.login-container .or {
  color: #666666;
  background-color: #ffffff;
  position: absolute;
  text-align: center;
  top: -8px;
  width: 40px;
  left: 43%;
}
.login-container .btn-facebook {
  background-color: #3F639E;
  color: #fff;
  font-weight: bold;
}
.login-container .btn-google {
  background-color: #454545;
  color: #fff;
  font-weight: bold;
}
.login-container .btn-facebook:hover,
.login-container .btn-google:hover {
  color: #fff;
}
#loadingbar {
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: -6px;
  width: 0;
  height: 2px;
  background: #e13535;
  border-radius: 1px;
  transition: all 500ms ease-in-out;
}
#loadingbar.waiting dd,
#loadingbar.waiting dt {
  animation: pulse 2s ease-out 0s infinite;
}
#loadingbar dt {
  opacity: .6;
  width: 180px;
  right: -80px;
  clip: rect(-6px, 90px, 14px, -6px);
}
#loadingbar dd {
  opacity: .6;
  width: 20px;
  right: 0;
  clip: rect(-6px, 22px, 14px, 10px);
}
#loadingbar dd,
#loadingbar dt {
  position: absolute;
  top: 0;
  height: 2px;
  box-shadow: #991717 1px 0 6px 1px;
  border-radius: 100%;
}
@keyframes pulse {
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}
@-moz-keyframes pulse {
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}
@-ms-keyframes pulse {
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}
@-webkit-keyframes pulse {
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}
/**
 *---------------------------------------------------------------
 * NOTES
 *---------------------------------------------------------------
*/
.note h1,
.note h2,
.note h3,
.note h4 {
  margin-top: 0;
}
.note p:last-child {
  margin-bottom: 0;
}
.note code,
.note .highlight {
  background-color: #FFFFFF;
}
.note-success {
  background-color: #3FC380;
  border-color: #37b173;
}
.note-info {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.note-warning {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.note-danger {
  background-color: #d24d57;
  border-color: #cd3944;
}
/**
*---------------------------------------------------------------
* NOTES
*---------------------------------------------------------------
*/
#notes .add-note {
  margin-bottom: 10px;
  border: 1px solid #DEE5E7;
}
#notes .add-note .note-box {
  padding: 10px;
  background: #F6F8F8;
}
#notes .add-note .note-actions {
  padding: 10px;
  background: #FFF;
}
#notes .view-notes {
  max-height: 550px;
  overflow: auto;
  margin-bottom: 20px;
}
#notes .view-notes .note .note-thumb {
  border: 1px solid #991717;
  display: block;
  height: 40px;
  border-radius: 50% !important;
}
#notes .view-notes .note .note-title {
  padding: 10px 10px 0 10px;
  font-weight: bold;
}
#notes .view-notes .note .note-description {
  padding: 10px;
  color: #5e5e5e;
}
#notes .view-notes .note code,
#notes .view-notes .note .highlight {
  background-color: #FFFFFF;
}
#notes .view-notes .note .note-success {
  background-color: #3FC380;
  border-color: #37b173;
}
#notes .view-notes .note .note-info {
  background-color: #5bc0de;
  border-color: #46b8da;
}
#notes .view-notes .note .note-warning {
  background-color: #f0ad4e;
  border-color: #eea236;
}
#notes .view-notes .note .note-danger {
  background-color: #d24d57;
  border-color: #cd3944;
}
#notes .view-notes .note .arrow.left {
  top: 20px;
}
#notes .delete-note-btn {
  cursor: pointer;
  margin-left: 5px;
}
#notes .tall-notes {
  background-color: #fff;
}
#notes .tall-notes .add-note {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border: 0;
  border-bottom: 1px solid #f2f2f2;
}
#notes .tall-notes .add-note .note-box {
  padding: 0;
  background: #FFF;
}
#notes .tall-notes .add-note .note-actions {
  padding: 0;
  background: #FFF;
}
/**
 *---------------------------------------------------------------
 * PANEL
 *---------------------------------------------------------------
*/
.panel {
  border-radius: 2px;
}
/**
 *---------------------------------------------------------------
 * PANEL HEADING
 *---------------------------------------------------------------
*/
.panel-default > .panel-heading,
.panel-default > .panel-footer {
  border-color: #EDF1F2;
}
.panel-default > .panel-heading {
  padding: 10px 15px;
}
.panel > .panel-heading:after,
.panel > .panel-heading:before {
  content: "";
  display: table;
  line-height: 0;
}
.panel > .panel-heading:after {
  clear: both;
}
/**
 *---------------------------------------------------------------
 * PANEL CAPTION
 *---------------------------------------------------------------
*/
.panel > .panel-heading > .caption {
  float: left;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}
.panel > .panel-heading > .caption > i {
  float: left;
  margin-top: 4px;
  display: inline-block !important;
  font-size: 13px;
  margin-right: 5px;
  color: #555555;
}
/**
 *---------------------------------------------------------------
 * PANEL SWITCH
 *---------------------------------------------------------------
*/
.panel-heading .switch-text {
  font-size: 14px;
  font-weight: bold;
}
/**
 *---------------------------------------------------------------
 * PANEL TOOLS
 *---------------------------------------------------------------
*/
.panel > .panel-heading > .tools,
.panel > .panel-heading > .actions {
  display: inline-block;
  padding: 0;
  margin: 0;
  float: right;
}
.panel > .panel-heading > .tools > a {
  display: inline-block;
  margin-left: 5px;
  color: #777;
}
.panel > .panel-heading > .tools > a:hover {
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  color: #6F5499;
}
/**
 *---------------------------------------------------------------
 * PANEL ACTIONS
 *---------------------------------------------------------------
*/
.panel-heading.has-pretty {
  padding: 5px 15px 0 15px;
}
.panel-heading.has-pretty span {
  margin: 5px 0 0 0;
  display: inline-block;
}
.panel-heading.has-pretty label,
.panel-heading.has-pretty label {
  font-weight: normal;
  margin: 5px 5px 0 2px;
}
.panel-heading.has-actions {
  padding-top: 7px;
  padding-bottom: 7px;
  line-height: 30px;
}
/**
 *---------------------------------------------------------------
 * PANEL BODY
 *---------------------------------------------------------------
*/
.panel-body {
  clear: both;
  position: relative;
}
.panel-body label {
  color: #555555;
}
.panel > .panel-body.has-tiny {
  padding: 0;
}
.panel > .panel-body.has-tiny .form-group {
  margin-bottom: 0;
}
/**
 *---------------------------------------------------------------
 * PANEL BODY TABLE
 *---------------------------------------------------------------
*/
.panel-body .table-title {
  padding: 10px 0;
}
.panel-body .table-title h3 {
  margin: 0;
  padding: 0;
}
.panel-default > .table th {
  border-bottom: 1px solid #DDDDDD;
}
.panel-body .table.no-padding {
  margin: 0;
  padding: 0;
}
/**
 *---------------------------------------------------------------
 * PANEL NO RESULTS
 *---------------------------------------------------------------
*/
.panel .no-results {
  text-align: center;
}
.panel .no-results h3 {
  margin: 25px;
}
/**
 *---------------------------------------------------------------
 * PANEL BODY COLOURS
 *---------------------------------------------------------------
*/
.panel > .panel-body.blue,
.panel > .panel-heading.blue,
.panel.blue {
  color: #FFFFFF;
  background-color: #34495E !important;
}
.panel > .panel-body.red,
.panel > .panel-heading.red,
.panel.red {
  color: #FFFFFF;
  background-color: #D64541 !important;
}
.panel > .panel-body.yellow,
.panel > .panel-heading.yellow,
.panel.yellow {
  color: #FFFFFF;
  background-color: #F4D03F !important;
}
.panel > .panel-body.green,
.panel > .panel-heading.green,
.panel.green {
  color: #FFFFFF;
  background-color: #3FC380 !important;
}
.panel > .panel-body.purple,
.panel > .panel-heading.purple,
.panel.purple {
  color: #FFFFFF;
  background-color: #9B59B6 !important;
}
/**
 *---------------------------------------------------------------
 * MODAL PANEL
 *---------------------------------------------------------------
*/
.modal-body .panel {
  margin-bottom: 20px;
  background-color: none;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-body .panel-default > .panel-heading {
  background: #F9F9F9;
  padding: 10px 15px;
  border: none;
}
.modal-body .panel-default > .panel-body {
  padding: 15px;
}
/**
 *---------------------------------------------------------------
 * MISC
 *---------------------------------------------------------------
*/
/* Dashboard Widgets */
#featured-widgets {
  margin-bottom: 20px;
}
/* Sortable Panels */
.sortable .panel > .panel-heading {
  cursor: move;
}
.panel-text-area {
  margin: -1px;
}
.title-btn-sm {
  line-height: 30px;
}
/**
 *---------------------------------------------------------------
 * SMALL MOBILE - Max Width: 480px breakpoint 30em
 *---------------------------------------------------------------
*/
@media only screen and (max-width: 480px) {
  /* Fix tables and datatables on mobile */
  .panel-body .table {
    margin: -15px;
  }
  .panel-body .dataTables_wrapper {
    margin: -15px;
  }
  .panel-body .dataTables_wrapper .table {
    margin: 0;
  }
  .panel-body .dataTables_header {
    margin-bottom: 0;
  }
  .dataTables_header select {
    border-radius: 0;
  }
  .panel-body .dataTables_footer {
    margin-bottom: -15px;
    padding-bottom: 15px;
  }
  .table td.actions {
    width: 150px !important;
  }
  .table td.actions .btn-group {
    width: 120px !important;
  }
  div.dataTables_length,
  div.dataTables_filter,
  div.dataTables_info,
  .pagination {
    float: left;
    display: inline-block;
    width: 300px;
    text-align: left;
  }
  div.dataTables_filter input {
    width: 80%;
  }
  div.dataTables_filter label,
  div.dataTables_paginate {
    float: left;
  }
  div.dataTables_paginate {
    margin-top: 10px;
  }
  div.dataTables_filter {
    float: left;
  }
  .pagination > li > a,
  .pagination > li > span {
    padding: 3px 6px;
  }
  .actions .btn {
    padding: 4px 10px;
  }
}
/**
 *---------------------------------------------------------------
 * MOBILE - Min Width: 600px breakpoint 38em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * TABLET - Min Width: 768px breakpoint 48em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * SMALL DESKTOP- Min Width: 992px breakpoint 62em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * LARGE DESKTOP- Min Width: 1382px breakpoint 86em
 *---------------------------------------------------------------
*/
/**
 *---------------------------------------------------------------
 * LARGE DESKTOP- Retina Display
 *---------------------------------------------------------------
*/
.loading {
  position: absolute;
  top: 20%;
  left: 48.5%;
}
.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
}
.preloader-wrapper.small {
  width: 36px;
  height: 36px;
}
.preloader-wrapper.big {
  width: 64px;
  height: 64px;
}
.preloader-wrapper.active {
  animation: container-rotate 1568ms linear infinite;
}
@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}
.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.spinner-blue,
.spinner-blue-only {
  border-color: #4285f4;
}
.spinner-red,
.spinner-red-only {
  border-color: #db4437;
}
.spinner-yellow,
.spinner-yellow-only {
  border-color: #f4b400;
}
.spinner-green,
.spinner-green-only {
  border-color: #0f9d58;
}
.active .spinner-layer.spinner-blue {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .spinner-layer.spinner-red {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .spinner-layer.spinner-yellow {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .spinner-layer.spinner-green {
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .spinner-layer.spinner-blue-only,
.active .spinner-layer.spinner-red-only,
.active .spinner-layer.spinner-yellow-only,
.active .spinner-layer.spinner-green-only {
  opacity: 1;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
@-webkit-keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}
@-webkit-keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}
@-webkit-keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/**
 * Patch the gap that appear between the two adjacent div.circle-clipper while the
 * spinner is rotating (appears on Chrome 38, Safari 7.1, and IE 11).
 */
.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.gap-patch .circle {
  width: 1000%;
  left: -450%;
}
.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  transform: rotate(129deg);
}
.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
}
.active .circle-clipper.left .circle {
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.active .circle-clipper.right .circle {
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}
@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}
#spinnerContainer.cooldown {
  animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/**
 *---------------------------------------------------------------
 * GOOGLE LISTING PREVIEW
 *---------------------------------------------------------------
*/
.google-preview {
  position: relative;
  padding: 15px;
  background: #fff;
  border: 1px solid #DDD;
  font-family: arial, sans-serif;
  margin-bottom: 25px;
  line-height: 1.2;
}
.google-preview > .preview-label {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  padding: 2px 5px;
  text-align: right;
  background: #DDD;
}
.google-preview > .preview-title,
.google-preview > .preview-description {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.google-preview > .preview-title {
  color: #609;
  font-size: 18px;
  margin-bottom: 5px;
}
.google-preview > .preview-url {
  color: #006621;
  font-style: normal;
  font-size: 13px;
  height: 17px;
  line-height: 16px;
  margin-bottom: 5px;
}
.google-preview > .preview-description {
  color: #545454;
  line-height: 1.3;
  word-wrap: break-word;
  font-size: small;
  max-width: 42em;
}
/**
 *---------------------------------------------------------------
 * SINGLE FILE UPLOAD FORM STYLES
 *---------------------------------------------------------------
*/
.single-file {
  position: relative;
}
.single-file .preview {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-color: #dadfe1;
  background-image: url(/packages/netmatters/base/images/upload-logo.png);
  background-repeat: no-repeat;
  background-position: center;
}
.single-file .preview.img-contain {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border: 12px solid #FFFFFF;
  background-color: #FFFFFF;
  outline: 1px solid #ccc;
  padding: 12px;
}
.single-file:hover .preview.img-contain:not(.active) {
  background-image: url(/packages/netmatters/base/images/upload-logo.png) !important;
  background-color: #dadfe1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  outline: 0;
  border: 0;
  pointer-events: none;
}
.single-file .form-control {
  border: 0;
  background-color: #FFFFFF;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  padding: 64px 12px 86px 20px;
  cursor: pointer;
}
/**
 *---------------------------------------------------------------
 * SORTABLE
 *---------------------------------------------------------------
*/
.sortable-dragging {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
  min-width: 350px;
}
.sortable li {
  display: block;
  margin: 5px 0;
}
.sortable li span.move {
  cursor: move !important;
}
.sortable li span {
  line-height: 24px;
}
.sortable li {
  line-height: 34px;
}
.sortable li ul li {
  margin-left: 10px;
  border: 0;
  padding: 0;
}
.sortable li ul li ul li {
  margin-left: 10px;
}
.sortable .list-sub-item {
  line-height: 34px;
  margin: 5px 0;
  border: 0;
  overflow: hidden;
  background-color: #EBEBEB;
}
.sortable li.sortable-placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  border: 2px dashed #DDDDDD;
  background: #EEEEEE;
  padding: 20px;
}
.sortable li.sortable-placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -10px;
  left: -10px;
  top: 50%;
  border: 5px solid transparent;
  border-left-color: red;
  border-right: none;
}
/**
 *---------------------------------------------------------------
 * SORTABLE ACTIONS
 *---------------------------------------------------------------
*/
.sortable .sort-actions {
  float: right;
  color: #888;
}
.sortable .sort-order {
  margin-right: 5px;
}
.sortable .sort-move {
  color: #fff;
}
/**
 *---------------------------------------------------------------
 * TABS
 *---------------------------------------------------------------
*/
.nav-tabs {
  border-color: #f2f2f2;
}
.nav-tabs > li > a {
  background: #f5f5f5;
  border: 1px solid #e2e2e2;
  border-radius: 2px 2px 0 0;
  border-bottom: none;
}
.nav-tabs > li > a:hover {
  background: #f2f2f2;
  border: 1px solid #dddddd;
  border-bottom: none;
}
.nav-tabs > li.active > a {
  border-color: #f2f2f2;
  background-color: #FFF;
  border: 1px solid #DEE5E7;
  border-bottom-color: #FFF !important;
}
.nav-tabs > li.active > a:active,
.nav-tabs > li.active > a:visited,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  background-color: #FFF;
}
.nav-tabs-alt .nav-tabs.nav-justified > li {
  display: table-cell;
  width: 1%;
}
.nav-tabs-alt .nav-tabs > li > a {
  border-radius: 0;
  border-color: transparent !important;
  background: transparent !important;
  border-bottom-color: #f2f2f2 !important;
}
.nav-tabs-alt .nav-tabs > li.active > a {
  border-bottom-color: #991717 !important;
}
.nav-tabs-alt .nav-tabs li span,
.nav-tabs-alt .nav-tabs li i {
  font-size: 20px;
}
.tab-container {
  margin-bottom: 15px;
}
.tab-container .tab-content {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #dee5e7;
  border-top-width: 0;
  border-radius: 0 0 2px 2px;
}
/**
 *---------------------------------------------------------------
 * TILES
 *---------------------------------------------------------------
*/
.tile {
  height: 135px;
  width: 100%;
  margin-bottom: 14px;
  display: block;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 20px;
  overflow: hidden;
  border: 4px solid #bbb;
  background-image: none;
  background-color: #FFF;
  border: 1px solid #DDD;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.tile * {
  color: #999;
}
.tile:hover * {
  color: #666;
}
.tile:hover {
  border-color: #c4c4c4;
}
.tile a,
.tile a:hover {
  color: #999;
  text-decoration: none;
}
.tile:after,
.tile:before {
  content: "";
  float: left;
}
.tile.double-down {
  height: 280px !important;
}
.tile:active,
.tile.selected {
  border-color: #ccc !important;
}
.tile.selected .corner:after {
  content: "";
  display: inline-block;
  border-left: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid #ccc;
  position: absolute;
  top: -3px;
  right: -3px;
}
.tile.selected .check:after {
  content: "";
  font-family: FontAwesome;
  font-size: 13px;
  content: "\f00c";
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
}
.tile .tile-body {
  height: 100%;
  vertical-align: top;
  padding: 10px 10px;
  overflow: hidden;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
}
.tile .tile-body img {
  float: left;
  margin-right: 10px;
}
.tile .tile-body img.pull-right {
  float: right !important;
  margin-left: 10px;
  margin-right: 0px;
}
.tile .tile-body .content {
  display: inline-block;
}
.tile .tile-body > i,
.tile .tile-body > span {
  margin-top: 17px;
  display: block;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}
.tile .tile-body > i::before,
.tile .tile-body > span::before {
  display: block;
}
.tile.double-down i,
.tile.double-down span {
  margin-top: 95px;
}
.tile .tile-body h1,
.tile .tile-body h2,
.tile .tile-body h3,
.tile .tile-body h4,
.tile .tile-body h5,
.tile .tile-body h6,
.tile .tile-body p {
  padding: 0;
  margin: 0;
  line-height: 14px;
}
.tile .tile-body h3,
.tile .tile-body h4 {
  margin-bottom: 5px;
}
.tile .tile-body p {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
}
.tile.icon > .tile-body {
  padding: 0;
}
.tile .tile-object {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 30px;
  background-color: transparent;
  *zoom: 1;
}
.tile .tile-object:before,
.tile .tile-object:after {
  display: table;
  content: "";
}
.tile .tile-object:after {
  clear: both;
}
.tile .tile-object > .name {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 5px;
  padding-left: 10px;
  width: 100%;
  text-align: center;
  padding-right: 10px;
  font-weight: bold;
  font-size: 13px;
}
.tile .tile-object > .name > i {
  vertical-align: middle;
  display: block;
  font-size: 24px;
  height: 18px;
  width: 24px;
}
.tile .tile-object > .number {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 14px;
  margin-bottom: 8px;
  margin-right: 10px;
}
.tile.image > .tile-body {
  padding: 0 !important;
}
.tile.image > .tile-body > img {
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: 100%;
}
.tile.image .tile-body h3 {
  display: inline-block;
}
/**
 *---------------------------------------------------------------
 * SORTABLE PLACEHOLDER
 *---------------------------------------------------------------
*/
.sortable-place-holder {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: top;
  margin-bottom: 14px;
}
.sortable-place-holder::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
  right: 7px;
  border: 2px dashed #ddd;
  background: #eee;
  padding: 0 20px;
}
#dashboard-form {
  margin: 0 -25px;
}
#dashboard-form > div {
  padding: 0 25px;
}
#dashboard-form .test {
  background: linear-gradient(to bottom right, #008299, #10acbe);
  color: #FFFFFF;
}
#dashboard-form .dashboard-group {
  min-height: 133px;
  display: block;
  margin: 0 -7px;
  font-size: 0;
}
#dashboard-form .dashboard-group > li {
  display: inline-block;
  float: none;
  padding: 0 7px;
}
#dashboard-form .dashboard-group > li .popover.top {
  top: auto;
  left: auto;
  right: -100px;
  left: -100px;
  margin: auto;
  width: 270px;
  border-radius: 0 !important;
  z-index: 9990;
}
#dashboard-form .dashboard-group > li .popover.top .popover-content.form-horizontal {
  padding: 15px;
}
#dashboard-form .dashboard-group > li .popover.top .popover-content.form-horizontal .control-label {
  text-align: left;
  font-size: 14px;
}
#dashboard-form .dashboard-group > li .popover.top .popover-content.form-horizontal .form-group:last-child {
  margin-bottom: 0;
}
#dashboard-form .dashboard-group a.custom-icon,
#dashboard-form .dashboard-group a.bg-cover,
#dashboard-form .dashboard-group a.bg-contain {
  height: 135px;
  margin-bottom: 20px;
}
#dashboard-form .tile.custom-icon {
  border: 0;
  text-align: center;
}
#dashboard-form .tile.custom-icon .tile-body img {
  margin-top: 17px;
  display: inline-block;
  text-align: center;
  float: none;
  margin-right: 0;
}
#dashboard-form .tile.custom-icon a {
  display: block;
  height: 135px;
}
#dashboard-form .tile.custom-icon * {
  color: #FFFFFF;
}
.bg-cover,
.bg-contain {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-contain {
  background-size: contain;
}
/**
 *---------------------------------------------------------------
 * NEWS WIDGET OVERRIDE
 *---------------------------------------------------------------
*/
.tile.news-widget {
  padding: 0;
  height: 295px;
}
.tile.news-widget .news-top {
  display: block;
  font-weight: bold;
  border-bottom: 1px solid #DDD;
  padding: 15px 20px;
  font-size: 14px;
}
.tile.news-widget .tile-body {
  padding: 0;
}
.tile.news-widget .tile-body .tile-inner {
  padding: 20px;
}
.tile.news-widget .tile-body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tile.news-widget .tile-body .bx-wrapper {
  min-height: 158px;
}
.tile.news-widget .caption {
  color: #fff;
  min-height: 140px;
}
.tile.news-widget .caption h3 {
  font-size: 14px;
  font-weight: bold;
}
.tile.news-widget .caption h3 {
  line-height: 18px;
}
.tile.news-widget .caption p {
  line-height: 24px;
}
.news-widget .caption a,
.news-widget .caption span {
  font-weight: bold;
}
.news-widget .caption a {
  margin-top: 10px;
  clear: both;
  display: block;
}
#internal-news .btn-next,
#internal-news .btn-prev {
  float: right;
  padding: 12px 18px;
  background-color: #999;
  margin-left: 15px;
}
#internal-news .btn-next:hover,
#internal-news .btn-prev:hover {
  background-color: #666;
}
#internal-news .btn-next span,
#internal-news .btn-prev span {
  color: #fff;
}
#knowledge-base .btn-next1,
#knowledge-base .btn-prev1 {
  float: right;
  padding: 12px 18px;
  background-color: #999;
  margin-left: 15px;
}
#knowledge-base .btn-next:hover,
#knowledge-base .btn-prev:hover {
  background-color: #666;
}
#knowledge-base .btn-next1 span,
#knowledge-base .btn-prev1 span {
  color: #fff;
}
#knowledge-base .tile-inner {
  overflow: auto;
}
/**
 *---------------------------------------------------------------
 * WARNING
 *---------------------------------------------------------------
*/
.has-warning .help-inline,
.has-warning .help-block,
.has-warning .control-label {
  color: #c09853;
}
.has-warning .form-control {
  border-color: #c09853;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #c09853;
}
/**
 *---------------------------------------------------------------
 * ERROR
 *---------------------------------------------------------------
*/
.has-error .help-inline,
.has-error .help-block,
.has-error .control-label {
  color: #b94a48;
}
.has-error .form-control {
  border-color: #b94a48;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:focus {
  border-color: #953b39;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #b94a48;
}
/**
 *---------------------------------------------------------------
 * SUCCESS
 *---------------------------------------------------------------
*/
.has-success .help-inline,
.has-success .help-block,
.has-success .control-label {
  color: #468847;
}
.has-success .form-control {
  border-color: #468847;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control:focus {
  border-color: #356635;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .input-group-addon {
  color: #468847;
  background-color: #dff0d8;
  border-color: #468847;
}
/**
 * Navbar default
 */
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555555;
  background-color: #F5F5F5;
  font-weight: normal;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #DDDDDD;
}
/**
 * Inline Wizard
 */
.wizard {
  /* Navbar */
  /** Wizard Bar */
}
.wizard .navbar {
  z-index: 998 !important;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
}
.wizard .navbar li a .glyphicons {
  margin-right: 10px;
}
.wizard .navbar li.active {
  font-weight: bold;
}
.wizard .navbar li.success a {
  color: #008A2E !important;
  background-color: rgba(0, 138, 46, 0.1);
  border-right: 1px solid rgba(0, 138, 46, 0.2);
}
.wizard .navbar li.error a {
  color: #E02222 !important;
  background-color: rgba(224, 34, 34, 0.1);
  border-right: 1px solid rgba(224, 34, 34, 0.2);
}
.wizard .navbar li:last-child a {
  border-right: none;
}
.wizard .wizard-bar {
  padding: 10px 15px;
}
.wizard .wizard-bar .wizard-status {
  padding-top: 3px;
  color: #888888;
}
.wizard .wizard-bar .wizard-status #status-text {
  float: left;
  margin-right: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
}
.wizard .wizard-bar .wizard-status #bar {
  margin-bottom: 0;
  border: 1px solid #ccc;
}
.wizard .wizard-bar .wizard-actions {
  text-align: right;
}
/**
 * Step Wizard
 */
.step-wizard {
  margin-top: 15%;
  background-color: #fff;
  border: 1px solid #DDD;
  padding: 30px;
  /* Loading spinner */
  /* Wizard Steps */
  /* Wizard content */
  /* Wizard actions */
}
.step-wizard .loading {
  position: absolute;
  top: 50%;
  left: 44.5%;
}
.step-wizard .wizard-steps {
  position: relative;
  margin-bottom: 30px;
  /* Progress bar */
  /* Navigation */
}
.step-wizard .wizard-steps .progress {
  position: absolute;
  display: inline-block;
  top: 23px;
  left: 0;
  right: 0;
  margin: auto;
  height: 10px;
}
.step-wizard .wizard-steps .nav {
  position: relative;
}
.step-wizard .wizard-steps .nav .step {
  display: inline-block;
  line-height: 28px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  border: 4px solid #E5E6E6;
  background: #FFFFFF;
}
.step-wizard .wizard-steps .nav .title {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -15px;
  color: #969C9C;
}
.step-wizard .wizard-steps .nav li a {
  margin-bottom: 15px;
}
.step-wizard .wizard-steps .nav li.active a,
.step-wizard .wizard-steps .nav li:hover a {
  color: inherit;
  background-color: transparent;
}
.step-wizard .wizard-steps .nav li.active .step,
.step-wizard .wizard-steps .nav li:hover .step {
  -webkit-transition: border-color 0.4s linear;
  -o-transition: border-color 0.4s linear;
  transition: border-color 0.4s linear;
  border-color: #991717;
  border-radius: 50% !important;
}
.step-wizard .wizard-steps .nav li.active .title,
.step-wizard .wizard-steps .nav li:hover .title {
  color: #313534;
}
.step-wizard .wizard-steps .nav li.done .step {
  color: #FFFFFF;
  border-color: #991717;
  background-color: #991717;
  font-weight: 500;
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.step-wizard .wizard-steps .nav li.disabled:hover .step {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  border-color: #E5E6E6;
}
.step-wizard .wizard-steps .nav li.disabled:hover .title {
  color: #969C9C;
}
.step-wizard .wizard-content {
  padding: 30px 0;
  margin: 30px 0;
  border-top: 3px dashed #E2E2E2;
  border-bottom: 3px dashed #E2E2E2;
}
/**
 * Small devices
 */
@media (max-width: 768px) {
  .step-wizard .wizard-steps .progress {
    display: none;
  }
}
/**
 *---------------------------------------------------------------
 * SHARED STYLES
 *---------------------------------------------------------------
*/
.wysiwyg-footer {
  background: #F0F0F0;
  border: 1px solid #DDD;
  border-top: none;
  min-height: 20px;
  padding: 5px;
}
.wysiwyg-footer .word-count {
  font-size: 12px;
}
/**
 *---------------------------------------------------------------
 * TINY MCE
 *---------------------------------------------------------------
*/
.has-tiny .mce-panel {
  border: 0 solid #DDD !important;
  background-color: #F0F0F0 !important;
  background-image: none !important;
}
.mce-panel {
  border-color: #CCC;
}
/**
 *---------------------------------------------------------------
 * REDACTOR
 *---------------------------------------------------------------
*/
.redactor_toolbar {
  background: #F0F0F0 !important;
  border: 1px solid #DDD !important;
  border-bottom: none !important;
  box-shadow: none !important;
}
.redactor_editor {
  border: 1px solid #DDD !important;
}
/**
 * ---------------------------------------------------------------
 * LAYOUT CLASSES
 * ---------------------------------------------------------------
 */
.hbox {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}
.hbox .col {
  display: table-cell;
  vertical-align: top;
  height: 100%;
  float: none;
}
.v-middle {
  vertical-align: middle !important;
}
.v-top {
  vertical-align: top !important;
}
.v-bottom {
  vertical-align: bottom !important;
}
.vbox {
  display: table;
  border-spacing: 0;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 240px;
}
.vbox .row-row {
  display: table-row;
  height: 100%;
}
.vbox .row-row .cell {
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}
.ie .vbox .row-row .cell {
  display: table-cell;
}
.vbox .row-row .cell .cell-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
/**
 * Positioning
 */
.pos-rlt {
  position: relative;
}
.pos-stc {
  position: static;
}
.pos-abt {
  position: absolute;
}
.pos-fix {
  position: fixed;
}
.stick-top-left,
.stick-top-right,
.stick-top-left-right {
  position: absolute;
  top: 0;
}
.stick-bottom-left,
.stick-bottom-right,
.stick-bottom-left-right {
  position: absolute;
  bottom: 0;
}
.stick-top-left,
.stick-bottom-left,
.stick-top-left-right,
.stick-bottom-left-right {
  left: 0;
}
.stick-top-right,
.stick-bottom-right,
.stick-top-left-right,
.stick-bottom-left-right {
  right: 0;
}
/**
 * ---------------------------------------------------------------
 * LINE
 * ---------------------------------------------------------------
 */
.line {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden;
}
.line-xs {
  margin: 0;
}
.line-lg {
  margin-top: 15px;
  margin-bottom: 15px;
}
.line-dashed {
  border-style: dashed !important;
  background-color: transparent;
  border-width: 0;
}
.no-line {
  border-width: 0;
}
/**
 * ---------------------------------------------------------------
 * BORDER
 * ---------------------------------------------------------------
 */
.no-border,
.no-borders {
  border-color: transparent;
  border-width: 0;
}
.no-radius {
  border-radius: 0;
}
/**
 * ---------------------------------------------------------------
 * DISPLAY
 * ---------------------------------------------------------------
 */
.block {
  display: block;
}
.block.hide {
  display: none;
}
.inline {
  display: inline-block !important;
}
.none {
  display: none;
}
.pull-none {
  float: none;
}
.rounded {
  border-radius: 500px;
}
.clear {
  display: block;
  overflow: hidden;
}
.no-bg {
  background-color: transparent;
  color: inherit;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.no-results {
  text-align: center;
  color: #444;
  padding: 20px;
  background: #FFFFFF;
  font-weight: 700;
  border: 1px solid #dee5e7;
}
.no-results h2,
.no-results h3,
.no-results h4 {
  margin: 0;
  font-size: 22px;
}
/**
 * ---------------------------------------------------------------
 * LINE HEIGHT
 * ---------------------------------------------------------------
 */
.l-h {
  line-height: 1.42857143;
}
.l-h-0x {
  line-height: 0;
}
.l-h-1x {
  line-height: 1.2;
}
.l-h-2x {
  line-height: 2em;
}
/**
 * ---------------------------------------------------------------
 * FONT WEIGHT
 * ---------------------------------------------------------------
 */
.font-normal {
  font-weight: normal;
}
.font-thin {
  font-weight: 300;
}
.font-bold {
  font-weight: 700;
}
/**
 * ---------------------------------------------------------------
 * FONT SIZE
 * ---------------------------------------------------------------
 */
.text-xxs {
  font-size: 65%;
}
.text-xs {
  font-size: 75%;
}
.text-sm {
  font-size: 85%;
}
.text-base {
  font-size: 14px;
}
.text-md {
  font-size: 16px;
}
.text-lg {
  font-size: 125%;
}
.text-xl {
  font-size: 180%;
}
.text-xxl {
  font-size: 225%;
}
.text-xxxl {
  font-size: 330%;
}
.text-xxxxl {
  font-size: 500%;
}
.text-2x {
  font-size: 2em;
}
.text-3x {
  font-size: 3em;
}
/**
 * ---------------------------------------------------------------
 * FONT STYLES
 * ---------------------------------------------------------------
 */
.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-u-c {
  text-transform: uppercase;
}
.text-l-t {
  text-decoration: line-through;
}
.text-u-l {
  text-decoration: underline;
}
.text-active,
.active > .text,
.active > .auto .text {
  display: none !important;
}
.active > .text-active,
.active > .auto .text-active {
  display: inline-block !important;
}
/**
 * ---------------------------------------------------------------
 * BOX SHADOW
 * ---------------------------------------------------------------
 */
.box-shadow {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}
.box-shadow-lg {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}
/**
 * ---------------------------------------------------------------
 * TEXT SHADOW
 * ---------------------------------------------------------------
 */
.text-shadow {
  font-size: 170px;
  text-shadow: 0 1px 0 #f2f2f2, 0 2px 0 #ffffff, 0 5px 10px rgba(0, 0, 0, 0.125), 0 10px 20px rgba(0, 0, 0, 0.2);
}
.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
/**
 * ---------------------------------------------------------------
 * WRAPPER
 * ---------------------------------------------------------------
 */
.wrapper-xs {
  padding: 5px;
}
.wrapper-sm {
  padding: 10px;
}
.wrapper {
  padding: 15px;
}
.wrapper-md {
  padding: 20px;
}
.wrapper-lg {
  padding: 30px;
}
.wrapper-xl {
  padding: 50px;
}
/**
 * ---------------------------------------------------------------
 * PADDING
 * ---------------------------------------------------------------
 */
.padder-lg {
  padding-left: 40px;
  padding-right: 30px;
}
.padder-md {
  padding-left: 40px;
  padding-right: 20px;
}
.padder {
  padding-left: 15px;
  padding-right: 15px;
}
.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}
.no-padder {
  padding: 0 !important;
}
.p-xxs {
  padding: 2px 4px;
}
.p-xs {
  padding: 5px;
}
.p-sm {
  padding: 10px;
}
.m {
  padding: 15px;
}
.p-md {
  padding: 20px;
}
.p-lg {
  padding: 30px;
}
.p-xl {
  padding: 50px;
}
.p-none {
  padding-left: 0 !important;
}
.p-t-none {
  padding-top: 0 !important;
}
.p-r-none {
  padding-right: 0 !important;
}
.p-b-none {
  padding-bottom: 0 !important;
}
.p-l-none {
  padding-left: 0 !important;
}
.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}
.pull-out {
  margin: -10px -15px;
}
/**
 * ---------------------------------------------------------------
 * BORDER
 * ---------------------------------------------------------------
 */
.b {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.b-a {
  border: 1px solid #f2f2f2;
}
.b-t {
  border-top: 1px solid #f2f2f2;
}
.b-r {
  border-right: 1px solid #f2f2f2;
}
.b-b {
  border-bottom: 1px solid #f2f2f2;
}
.b-l {
  border-left: 1px solid #f2f2f2;
}
/**
 * ---------------------------------------------------------------
 * BORDER COLOUR
 * ---------------------------------------------------------------
 */
.b-light {
  border-color: #e74c3c;
}
.b-dark {
  border-color: #3a3f51;
}
.b-black {
  border-color: #3a3f51;
}
.b-primary {
  border-color: #991717;
}
.b-success {
  border-color: #3FC380;
}
.b-info {
  border-color: #5bc0de;
}
.b-warning {
  border-color: #f0ad4e;
}
.b-danger {
  border-color: #d24d57;
}
.b-white {
  border-color: #FFFFFF;
}
.b-dashed {
  border-style: dashed !important;
}
.b-l-light {
  border-left-color: #e74c3c;
}
.b-l-dark {
  border-left-color: #3a3f51;
}
.b-l-black {
  border-left-color: #3a3f51;
}
.b-l-primary {
  border-left-color: #991717;
}
.b-l-success {
  border-left-color: #3FC380;
}
.b-l-info {
  border-left-color: #5bc0de;
}
.b-l-warning {
  border-left-color: #f0ad4e;
}
.b-l-danger {
  border-left-color: #d24d57;
}
.b-l-white {
  border-left-color: #FFFFFF;
}
/**
 * ---------------------------------------------------------------
 * BORDER WIDTH
 * ---------------------------------------------------------------
 */
.b-l-2x {
  border-left-width: 2px;
}
.b-l-3x {
  border-left-width: 3px;
}
.b-l-4x {
  border-left-width: 4px;
}
.b-l-5x {
  border-left-width: 5px;
}
.b-2x {
  border-width: 2px;
}
.b-3x {
  border-width: 3px;
}
.b-4x {
  border-width: 4px;
}
.b-5x {
  border-width: 5px;
}
/**
 * ---------------------------------------------------------------
 * BORDER RADIUS
 * ---------------------------------------------------------------
 */
.r {
  border-radius: 2px 2px 2px 2px;
}
.r-2x {
  border-radius: 4px;
}
.r-3x {
  border-radius: 6px;
}
.r-l {
  border-radius: 2px 0 0 2px;
}
.r-r {
  border-radius: 0 2px 2px 0;
}
.r-t {
  border-radius: 2px 2px 0 0;
}
.r-b {
  border-radius: 0 0 2px 2px;
}
/**
 * ---------------------------------------------------------------
 * MARGIN
 * ---------------------------------------------------------------
 */
.m-xxs {
  margin: 2px 4px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m {
  margin: 15px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-xl {
  margin: 50px;
}
.m-n {
  margin: 0 !important;
}
.m-l-none {
  margin-left: 0 !important;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l {
  margin-left: 15px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-l-xl {
  margin-left: 40px;
}
.m-l-xxl {
  margin-left: 50px;
}
.m-l-n-xxs {
  margin-left: -1px;
}
.m-l-n-xs {
  margin-left: -5px;
}
.m-l-n-sm {
  margin-left: -10px;
}
.m-l-n {
  margin-left: -15px;
}
.m-l-n-md {
  margin-left: -20px;
}
.m-l-n-lg {
  margin-left: -30px;
}
.m-l-n-xl {
  margin-left: -40px;
}
.m-l-n-xxl {
  margin-left: -50px;
}
.m-t-none {
  margin-top: 0 !important;
}
.m-t-xxs {
  margin-top: 1px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t {
  margin-top: 15px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-xxl {
  margin-top: 50px;
}
.m-t-n-xxs {
  margin-top: -1px;
}
.m-t-n-xs {
  margin-top: -5px;
}
.m-t-n-sm {
  margin-top: -10px;
}
.m-t-n {
  margin-top: -15px;
}
.m-t-n-md {
  margin-top: -20px;
}
.m-t-n-lg {
  margin-top: -30px;
}
.m-t-n-xl {
  margin-top: -40px;
}
.m-t-n-xxl {
  margin-top: -50px;
}
.m-r-none {
  margin-right: 0 !important;
}
.m-r-xxs {
  margin-right: 1px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r {
  margin-right: 15px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-r-xl {
  margin-right: 40px;
}
.m-r-xxl {
  margin-right: 50px;
}
.m-r-n-xxs {
  margin-right: -1px;
}
.m-r-n-xs {
  margin-right: -5px;
}
.m-r-n-sm {
  margin-right: -10px;
}
.m-r-n {
  margin-right: -15px;
}
.m-r-n-md {
  margin-right: -20px;
}
.m-r-n-lg {
  margin-right: -30px;
}
.m-r-n-xl {
  margin-right: -40px;
}
.m-r-n-xxl {
  margin-right: -50px;
}
.m-b-none {
  margin-bottom: 0 !important;
}
.m-b-xxs {
  margin-bottom: 1px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-b-xxl {
  margin-bottom: 50px;
}
.m-b-n-xxs {
  margin-bottom: -1px;
}
.m-b-n-xs {
  margin-bottom: -5px;
}
.m-b-n-sm {
  margin-bottom: -10px;
}
.m-b-n {
  margin-bottom: -15px;
}
.m-b-n-md {
  margin-bottom: -20px;
}
.m-b-n-lg {
  margin-bottom: -30px;
}
.m-b-n-xl {
  margin-bottom: -40px;
}
.m-b-n-xxl {
  margin-bottom: -50px;
}
/**
 * ---------------------------------------------------------------
 * AVATAR
 * ---------------------------------------------------------------
 */
.avatar {
  position: relative;
  display: block;
  border-radius: 500px;
  white-space: nowrap;
}
.avatar img {
  border-radius: 500px;
  width: 100%;
}
.avatar i {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 2px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
}
.avatar i.right {
  left: auto;
  right: 0;
}
.avatar i.bottom {
  left: auto;
  top: auto;
  bottom: 0;
  right: 0;
}
.avatar i.left {
  top: auto;
  bottom: 0;
}
.avatar i.on {
  background-color: #3FC380;
}
.avatar i.off {
  background-color: #888888;
}
.avatar i.busy {
  background-color: #d24d57;
}
.avatar i.away {
  background-color: #f0ad4e;
}
.avatar.thumb-md i {
  width: 12px;
  height: 12px;
  margin: 3px;
}
.avatar.thumb-sm i {
  margin: 1px;
}
.avatar.thumb-xs i {
  margin: 0;
}
/**
 * ---------------------------------------------------------------
 * WIDTHS
 * ---------------------------------------------------------------
 */
.w-xxs {
  width: 60px;
}
.w-xs {
  width: 90px;
}
.w-sm {
  width: 150px;
}
.w {
  width: 200px;
}
.w-md {
  width: 240px;
}
.w-lg {
  width: 280px;
}
.w-xl {
  width: 320px;
}
.w-xxl {
  width: 360px;
}
.w-full {
  width: 100%;
}
.w-auto {
  width: auto;
}
/**
 * ---------------------------------------------------------------
 * HEIGHTS
 * ---------------------------------------------------------------
 */
.h-auto {
  height: auto;
}
.h-full {
  height: 100%;
}
/**
 * ---------------------------------------------------------------
 * THUMBNAIL
 * ---------------------------------------------------------------
 */
.thumb-xl {
  width: 128px;
  display: inline-block;
}
.thumb-lg {
  width: 96px;
  display: inline-block;
}
.thumb-md {
  width: 64px;
  display: inline-block;
}
.thumb {
  width: 50px;
  display: inline-block;
}
.thumb-sm {
  width: 40px;
  display: inline-block;
}
.thumb-xs {
  width: 34px;
  display: inline-block;
}
.thumb-xxs {
  width: 30px;
  display: inline-block;
}
.thumb-wrapper {
  padding: 2px;
  border: 1px solid #f2f2f2;
}
.thumb img,
.thumb-xs img,
.thumb-sm img,
.thumb-md img,
.thumb-lg img,
.thumb-btn img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.img-full {
  width: 100%;
}
.img-full img {
  width: 100%;
}
/**
 * ---------------------------------------------------------------
 * SCROLLABLE
 * ---------------------------------------------------------------
 */
.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollable.hover {
  overflow-y: hidden !important;
}
.scrollable.hover:hover {
  overflow: visible !important;
  overflow-y: auto !important;
}
.smart .scrollable {
  overflow-y: auto !important;
}
.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.scroll-y {
  overflow-y: auto;
}
.scroll-x {
  overflow-x: auto;
}
/**
 * ---------------------------------------------------------------
 * ARROWS
 * ---------------------------------------------------------------
 */
.arrow {
  border-width: 9px;
  z-index: 10;
}
.arrow,
.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.arrow:after {
  border-width: 8px;
  content: "";
}
.arrow.top {
  left: 50%;
  margin-left: -9px;
  border-top-width: 0;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  top: -9px;
}
.arrow.top:after {
  top: 1px;
  margin-left: -8px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.arrow.top.arrow-primary:after {
  border-bottom-color: #991717;
}
.arrow.top.arrow-info:after {
  border-bottom-color: #5bc0de;
}
.arrow.top.arrow-success:after {
  border-bottom-color: #3FC380;
}
.arrow.top.arrow-danger:after {
  border-bottom-color: #d24d57;
}
.arrow.top.arrow-warning:after {
  border-bottom-color: #f0ad4e;
}
.arrow.top.arrow-light:after {
  border-bottom-color: #e74c3c;
}
.arrow.top.arrow-dark:after {
  border-bottom-color: #3a3f51;
}
.arrow.top.arrow-black:after {
  border-bottom-color: #1c2b36;
}
.arrow.right {
  top: 50%;
  right: -9px;
  margin-top: -9px;
  border-right-width: 0;
  border-left-color: rgba(0, 0, 0, 0.1);
}
.arrow.right:after {
  right: 1px;
  bottom: -8px;
  border-right-width: 0;
  border-left-color: #fff;
}
.arrow.right.arrow-primary:after {
  border-left-color: #991717;
}
.arrow.right.arrow-info:after {
  border-left-color: #5bc0de;
}
.arrow.right.arrow-success:after {
  border-left-color: #3FC380;
}
.arrow.right.arrow-danger:after {
  border-left-color: #d24d57;
}
.arrow.right.arrow-warning:after {
  border-left-color: #f0ad4e;
}
.arrow.right.arrow-light:after {
  border-left-color: #e74c3c;
}
.arrow.right.arrow-dark:after {
  border-left-color: #3a3f51;
}
.arrow.right.arrow-black:after {
  border-left-color: #1c2b36;
}
.arrow.bottom {
  left: 50%;
  bottom: -9px;
  margin-left: -9px;
  border-bottom-width: 0;
  border-top-color: rgba(0, 0, 0, 0.1);
}
.arrow.bottom:after {
  bottom: 1px;
  margin-left: -8px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.arrow.bottom.arrow-primary:after {
  border-top-color: #991717;
}
.arrow.bottom.arrow-info:after {
  border-top-color: #5bc0de;
}
.arrow.bottom.arrow-success:after {
  border-top-color: #3FC380;
}
.arrow.bottom.arrow-danger:after {
  border-top-color: #d24d57;
}
.arrow.bottom.arrow-warning:after {
  border-top-color: #f0ad4e;
}
.arrow.bottom.arrow-light:after {
  border-top-color: #e74c3c;
}
.arrow.bottom.arrow-dark:after {
  border-top-color: #3a3f51;
}
.arrow.bottom.arrow-black:after {
  border-top-color: #1c2b36;
}
.arrow.left {
  top: 50%;
  left: -9px;
  margin-top: -9px;
  border-left-width: 0;
  border-right-color: rgba(0, 0, 0, 0.1);
}
.arrow.left:after {
  left: 1px;
  bottom: -8px;
  border-left-width: 0;
  border-right-color: #fff;
}
.arrow.left.arrow-primary:after {
  border-right-color: #991717;
}
.arrow.left.arrow-info:after {
  border-right-color: #5bc0de;
}
.arrow.left.arrow-success:after {
  border-right-color: #3FC380;
}
.arrow.left.arrow-danger:after {
  border-right-color: #d24d57;
}
.arrow.left.arrow-warning:after {
  border-right-color: #f0ad4e;
}
.arrow.left.arrow-light:after {
  border-right-color: #e74c3c;
}
.arrow.left.arrow-dark:after {
  border-right-color: #3a3f51;
}
.arrow.left.arrow-black:after {
  border-right-color: #1c2b36;
}
.arrow.pull-left {
  left: 19px;
}
.arrow.pull-right {
  left: auto;
  right: 19px;
}
.arrow.pull-up {
  top: 19px;
}
.arrow.pull-down {
  top: auto;
  bottom: 19px;
}
/**
 * ---------------------------------------------------------------
 * HOVER
 * ---------------------------------------------------------------
 */
.hover-action {
  display: none;
}
.hover-rotate {
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s;
}
.hover-anchor:hover > .hover-action,
.hover-anchor:focus > .hover-action,
.hover-anchor:active > .hover-action {
  display: inherit;
}
.hover-anchor:hover > .hover-rotate,
.hover-anchor:focus > .hover-rotate,
.hover-anchor:active > .hover-rotate {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
}
.backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
/*desktop*/
@media screen and (min-width: 992px) {
  .col-lg-2-4 {
    width: 20.000%;
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm.show {
    display: inherit !important;
  }
}
/*phone*/
@media (max-width: 767px) {
  .w-auto-xs {
    width: auto;
  }
  .shift {
    display: none !important;
  }
  .shift.in {
    display: block !important;
  }
  .row-2 [class*="col"] {
    width: 50%;
    float: left;
  }
  .row-2 .col-0 {
    clear: none;
  }
  .row-2 li:nth-child(odd) {
    clear: left;
    margin-left: 0;
  }
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
  .no-border-xs {
    border-width: 0;
  }
  .pull-none-xs {
    float: none !important;
  }
  .pull-right-xs {
    float: right !important;
  }
  .pull-left-xs {
    float: left !important;
  }
  .dropdown-menu.pull-none-xs {
    left: 0;
  }
  .hidden-xs.show {
    display: inherit !important;
  }
  .wrapper-lg,
  .wrapper-md {
    padding: 15px;
  }
  .padder-lg,
  .padder-md {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.back-forward-arrows {
  padding-top: 7px;
}
/**
 * Transitions
 */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
/**
 * ---------------------------------------------------------------
 * LOADERS/ SPINNERS STYLES
 * ---------------------------------------------------------------
 */
.load.spin {
  margin: 20px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(153, 23, 23, 0.2);
  border-right: 1.1em solid rgba(153, 23, 23, 0.2);
  border-bottom: 1.1em solid rgba(153, 23, 23, 0.2);
  border-left: 1.1em solid #991717;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.load.spin.spin,
.load.spin::after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/**
 *---------------------------------------------------------------
 * COLOURS: GRADIENT
 *---------------------------------------------------------------
 */
.bg-gd {
  background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
  background-image: -webkit-gradient(linear, left 0, left 100%, from(rgba(40, 50, 60, 0)), to(rgba(40, 50, 60, 0.075)));
  background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0), 0, rgba(40, 50, 60, 0.075), 100%);
  background-image: -moz-linear-gradient(top, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
  background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#1328323c', GradientType=0);
  filter: none;
}
.bg-gd-dk {
  background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
  background-image: -webkit-gradient(linear, left 10%, left 100%, from(rgba(40, 50, 60, 0)), to(rgba(40, 50, 60, 0.5)));
  background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0), 10%, rgba(40, 50, 60, 0.5), 100%);
  background-image: -moz-linear-gradient(top, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
  background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#8028323c', GradientType=0);
  filter: none;
}
/**
 *---------------------------------------------------------------
 * COLOURS: OPACITY
 *---------------------------------------------------------------
 */
.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-black-opacity {
  background-color: rgba(32, 43, 54, 0.5);
}
/**
 *---------------------------------------------------------------
 * COLOURS: BG OVERRIDE
 *---------------------------------------------------------------
 */
.bg-override {
  background-image: none !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: WHITE
 *---------------------------------------------------------------
 */
.bg-light {
  background-color: #e74c3c;
  color: #444444;
}
.bg-light.lt,
.bg-light .lt {
  background-color: #e65648;
}
.bg-light.lter,
.bg-light .lter {
  background-color: #e45d4f;
}
.bg-light.dk,
.bg-light .dk {
  background-color: #e83d2b;
}
.bg-light.dker,
.bg-light .dker {
  background-color: #ea321f;
}
.bg-light.bg,
.bg-light .bg {
  background-color: #e74c3c;
}
a.bg-light:hover {
  color: #333645;
}
.bg-white {
  background-color: #FFFFFF !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-white-only {
  background-color: #fff;
}
.border-white {
  border-color: #FFFFFF !important;
}
.txt-white {
  color: #FFFFFF !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: GREY
 *---------------------------------------------------------------
 */
.bg-grey {
  background-color: #AAAAAA !important;
  background-color: rgba(170, 170, 170, 0.5) !important;
}
.border-grey {
  border-color: #AAAAAA !important;
}
.txt-grey {
  color: #AAAAAA !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: DARK
 *---------------------------------------------------------------
 */
.bg-black {
  background-color: #1c2b36;
  color: #6b94b3;
}
.bg-black.lt,
.bg-black .lt {
  background-color: #263845;
}
.bg-black.lter,
.bg-black .lter {
  background-color: #314554;
}
.bg-black.dk,
.bg-black .dk {
  background-color: #131e26;
}
.bg-black.dker,
.bg-black .dker {
  background-color: #0a1015;
}
.bg-black.bg,
.bg-black .bg {
  background-color: #1c2b36;
}
.bg-black a {
  color: #8dadc4;
}
.bg-black a:hover {
  color: #fff;
}
.bg-black a.list-group-item:hover,
.bg-black a.list-group-item:focus {
  background-color: inherit;
}
.bg-black .nav > li:hover > a,
.bg-black .nav > li:focus > a,
.bg-black .nav > li.active > a {
  color: #fff;
  background-color: #131e26;
}
.bg-black .nav > li > a {
  color: #7ca1bb;
}
.bg-black .nav > li > a:hover,
.bg-black .nav > li > a:focus {
  background-color: #16232d;
}
.bg-black .nav .open > a {
  background-color: #131e26;
}
.bg-black .caret {
  border-top-color: #6b94b3;
  border-bottom-color: #6b94b3;
}
.bg-black.navbar .nav > li.active > a {
  color: #fff;
  background-color: #131e26;
}
.bg-black .open > a,
.bg-black .open > a:hover,
.bg-black .open > a:focus {
  color: #fff;
}
.bg-black .text-muted {
  color: #507b9b !important;
}
.bg-black .text-lt {
  color: #bfd1de !important;
}
.bg-black.auto .list-group-item,
.bg-black .auto .list-group-item {
  border-color: #131e25 !important;
  background-color: transparent;
}
.bg-black.auto .list-group-item:hover,
.bg-black .auto .list-group-item:hover,
.bg-black.auto .list-group-item:focus,
.bg-black .auto .list-group-item:focus,
.bg-black.auto .list-group-item:active,
.bg-black .auto .list-group-item:active,
.bg-black.auto .list-group-item.active,
.bg-black .auto .list-group-item.active {
  background-color: #131e26 !important;
}
.bg-dark {
  background-color: #555555 !important;
  background-color: rgba(85, 85, 85, 0.5) !important;
}
.border-dark {
  border-color: #555555 !important;
}
.txt-dark {
  color: #555555 !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: BLUE
 *---------------------------------------------------------------
 */
.bg-blue {
  background-color: #4b8df8 !important;
  background-color: rgba(75, 141, 248, 0.5) !important;
}
.border-blue {
  border-color: #4b8df8 !important;
}
.txt-blue {
  color: #4b8df8 !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: RED
 *---------------------------------------------------------------
 */
.bg-red {
  background-color: #e02222 !important;
  background-color: rgba(224, 34, 34, 0.5) !important;
}
.border-red {
  border-color: #e02222 !important;
}
.txt-red {
  color: #e02222 !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: ORANGE
 *---------------------------------------------------------------
 */
.bg-orange {
  background-color: #F9A123 !important;
  background-color: rgba(249, 161, 35, 0.5) !important;
}
.border-orange {
  border-color: #F9A123 !important;
}
.txt-orange {
  color: #F9A123 !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: YELLOW
 *---------------------------------------------------------------
 */
.bg-yellow {
  background-color: #FFF333 !important;
  background-color: rgba(255, 243, 51, 0.5) !important;
}
.border-yellow {
  border-color: #FFF333 !important;
}
.txt-yellow {
  color: #FFF333 !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: GREEN
 *---------------------------------------------------------------
 */
.bg-green {
  background-color: #008A2E !important;
  background-color: rgba(0, 138, 46, 0.5) !important;
}
.border-green {
  border-color: #008A2E !important;
}
.txt-green {
  color: #008A2E !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: PURPLE
 *---------------------------------------------------------------
 */
.bg-purple {
  background-color: #852b99 !important;
  background-color: rgba(133, 43, 153, 0.5) !important;
}
.border-purple {
  border-color: #852b99 !important;
}
.txt-purple {
  color: #852b99 !important;
}
/**
 *---------------------------------------------------------------
 * COLOURS: DEFAULT
 *---------------------------------------------------------------
 */
.bg-primary {
  background-color: #991717;
  color: #ee8e8e;
}
.bg-primary.lt,
.bg-primary .lt {
  background-color: #ad1d1d;
}
.bg-primary.lter,
.bg-primary .lter {
  background-color: #c02323;
}
.bg-primary.dk,
.bg-primary .dk {
  background-color: #851212;
}
.bg-primary.dker,
.bg-primary .dker {
  background-color: #700d0d;
}
.bg-primary.bg,
.bg-primary .bg {
  background-color: #991717;
}
.bg-primary a {
  color: #f5baba;
}
.bg-primary a:hover {
  color: #fff;
}
.bg-primary a.list-group-item:hover,
.bg-primary a.list-group-item:focus {
  background-color: inherit;
}
.bg-primary .nav > li:hover > a,
.bg-primary .nav > li:focus > a,
.bg-primary .nav > li.active > a {
  color: #fff;
  background-color: #851212;
}
.bg-primary .nav > li > a {
  color: #f1a4a4;
}
.bg-primary .nav > li > a:hover,
.bg-primary .nav > li > a:focus {
  background-color: #8e1313;
}
.bg-primary .nav .open > a {
  background-color: #851212;
}
.bg-primary .caret {
  border-top-color: #ee8e8e;
  border-bottom-color: #ee8e8e;
}
.bg-primary.navbar .nav > li.active > a {
  color: #fff;
  background-color: #851212;
}
.bg-primary .open > a,
.bg-primary .open > a:hover,
.bg-primary .open > a:focus {
  color: #fff;
}
.bg-primary .text-muted {
  color: #e76262 !important;
}
.bg-primary .text-lt {
  color: #fffdfd !important;
}
.bg-primary.auto .list-group-item,
.bg-primary .auto .list-group-item {
  border-color: #831414 !important;
  background-color: transparent;
}
.bg-primary.auto .list-group-item:hover,
.bg-primary .auto .list-group-item:hover,
.bg-primary.auto .list-group-item:focus,
.bg-primary .auto .list-group-item:focus,
.bg-primary.auto .list-group-item:active,
.bg-primary .auto .list-group-item:active,
.bg-primary.auto .list-group-item.active,
.bg-primary .auto .list-group-item.active {
  background-color: #851212 !important;
}
.bg-success {
  background-color: #3FC380;
  color: #daf4e7;
}
.bg-success.lt,
.bg-success .lt {
  background-color: #55c68d;
}
.bg-success.lter,
.bg-success .lter {
  background-color: #6bca9a;
}
.bg-success.dk,
.bg-success .dk {
  background-color: #34b473;
}
.bg-success.dker,
.bg-success .dker {
  background-color: #2ca367;
}
.bg-success.bg,
.bg-success .bg {
  background-color: #3FC380;
}
.bg-success a {
  color: #ffffff;
}
.bg-success a:hover {
  color: #fff;
}
.bg-success a.list-group-item:hover,
.bg-success a.list-group-item:focus {
  background-color: inherit;
}
.bg-success .nav > li:hover > a,
.bg-success .nav > li:focus > a,
.bg-success .nav > li.active > a {
  color: #fff;
  background-color: #34b473;
}
.bg-success .nav > li > a {
  color: #f2f2f2;
}
.bg-success .nav > li > a:hover,
.bg-success .nav > li > a:focus {
  background-color: #37bc78;
}
.bg-success .nav .open > a {
  background-color: #34b473;
}
.bg-success .caret {
  border-top-color: #daf4e7;
  border-bottom-color: #daf4e7;
}
.bg-success.navbar .nav > li.active > a {
  color: #fff;
  background-color: #34b473;
}
.bg-success .open > a,
.bg-success .open > a:hover,
.bg-success .open > a:focus {
  color: #fff;
}
.bg-success .text-muted {
  color: #b4e7cd !important;
}
.bg-success .text-lt {
  color: #ffffff !important;
}
.bg-success.auto .list-group-item,
.bg-success .auto .list-group-item {
  border-color: #37b173 !important;
  background-color: transparent;
}
.bg-success.auto .list-group-item:hover,
.bg-success .auto .list-group-item:hover,
.bg-success.auto .list-group-item:focus,
.bg-success .auto .list-group-item:focus,
.bg-success.auto .list-group-item:active,
.bg-success .auto .list-group-item:active,
.bg-success.auto .list-group-item.active,
.bg-success .auto .list-group-item.active {
  background-color: #34b473 !important;
}
.bg-info {
  background-color: #5bc0de;
  color: #ffffff;
}
.bg-info.lt,
.bg-info .lt {
  background-color: #72c7e0;
}
.bg-info.lter,
.bg-info .lter {
  background-color: #89cee3;
}
.bg-info.dk,
.bg-info .dk {
  background-color: #43b9dd;
}
.bg-info.dker,
.bg-info .dker {
  background-color: #2ab3dc;
}
.bg-info.bg,
.bg-info .bg {
  background-color: #5bc0de;
}
.bg-info a {
  color: #ffffff;
}
.bg-info a:hover {
  color: #fff;
}
.bg-info a.list-group-item:hover,
.bg-info a.list-group-item:focus {
  background-color: inherit;
}
.bg-info .nav > li:hover > a,
.bg-info .nav > li:focus > a,
.bg-info .nav > li.active > a {
  color: #fff;
  background-color: #43b9dd;
}
.bg-info .nav > li > a {
  color: #f2f2f2;
}
.bg-info .nav > li > a:hover,
.bg-info .nav > li > a:focus {
  background-color: #4cbdde;
}
.bg-info .nav .open > a {
  background-color: #43b9dd;
}
.bg-info .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.bg-info.navbar .nav > li.active > a {
  color: #fff;
  background-color: #43b9dd;
}
.bg-info .open > a,
.bg-info .open > a:hover,
.bg-info .open > a:focus {
  color: #fff;
}
.bg-info .text-muted {
  color: #e6e6e6 !important;
}
.bg-info .text-lt {
  color: #ffffff !important;
}
.bg-info.auto .list-group-item,
.bg-info .auto .list-group-item {
  border-color: #46b8da !important;
  background-color: transparent;
}
.bg-info.auto .list-group-item:hover,
.bg-info .auto .list-group-item:hover,
.bg-info.auto .list-group-item:focus,
.bg-info .auto .list-group-item:focus,
.bg-info.auto .list-group-item:active,
.bg-info .auto .list-group-item:active,
.bg-info.auto .list-group-item.active,
.bg-info .auto .list-group-item.active {
  background-color: #43b9dd !important;
}
.bg-warning {
  background-color: #f0ad4e;
  color: #ffffff;
}
.bg-warning.lt,
.bg-warning .lt {
  background-color: #f0b868;
}
.bg-warning.lter,
.bg-warning .lter {
  background-color: #f0c281;
}
.bg-warning.dk,
.bg-warning .dk {
  background-color: #f1a334;
}
.bg-warning.dker,
.bg-warning .dker {
  background-color: #f29819;
}
.bg-warning.bg,
.bg-warning .bg {
  background-color: #f0ad4e;
}
.bg-warning a {
  color: #ffffff;
}
.bg-warning a:hover {
  color: #fff;
}
.bg-warning a.list-group-item:hover,
.bg-warning a.list-group-item:focus {
  background-color: inherit;
}
.bg-warning .nav > li:hover > a,
.bg-warning .nav > li:focus > a,
.bg-warning .nav > li.active > a {
  color: #fff;
  background-color: #f1a334;
}
.bg-warning .nav > li > a {
  color: #f2f2f2;
}
.bg-warning .nav > li > a:hover,
.bg-warning .nav > li > a:focus {
  background-color: #f1a73d;
}
.bg-warning .nav .open > a {
  background-color: #f1a334;
}
.bg-warning .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.bg-warning.navbar .nav > li.active > a {
  color: #fff;
  background-color: #f1a334;
}
.bg-warning .open > a,
.bg-warning .open > a:hover,
.bg-warning .open > a:focus {
  color: #fff;
}
.bg-warning .text-muted {
  color: #e6e6e6 !important;
}
.bg-warning .text-lt {
  color: #ffffff !important;
}
.bg-warning.auto .list-group-item,
.bg-warning .auto .list-group-item {
  border-color: #eea236 !important;
  background-color: transparent;
}
.bg-warning.auto .list-group-item:hover,
.bg-warning .auto .list-group-item:hover,
.bg-warning.auto .list-group-item:focus,
.bg-warning .auto .list-group-item:focus,
.bg-warning.auto .list-group-item:active,
.bg-warning .auto .list-group-item:active,
.bg-warning.auto .list-group-item.active,
.bg-warning .auto .list-group-item.active {
  background-color: #f1a334 !important;
}
.bg-danger {
  background-color: #d24d57;
  color: #fbf0f1;
}
.bg-danger.lt,
.bg-danger .lt {
  background-color: #d5646c;
}
.bg-danger.lter,
.bg-danger .lter {
  background-color: #d87a81;
}
.bg-danger.dk,
.bg-danger .dk {
  background-color: #d03641;
}
.bg-danger.dker,
.bg-danger .dker {
  background-color: #c22a35;
}
.bg-danger.bg,
.bg-danger .bg {
  background-color: #d24d57;
}
.bg-danger a {
  color: #ffffff;
}
.bg-danger a:hover {
  color: #fff;
}
.bg-danger a.list-group-item:hover,
.bg-danger a.list-group-item:focus {
  background-color: inherit;
}
.bg-danger .nav > li:hover > a,
.bg-danger .nav > li:focus > a,
.bg-danger .nav > li.active > a {
  color: #fff;
  background-color: #d03641;
}
.bg-danger .nav > li > a {
  color: #f2f2f2;
}
.bg-danger .nav > li > a:hover,
.bg-danger .nav > li > a:focus {
  background-color: #d23e49;
}
.bg-danger .nav .open > a {
  background-color: #d03641;
}
.bg-danger .caret {
  border-top-color: #fbf0f1;
  border-bottom-color: #fbf0f1;
}
.bg-danger.navbar .nav > li.active > a {
  color: #fff;
  background-color: #d03641;
}
.bg-danger .open > a,
.bg-danger .open > a:hover,
.bg-danger .open > a:focus {
  color: #fff;
}
.bg-danger .text-muted {
  color: #f1c7ca !important;
}
.bg-danger .text-lt {
  color: #ffffff !important;
}
.bg-danger.auto .list-group-item,
.bg-danger .auto .list-group-item {
  border-color: #cd3944 !important;
  background-color: transparent;
}
.bg-danger.auto .list-group-item:hover,
.bg-danger .auto .list-group-item:hover,
.bg-danger.auto .list-group-item:focus,
.bg-danger .auto .list-group-item:focus,
.bg-danger.auto .list-group-item:active,
.bg-danger .auto .list-group-item:active,
.bg-danger.auto .list-group-item.active,
.bg-danger .auto .list-group-item.active {
  background-color: #d03641 !important;
}
/**
 *---------------------------------------------------------------
 * BORDER RADIUS OVERRIDE
 *---------------------------------------------------------------
*/
.popover {
  border-radius: 6px !important;
}
.tooltip-inner {
  border-radius: 4px !important;
}
.label {
  border-radius: .25em !important;
}
.badge {
  border-radius: 10px !important;
}
