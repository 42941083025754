/**
 * Navbar default
 */
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: @gray;
    background-color: @white-light;
    font-weight: normal;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #DDDDDD;
}


/**
 * Inline Wizard
 */
.wizard {

    /* Navbar */
    .navbar {
        z-index: 998 !important;
        background: @white;
        border: 1px solid @white-dark;

        li {
            a {
                .glyphicons {
                    margin-right: 10px;
                }
            }
            &.active{
                font-weight: bold;
            }
            &.success {
                a {
                    color: #008A2E !important;
                    background-color: rgba(0, 138, 46, 0.1);
                    border-right: 1px solid rgba(0, 138, 46, 0.2);
                }
            }
            &.error {
                a {
                    color: #E02222 !important;
                    background-color: rgba(224, 34, 34, 0.1);
                    border-right: 1px solid rgba(224, 34, 34, 0.2);
                }
            }
        }
        li:last-child {
            a {
                border-right: none;
            }
        }
    }


    /** Wizard Bar */
    .wizard-bar {
        padding: 10px 15px;

        .wizard-status {
            padding-top: 3px;
            color: @gray-light;

            #status-text {
                float: left;
                margin-right: 10px;
                font-size: 16px;
                font-weight: bold;
                line-height: 30px;
            }
            #bar {
                margin-bottom: 0;
                border: 1px solid #ccc;
            }
        }

        .wizard-actions {
            text-align: right;
        }
    }
}


/**
 * Step Wizard
 */
.step-wizard {
    margin-top: 15%;
    background-color: #fff;
    border: 1px solid #DDD;
    padding: 30px;

    /* Loading spinner */
    .loading {
        position: absolute;
        top: 50%;
        left: 44.5%;
    }

    /* Wizard Steps */
    .wizard-steps {
        position: relative;
        margin-bottom: 30px;


        /* Progress bar */
        .progress {
            position: absolute;
            display: inline-block;
            top: 23px;
            left: 0;
            right: 0;
            margin: auto;
            height: 10px;
        }

        /* Navigation */
        .nav {
            position: relative;

            .step {
                display: inline-block;
                line-height: 28px;
                width: 36px;
                height: 36px;
                border-radius: 50% !important;
                border: 4px solid #E5E6E6;
                background: #FFFFFF;
            }

            .title {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: -15px;
                color: #969C9C;
            }

            li {
                a {
                    margin-bottom: 15px;
                }

                &.active a,
                &:hover a {
                    color: inherit;
                    background-color: transparent;
                }
                &.active .step,
                &:hover .step {
                    -webkit-transition: border-color 0.4s linear;
                    -o-transition: border-color 0.4s linear;
                    transition: border-color 0.4s linear;
                    border-color: @brand-primary;
                    border-radius: 50% !important;
                }
                &.active .title,
                &:hover .title {
                    color: #313534;
                }

                &.done .step {
                    color: #FFFFFF;
                    border-color: @brand-primary;
                    background-color: @brand-primary;
                    font-weight: 500;
                    -webkit-transition: background-color 0.2s linear;
                    -o-transition: background-color 0.2s linear;
                    transition: background-color 0.2s linear;
                }

                &.disabled {
                    &:hover .step {
                        -webkit-transition: none;
                        -o-transition: none;
                        transition: none;
                        border-color: #E5E6E6;
                    }
                    &:hover .title {
                        color: #969C9C;
                    }
                }
            }
        }
    }

    /* Wizard content */
    .wizard-content {
        padding: 30px 0;
        margin: 30px 0;
        border-top: 3px dashed #E2E2E2;
        border-bottom: 3px dashed #E2E2E2;
    }

    /* Wizard actions */
    .wizard-actions {

    }
}


/**
 * Small devices
 */
@media (max-width: 768px) {
    .step-wizard {
        .wizard-steps {
            .progress {
                display: none;
            }
        }
    }
}
