
/**
 *---------------------------------------------------------------
 * LOGIN: CONTAINER
 *---------------------------------------------------------------
*/
    .login-container {
        border:1px solid @white-dark;
        background-color: @white;
        margin-top:20%;
        box-shadow: 0 0 5px @gray-dark;
    }

    .login-container .login-header {
        background-color: @white;
        border-bottom:1px solid @white-dark;
        font-size:14px;
        margin:0;
        padding:10px 15px;
        font-weight: bold;
    }

    .login-container label{
        color: @gray;
    }
    .login-container .login-form {
        padding:20px 15px;
    }
    .login-container input.error {
        border-color: @red;
        box-shadow:0 0 3px @red;
    }


/**
 *---------------------------------------------------------------
 * LOGIN: HEADER LOGO
 *---------------------------------------------------------------
*/
    /* Header Logo */
    .login-container .login-header.header-logo {
        margin:0;

        img {
            max-height: 60px;
            width: auto;
        }
    }


/**
 *---------------------------------------------------------------
 * LOGIN: HEADER TEXT
 *---------------------------------------------------------------
*/
    /* Header Text */
    .login-container .login-header.header-text {
        margin:0;
        padding: 10px 15px;
        font-size: 20px;
        color: #EEE;
        background: @navbar-logo-bg;
    }


/**
 *---------------------------------------------------------------
 * LOGIN: OTHER ACTIONS
 *---------------------------------------------------------------
*/
    .login-container .forgotLnk {
        margin-top: 10px;
        display: block;
    }

    .login-container .or-box {
        position: relative;
        border-top: 1px solid #dfdfdf;
        padding-top: 20px;
        margin: 20px 0 5px 0;
    }

    .login-container .or {
        color: #666666;
        background-color: #ffffff;
        position: absolute;
        text-align: center;
        top: -8px;
        width: 40px;
        left: 43%;
    }

    .login-container .btn-facebook {
        background-color: #3F639E;
        color: #fff;
        font-weight:bold;
    }
    .login-container .btn-google {
        background-color: #454545;
        color: #fff;
        font-weight:bold;
    }

    .login-container .btn-facebook:hover,
    .login-container .btn-google:hover {
        color: #fff;
    }
