/**
 *---------------------------------------------------------------
 * CMS REDACTOR STYLES
 *---------------------------------------------------------------
*/

    .redactor_editor {
        i,
        em {
            font-style: italic;
        }

        ul,
        ol {
            padding-left: 28px;
            margin-bottom: 27px;

            li {
                padding-left: 20px;
                line-height: 24px;
            }
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }
    }
