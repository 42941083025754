
/**
 *---------------------------------------------------------------
 * GOOGLE LISTING PREVIEW
 *---------------------------------------------------------------
*/
    .google-preview {
        position: relative;
        padding: 15px;
        background: #fff;
        border:1px solid #DDD;
        font-family: arial,sans-serif;
        margin-bottom: 25px;
        line-height: 1.2;
    }
    .google-preview > .preview-label{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        padding: 2px 5px;
        text-align: right;
        background: #DDD;
    }
    .google-preview > .preview-title,
    .google-preview > .preview-description {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
    }

    .google-preview > .preview-title {
        color: #609;
        font-size:18px;
        margin-bottom:5px;
    }
    .google-preview > .preview-url {
        color: #006621;
        font-style: normal;
        font-size: 13px;
        height: 17px;
        line-height: 16px;
        margin-bottom: 5px;
    }
    .google-preview > .preview-description {
        color: #545454;
        line-height: 1.3;
        word-wrap: break-word;
        font-size: small;
        max-width: 42em;
    }
