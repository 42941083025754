
#loadingbar {
    position: fixed;
    z-index: 2147483647;
    top: 0;
    left: -6px;
    width: 0;
    height: 2px;
    background: lighten(@brand-primary, 20%);
    border-radius: 1px;
    transition: all 500ms ease-in-out;
}

#loadingbar.waiting dd, #loadingbar.waiting dt {
    animation: pulse 2s ease-out 0s infinite;
}

#loadingbar dt {
    opacity: .6;
    width: 180px;
    right: -80px;
    clip: rect(-6px,90px,14px,-6px);
}

#loadingbar dd {
    opacity: .6;
    width: 20px;
    right: 0;
    clip: rect(-6px,22px,14px,10px);
}

#loadingbar dd,
#loadingbar dt {
    position: absolute;
    top: 0;
    height: 2px;
    box-shadow: @brand-primary 1px 0 6px 1px;
    border-radius: 100%;
}

@keyframes pulse {
    30% { opacity:0.6; }
    60% { opacity:0; }
    100% { opacity:0.6; }
}

@-moz-keyframes pulse
{
    30% { opacity:0.6; }
    60% { opacity:0; }
    100% { opacity:0.6; }
}

@-ms-keyframes pulse
{
    30% { opacity:0.6; }
    60% { opacity:0; }
    100% { opacity:0.6; }
}

@-webkit-keyframes pulse
{
    30% { opacity:0.6; }
    60% { opacity:0; }
    100% { opacity:0.6; }
}
