
/**
 *---------------------------------------------------------------
 * WARNING
 *---------------------------------------------------------------
*/
    .has-warning .help-inline,
    .has-warning .help-block,
    .has-warning .control-label {
        color: #c09853;
    }
    .has-warning .form-control {
        border-color: #c09853;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .has-warning .form-control:focus {
        border-color: #a47e3c;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .has-warning .input-group-addon {
        color: #c09853;
        background-color: #fcf8e3;
        border-color: #c09853;
    }


/**
 *---------------------------------------------------------------
 * ERROR
 *---------------------------------------------------------------
*/
    .has-error .help-inline,
    .has-error .help-block,
    .has-error .control-label {
        color: #b94a48;
    }
    .has-error .form-control {
        border-color: #b94a48;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .has-error .form-control:focus {
        border-color: #953b39;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .has-error .input-group-addon {
        color: #b94a48;
        background-color: #f2dede;
        border-color: #b94a48;
    }


/**
 *---------------------------------------------------------------
 * SUCCESS
 *---------------------------------------------------------------
*/
    .has-success .help-inline,
    .has-success .help-block,
    .has-success .control-label {
        color: #468847;
    }
    .has-success .form-control {
        border-color: #468847;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .has-success .form-control:focus {
        border-color: #356635;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .has-success .input-group-addon {
        color: #468847;
        background-color: #dff0d8;
        border-color: #468847;
    }
